import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import CommonHeader from "../NewCheckOutPage/Components/CommonHeader";
import {
  AreaContext,
  CartContext,
  ContactContext,
  InterNationalContext,
  LanguageContext,
  VendorContext,
  VendorSlugContext,
} from "../../App";
import DeliveryMapContainer from "./Component/DeliveryMapContainer";
import "./newDeliveryStyles.css";
import NewAddressForm from "./Component/NewAddressForm";
import axios from "axios";
import ReactFlagsSelect from "react-flags-select";
import InternationalAddress from "../InterNationalDelivery/InternationalAddress";
import { getVendorCountries } from "../APIS/submitOrderApi";
import { mapArea } from "../../commonFunction/areaConstant";

const NewDeliveryAddress = () => {
  const { language } = useContext(LanguageContext);
  const details = useContext(VendorContext);
  const { areaDetails, setAreaDetails } = useContext(AreaContext);
  const [countryDropDown, setCountryDropDown] = useState();
  const [countryArray, setCountryArray] = useState();

  const { cart } = useContext(CartContext);
  const { addressDetails, setAddressDetails } = useContext(ContactContext);

  const history = useHistory();
  const [errorState, setErrorState] = useState({
    blockError: false,
    blockErrorMessage: "",
    blockErrorMessagear: "",
    streetError: false,
    streetErrorMessage: "",
    streetErrorMessagear: "",
    houseError: false,
    houseErrorMessage: "",
    houseErrorMessagear: "",
  });

  const [markerPosition, setMarkerPosition] = useState(null);
  const [selectedBounds, setSelectedBounds] = useState({
    north: 29.3933486,
    south: 29.3587818,
    east: 48.0058122,
    west: 47.9579213,
  });
  const { internationalDelivery, setInternationalDelivery } =
    useContext(InterNationalContext);
  const vendorSlug = useContext(VendorSlugContext);
  const [internationalError, setInternationalError] = useState({
    delivery_state: false,
    delivery_city: false,
    delivery_address1: false,
    delivery_address2: false,
  });

  useEffect(() => {
    if (!cart?.cartCount) {
      history.push(`/`);
    } else if (
      !areaDetails?.area &&
      !areaDetails?.branch &&
      details?.vendor?.home_page_type != "18" &&
      (details?.vendor?.international_delivery === "3" ||
        details?.vendor?.international_delivery === "") &&
      internationalDelivery.delivery_country_code.toLowerCase() === "kw"
    ) {
      history.push(`/area`);
    }
  }, []);

  const blockValidation = (value) => {
    if (value == "") {
      setErrorState((errorState) => ({
        ...errorState,
        blockErrorMessage: "This field is compulsory",
        blockError: true,
        blockErrorMessagear: "مطلوب ملء هذا الحقل",
      }));
      return true;
    } else {
      setErrorState((errorState) => ({
        ...errorState,
        blockErrorMessage: "",
        blockError: false,
        blockErrorMessagear: "",
      }));
      return false;
    }
  };

  const streetValidation = (value) => {
    if (value == "") {
      setErrorState((errorState) => ({
        ...errorState,
        streetErrorMessage: "This field is compulsory",
        streetError: true,
        streetErrorMessagear: "مطلوب ملء هذا الحقل",
      }));
      return true;
    } else {
      setErrorState((errorState) => ({
        ...errorState,
        streetErrorMessage: "",
        streetError: false,
        streetErrorMessagear: "",
      }));
      return false;
    }
  };

  const houseValidation = (value) => {
    if (value == "") {
      setErrorState((errorState) => ({
        ...errorState,
        houseErrorMessage: "This field is compulsory",
        houseError: true,
        houseErrorMessagear: "مطلوب ملء هذا الحقل",
      }));
      return true;
    } else {
      setErrorState((errorState) => ({
        ...errorState,
        houseErrorMessage: "",
        houseError: false,
        houseErrorMessagear: "",
      }));
      return false;
    }
  };
  const handleNext = () => {
    if (
      details?.vendor?.international_delivery === "3" ||
      details?.vendor?.international_delivery === "" ||
      internationalDelivery.delivery_country_code.toUpperCase() === "KW"
    ) {
      if (!areaDetails?.area && !areaDetails?.branch) {
        history.push(`/area`);
      } else {
        let block = details?.vendor?.enable_address_types?.includes(
          addressDetails.addressType
        )
          ? false
          : blockValidation(addressDetails.block);
        let street = details?.vendor?.enable_address_types?.includes(
          addressDetails.addressType
        )
          ? false
          : streetValidation(addressDetails.street);
        let house = houseValidation(addressDetails.house);
        if (!block && !street && !house) history.push("/checkout");
      }
    } else {
      if (
        internationalDelivery.delivery_address1 !== "" &&
        internationalDelivery.delivery_address2 !== ""
      ) {
        history.push("/checkout");
      } else {
        let errorData = {};
        if (internationalDelivery.delivery_address1 === "") {
          errorData = { ...errorData, delivery_address1: true };
        } else {
          errorData = { ...errorData, delivery_address1: false };
        }
        if (internationalDelivery.delivery_address2 === "") {
          errorData = { ...errorData, delivery_address2: true };
        } else {
          errorData = { ...errorData, delivery_address2: false };
        }
        setInternationalError(errorData);
      }
    }
  };

  const convertArabicIndicToRegularArabic = (arabicIndicNumber) => {
    const arabicIndicDigits = [
      "٠",
      "١",
      "٢",
      "٣",
      "٤",
      "٥",
      "٦",
      "٧",
      "٨",
      "٩",
    ];
    let regularArabicNumber = "";

    for (let i = 0; i < arabicIndicNumber.length; i++) {
      const char = arabicIndicNumber.charAt(i);
      const digitIndex = arabicIndicDigits.indexOf(char);

      if (digitIndex !== -1) {
        regularArabicNumber += digitIndex;
      } else {
        regularArabicNumber += char;
      }
    }

    return regularArabicNumber;
  };

  const handleMapChanges = async (event, is_input) => {
    if (is_input) {
      let block = addressDetails.block;
      let street = addressDetails.street;
      if (event.target.name == "street") {
        street = event.target.value;
      } else {
        block = event.target.value;
      }
      // it is input from field
      if (areaDetails?.area && (block || street)) {
        // const encodedPlaceName = encodeURIComponent('kuwait ' + areaDetails?.area + ', block ' + block + ', street ' + street);
        let addressString = "";
        // if (areaDetails?.area.includes("Al")) {
        //     const newString = areaDetails?.area.split(" ").filter((ele, i) => i !== 0).join(' ')
        //     addressString = `${newString}, Kuwait`
        // }
        // else {

        let selectedArea = areaDetails?.area;

        const selectedAra = mapArea.find(
          (ele) =>
            ele.area_name == areaDetails.area
        );
        selectedArea = selectedAra.area_map;
        addressString = `${selectedArea}, Kuwait`;
        // }
        if (block) {
          addressString = `block ${convertArabicIndicToRegularArabic(
            block
          )}, ${addressString}`;
        } else {
          addressString = `block 1, ${addressString}`;
        }
        if (street) {
          addressString = `${convertArabicIndicToRegularArabic(
            street
          )} street, ${addressString}`;
        }

        // const encodedPlaceName = encodeURIComponent('street ' + street + ', block ' + block + ', ' + areaDetails?.area + ', Kuwait');
        const encodedPlaceName = encodeURIComponent(addressString);
        const respones = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodedPlaceName}&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`
        );
        if (respones.status === 200) {
          let lat = respones?.data?.results[0]?.geometry?.location?.lat;
          let lng = respones?.data?.results[0]?.geometry?.location?.lng;
          if (lat && lng) {
            setMarkerPosition({ lat, lng });
            setAddressDetails((a) => ({
              ...a,
              lat: lat,
              lng: lng,
            }));
          }
        }
      }
    }
  };
  const handleMapLoad = () => {
    const forClick = document.getElementById("forClickOnly");
    forClick.click();
  };

  useEffect(() => {
    if (
      internationalDelivery.delivery_country_code &&
      details?.vendor?.international_delivery !== "3" &&
      details?.vendor?.international_delivery !== ""
    ) {
      (async () => {
        const response = await getVendorCountries({
          vendor_id: details.vendor.vendors_id,
          ecom_vendor_id: details.vendor.ecommerce_vendor_id,
          vendor_slug: vendorSlug,
        });
        if (response.status) {
          let countryArray = [];
          response.data.map((ele) => {
            countryArray.push(ele.abbr);
          });
          const currentCountry = response.data.filter(
            (ele) => ele.abbr === internationalDelivery.delivery_country_code
          );
          if (currentCountry && currentCountry.length > 0) {
            setInternationalDelivery({
              ...internationalDelivery,
              delivery_country_code: currentCountry[0].abbr,
              country_id: currentCountry[0].country_id,
              delivery_expected_time: currentCountry[0]?.delivery_expected_time,
              delivery_expected_type: currentCountry[0]?.delivery_expected_type,
            });
          }
          setCountryArray(countryArray);
          setCountryDropDown(response.data);
        }
      })();
    }
  }, []);

  // const changeSelectedCountry=(e)=>{

  // }
  return (
    <>
      <div
        className="holder-container"
        style={{ position: "relative", minHeight: "calc(100vh - 53px)" }}
      >
        <CommonHeader
          englishHeader="Delivery Address"
          arabicHeader="عنوان التسليم"
        />
        <div className="delivery-details-text">
          {language === "ltr" ? "Delivery Details" : "تفاصيل التوصيل"}
        </div>
        {(details?.vendor?.international_delivery !== "3" &&
          details?.vendor?.international_delivery !== "") ||
          internationalDelivery.delivery_country_code.toUpperCase() !== "KW" ? (
          <div style={{ paddingBottom: "10px" }}>
            <label className="country-select-label">
              {language === "ltr" ? "Country" : "دولة"}
            </label>
            <div className="international-country-selector">
              <ReactFlagsSelect
                selected={internationalDelivery.delivery_country_code}
                showSelectedLabel={true}
                searchable
                countries={countryArray}
                onSelect={(code) => {
                  const filterCountry = countryDropDown.filter(
                    (country) => country.abbr === code
                  );
                  setInternationalDelivery({
                    ...internationalDelivery,
                    delivery_country_code: code,
                    country_id: filterCountry[0].country_id,
                    delivery_country: filterCountry[0].country_name,
                    delivery_expected_time:
                      filterCountry[0]?.delivery_expected_time,
                    delivery_expected_type:
                      filterCountry[0]?.delivery_expected_type,
                  });
                  if (code !== "KW") {
                    setAreaDetails({
                      ...areaDetails,
                      area: "",
                      branch: "",
                      branch_id: "",
                      area_id: "",
                      branchForArea: {},
                      deliveryTiming: "",
                      pickupTiming: "",
                      ar_area: "",
                      ar_branch: "",
                      ar_deliveryTiming: "",
                      ar_pickupTiming: "",
                    });
                  }
                }}
              />
            </div>
          </div>
        ) : (
          <></>
        )}
        {details?.vendor?.international_delivery === "3" ||
          details?.vendor?.international_delivery === "" ||
          internationalDelivery.delivery_country_code.toUpperCase() === "KW" ||
          areaDetails.area_id !== "" ? (
          <>
            <DeliveryMapContainer
              selectedArea={areaDetails?.area}
              handleMapChanges={handleMapChanges}
              markerPosition={markerPosition}
              setMarkerPosition={setMarkerPosition}
              selectedBounds={selectedBounds}
              setSelectedBounds={setSelectedBounds}
              triggerClick={handleMapLoad}
            />
            <NewAddressForm
              areaDetails={areaDetails}
              blockValidation={blockValidation}
              streetValidation={streetValidation}
              houseValidation={houseValidation}
              handleMapChanges={handleMapChanges}
              errorState={errorState}
            />
          </>
        ) : (
          <InternationalAddress internationalError={internationalError} />
        )}
      </div>
      <div
        className={`contact-details-bottom-button ${details?.vendor?.home_page_type === "18" && "fashion-theme"
          }`}
      >
        <Link
          className="contact-details-back-button"
          onClick={(e) => {
            e.preventDefault();
            history.push("/contact-details");
          }}
        >
          {language === "ltr" ? "Back" : "خلف"}
        </Link>
        <Link className="contact-details-next-button" onClick={handleNext}>
          {language === "ltr" ? "Next" : "متابعة"}
        </Link>
      </div>
    </>
  );
};

export default NewDeliveryAddress;
