import moment from "moment";
import React, { useContext } from "react";
import { deleteB } from "../api";
import { LanguageContext, VendorContext } from "../App";

function ModalBooking({
  setStepper,
  setPopup,
  setBookingDetails,
  setTabOpen,
  bookingDetails,
  setOpenCal,
}) {
  const { language } = useContext(LanguageContext);
  const details = useContext(VendorContext);

  return (
    <div id="myModal" className="modal-made">
      <div className="modal-content-made">
        <p className="header-modal">
          {language == "ltr" ? "Cannot Book" : "المحل مغلق"}
        </p>
        <p className="text-modal">
          {" "}
          {language == "ltr"
            ? "Sorry we cannot reserve due to the slot being booked. Please try again using different slots."
            : "نأسف, لا يمكن إتمام الحجز لوجود حجز آخر بنفس الوقت الذي حددته, يرجى إعادة المحاولة مرة أخرى."}
        </p>
        <div className="button-container">
          <button
            onClick={() => {
              setBookingDetails(() => ({
                time: [],
                attendee: 2,
                branch: "",
                date: moment()?.locale("en"),
              }));
              setOpenCal(() => true);
              setTabOpen(() => 1);
              setPopup(() => false);
              setStepper(() => 0);
              deleteB({
                vendorId: details.vendor.booking_vendor_id,
                userId: localStorage.getItem("userID"),
                mainVendor:
                  details?.vendor?.home_page_type != 2 &&
                    details?.vendor?.home_page_type != 4
                    ? bookingDetails?.branch?.booking_vendor_id
                    : bookingDetails?.branch?.available_place?.[
                      bookingDetails?.place
                    ]?.booking_vendor_id,
              });
            }}
            className="green"
          >
            {language == "ltr" ? "OK" : "حسناً"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ModalBooking;
