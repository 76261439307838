import React, { useContext, useEffect } from "react";
import { VendorContext } from "../../App";
import BranchList from "./components/BranchList";
import BranchListTitle from "./components/BranchListTitle";
import SnapPixel from "react-snapchat-pixel";

function BranchListPage() {
  const details = useContext(VendorContext);

  useEffect(() => {
    if (
      details?.vendor?.snap_pixel_code &&
      details?.vendor?.snap_pixel_code != ""
    )
      SnapPixel.pageView();
  }, []);

  return (
    <>
      <BranchListTitle></BranchListTitle>
      <BranchList></BranchList>
    </>
  );
}

export default BranchListPage;
