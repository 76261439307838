import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  AreaContext,
  CartContext,
  VendorContext,
  VendorSlugContext,
} from "../../App";
import sha256 from "sha256";
import Products from "../Products";
import ReactPixel from "react-facebook-pixel";
import axios from "axios";
import { API_URL } from "../../services/constants";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Categoryview from "../Categoryview";
import SingleProductView from "../SingleProductView.jsx";
import CategoryContainer from "../HomePage/components/CategoryContainer";
import CommonBack from "../NewCheckOutPage/Components/CommonBack";
import ReviewBar from "../HomePage/components/ReviewBar";
import CategoryLoading from "./CategoryLoading.jsx";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const NewCategoeyModal = () => {
  const details = useContext(VendorContext);
  const [productsData, setProductsDatat] = useState([]);
  const [subCategoryData, setSubCategoryData] = useState([]);
  const { areaDetails } = useContext(AreaContext);
  const [loading, setLoading] = useState(false);
  const vendorSlug = useContext(VendorSlugContext);
  const [hasMore, setHasMore] = useState(0);
  const { category } = useParams();
  const [page, setPage] = useState(0);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [hasSubCategories, setHasSubCategories] = useState(0);
  const { cart } = useContext(CartContext);
  const history = useHistory()
  const [isCategoryChanged, setIsCategoryChanged] = useState(false)

  useEffect(() => {
    if (!(page === 0 && !isCategoryChanged)) {
      if (hasMore) {
        setIsPageLoading((l) => true);
      } else {
        setLoading((loading) => true);
        setHasSubCategories(0);
      }
      axios
        .post(
          `${API_URL}/category-product`,
          JSON.stringify({
            token: process.env.REACT_APP_TOKEN,
            category: category,
            vendor_slug: vendorSlug,
            vendor_id: details?.vendor?.vendors_id,
            area_id: areaDetails?.area_id,
            page: page,
          })
        )
        .then((res) => {
          setLoading((loading) => false);
          setIsPageLoading((l) => false);
          if (res.data.is_subcategory) {
            setHasSubCategories(1);
            setSubCategoryData((subCategoryData) => [...res.data.data]);
            setHasMore((f) => 0);
          } else {
            setHasSubCategories(2);
            setHasMore((f) => res.data.is_more_records);
            if (page) {
              setProductsDatat((productsData) => [
                ...productsData,
                ...res.data.data,
              ]);
            } else {
              setProductsDatat((productsData) => [...res.data.data]);
            }
          }
          if (!res.data.data.length) {
            setProductsDatat([]);
          }
          if (details?.vendor?.fb_pixel_code != "" && category !== "") {
            ReactPixel.trackCustom("ViewCategory", {
              content_name: category,
              content_category: category,
              content_ids: category,
              content_type: "category",
            });
            const time = Date.now();
            const sec = Math.round(time / 1000);
            //static for mijana
            if (vendorSlug == "mijana-restaurant-and-café") {
              axios
                .post(
                  `https://graph.facebook.com/v15.0/${details?.vendor?.fb_pixel_code}/events?access_token=EAAGZA8GMZAs1IBAC9mDImnZCTAdafRzN769x6ZCIRMExueSZBZBfnDkIzGrsP4gZBMZCCwXaSvKNggZBEKdEk3582JWiwecrnZAEHFzfCaYKSNRbltxMm2cSvUrgZBUDpVNZCQAOVWUuzO4m7nbvQn1Wqb94IBbVSexSbwWzAf6TYV80HQF1ZAZAzGcXKB`,
                  {
                    data: [
                      {
                        action_source: "website",
                        event_name: "ViewCategory",
                        event_time: sec,
                        user_data: {
                          client_user_agent:
                            "Mozilla/5.0 (iPhone; CPU iPhone OS 13_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/13.0.5 Mobile/15E148 Safari/604.1",
                          em: [sha256(details?.vendor?.support_mail)],
                        },
                        custom_data: {
                          content_name: category,
                          content_category: category,
                          content_ids: category,
                          content_type: "category",
                        },
                      },
                    ],
                  }
                )
                .then((res) => { })
                .catch((e) => console.log(e));
            }
            // dynamic for all vendors
            if (
              details?.vendor?.fb_access_token &&
              details?.vendor?.fb_access_token != ""
            ) {
              axios
                .post(
                  `https://graph.facebook.com/v15.0/${details?.vendor?.fb_pixel_code}/events?access_token=${details?.vendor?.fb_access_token}`,
                  {
                    data: [
                      {
                        action_source: "website",
                        event_name: "ViewCategory",
                        event_time: sec,
                        user_data: {
                          client_user_agent:
                            "Mozilla/5.0 (iPhone; CPU iPhone OS 13_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/13.0.5 Mobile/15E148 Safari/604.1",
                          em: [sha256(details?.vendor?.support_mail)],
                        },
                        custom_data: {
                          content_name: category,
                          content_category: category,
                          content_ids: category,
                          content_type: "category",
                        },
                      },
                    ],
                  }
                )
                .then((res) => { })
                .catch((e) => console.log(e));
            }
          }
        }).finally(() => {
          setIsCategoryChanged(false)
        })
        .catch((e) => console.log(e));
    }
  }, [page, isCategoryChanged]);

  useEffect(() => {
    if (category) {
      setIsCategoryChanged(true)
    }
  }, [category])

  const getCategoryName = () => {
    const filterCategory = details?.categories.filter(
      (ele) => ele.category_slug == category
    );
    if (filterCategory.length) {
      return {
        eng: filterCategory[0].category_name,
        ar: filterCategory[0].category_name_ar,
      };
    } else {
      return { eng: "", ar: "" };
    }
  };

  const observer = useRef();

  const lastBookElementRef = useCallback(
    (node) => {
      if (isPageLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isPageLoading, hasMore]
  );

  const handleBackClick = () => {
    setProductsDatat([]);
    setHasMore(0);
    setPage(0);
    history.goBack();
  }
  return (
    <div>
      {hasSubCategories === 1 ? (
        <>
          <div style={{ padding: "8px 16px" }}>
            <CommonBack
              english_title={getCategoryName().eng}
              arabic_title={getCategoryName().ar}
              variant="light"
            />
          </div>
          <CategoryContainer categories={subCategoryData}></CategoryContainer>
        </>
      ) : hasSubCategories === 2 ? (
        <>
          {(details?.vendor?.home_page_type == 10 ||
            details?.vendor?.home_page_type == 14 ||
            details?.vendor?.home_page_type == 17 ||
            details?.vendor?.home_page_type == 11 ||
            details?.vendor?.home_page_type == 18) && (
              <Products
                loading={loading}
                products={productsData}
                lastBookElementRef={lastBookElementRef}
                isPageLoading={isPageLoading}
                handleBackClick={handleBackClick}
              />
            )}
          {(details?.vendor?.home_page_type == 13 ||
            details?.vendor?.home_page_type == 12 ||
            details?.vendor?.home_page_type == 15 ||
            details?.vendor?.home_page_type == 19) && (
              <Categoryview
                loading={loading}
                products={productsData}
                isPageLoading={isPageLoading}
                lastBookElementRef={lastBookElementRef}
                handleBackClick={handleBackClick}

              />
            )}
          {details?.vendor?.home_page_type == 16 && (
            <SingleProductView
              loading={loading}
              products={productsData}
              isPageLoading={isPageLoading}
              lastBookElementRef={lastBookElementRef}
              handleBackClick={handleBackClick}
            />
          )}
        </>
      ) : (
        <CategoryLoading
          type={details?.vendor?.home_page_type}
          isSubCategory={
            details?.categories.find((ele) => ele.category_slug == category)
              ?.is_subcategory
          }
        />
      )}
      {cart?.cartCount && <ReviewBar></ReviewBar>}
    </div>
  );
};

export default NewCategoeyModal;
