import React, { useContext } from "react";
import { CartContext, LanguageContext, VendorContext } from "../../../App";

function Footer({ text }) {
  const { cart } = useContext(CartContext);
  const details = useContext(VendorContext);
  const { language } = useContext(LanguageContext);
  return (
    <>
      {
        <footer
          className={`${details?.vendor?.slug_type == 2 ? "" : "pt-3"
            } footer ${text} ${cart?.cartCount ? "footer-cart" : ""}`}
          style={{ minHeight: 230 }}

        >
          <a
            href="http://payzah.com/"
            style={{
              width: 220,
              height: 132,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <div>
              <div
                style={{
                  color: "gray",
                  display: "flex",
                  alignItems: "center",
                  margin: `${language === "ltr" ? "0 0 0 10px" : "0 35px 0 0"}`,
                  fontSize: `${language === "ltr" ? "16px" : "18px"}`,
                  marginBottom: "5px"
                }}
              >
                {language === "ltr" ? "Powered By" : "مدعوم من قبل"}
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "space-evenly",
                  marginBottom: 10,
                }}
              >
                <img
                  style={{ height: "70px", width: "auto" }}
                  src={language === "ltr" ? "pictures/payzahFooterImg.png" : "pictures/arPayzahFooterImg.png"}
                ></img>
              </div>
            </div>
          </a>
        </footer>
      }
    </>
  );
}

export default Footer;
