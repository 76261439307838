import React, { useContext } from 'react'
import { LanguageContext } from '../../../App'

const CommonSectionHeader = ({ englishHeader, arabicHeader, color }) => {
    const { language } = useContext(LanguageContext)
    return (
        <div className='commonSection-div' style={{ color: color ? color : "#A1A1A1" }}>
            {
                language === "ltr" ?
                    englishHeader :
                    arabicHeader
            }
        </div>
    )
}

export default CommonSectionHeader