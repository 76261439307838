import React, { useContext } from "react";
import { LanguageContext, VendorContext } from "../../App";
import "./vendorLogo.css";

const VendorDesktopLogo = () => {
  const details = useContext(VendorContext);
  const { language } = useContext(LanguageContext);
  return (
    <div className="vendorDesktop-main">
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "8px",
          }}
        >
          <img
            src={
              language == "ltr"
                ? details?.vendor?.english_new_background
                : details?.vendor?.arabic_new_background
            }
            className="vendorDesktop-img"
          />
        </div>

        <div className="vendorDesktop-name">
          {language == "ltr" ? details?.vendor?.name : details?.vendor?.name_ar}
        </div>

        <div className="vendorDesktop-slogan">
          {language == "ltr"
            ? details?.vendor?.slogan
            : details?.vendor?.slogan_ar}
        </div>
      </div>
    </div>
  );
};

export default VendorDesktopLogo;
