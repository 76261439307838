import React, { useContext } from "react";
import { AreaContext, VendorSlugContext } from "../../../App";

const ModeSelector = ({ details, language }) => {
  const { areaDetails, setAreaDetails } = useContext(AreaContext);
  const vendorSlug = useContext(VendorSlugContext);
  return (
    <div className="modeSelector-mainDivNew">
      <div
        className={`modeSelector-tabsNew ${
          details?.vendor?.is_pickup === "1" &&
          vendorSlug != "alawael-bilingual-school"
            ? areaDetails.type === "delivery"
              ? "left"
              : "right"
            : "selector-full"
        }`}
      >
        {vendorSlug != "alawael-bilingual-school" && (
          <div
            className={`modeSelector-insidetabsNew ${
              areaDetails.type === "delivery" ? "active" : ""
            } ${details?.vendor?.home_page_type === "18" && "fashion-theme"} `}
            onClick={(e) => {
              e.preventDefault();
              setAreaDetails({ ...areaDetails, type: "delivery" });
            }}
          >
            {language === "ltr" ? "Delivery" : "توصيل"}
          </div>
        )}
        {details?.vendor?.is_pickup === "1" ? (
          <div
            className={`modeSelector-insidetabsNew ${
              areaDetails.type === "pickup" ? "active" : ""
            } ${details?.vendor?.home_page_type === "18" && "fashion-theme"}`}
            onClick={(e) => {
              e.preventDefault();
              setAreaDetails({ ...areaDetails, type: "pickup" });
            }}
          >
            {language === "ltr" ? "Pickup" : "استلام"}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ModeSelector;
