import React, { useContext, useState } from "react";
import { CartContext, LanguageContext } from "../App";
import CategoryListTab from "./CategoryListTab";
import $ from "jquery";

function CategoryListView({
  categories,
  setcategoryopen,
  setcategorynow,
  categorynow,
}) {
  const { cart } = useContext(CartContext);
  const { language } = useContext(LanguageContext);

  const onCategorySelect = (category, k) => {
    setcategorynow(() => category?.category_name);
    $("#categoryflex").animate(
      {
        scrollLeft:
          $(`#cathort${k}`).position().left +
          $(`#cathort${k}`).width() / 2 +
          $("#categoryflex").scrollLeft() -
          $("#categoryflex").width() / 2,
      },
      "slow"
    );
    $("html, body").animate(
      {
        scrollTop:
          $(`#category${k}`).offset().top -
          (window.screen.width < 991 ? 107 : 59),
      },
      "slow"
    );
  };
  return (
    <div className={cart?.cartCount ? "bottom-category-accordian" : ""}>
      <div className="list-sticky-top">
        <div id={"categoryflex"} className="list-select-flex">
          {categories?.map((category, i) => (
            <div
              id={`cathort${i}`}
              onClick={() => onCategorySelect(category, i)}
              className={`list-select-button ${category?.category_name == categorynow ? "list-select" : ""
                }`}
            >
              {language == "ltr"
                ? category?.category_name
                : category?.category_name_ar}
            </div>
          ))}
        </div>
      </div>
      {categories?.map((category, i) => (
        <CategoryListTab
          category={category}
          idx={i}
          setcategoryopen={setcategoryopen}
        ></CategoryListTab>
      ))}
    </div>
  );
}

export default CategoryListView;
