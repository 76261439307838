import React, { useContext, useEffect } from 'react'
import { AreaContext, InterNationalContext, LanguageContext, VendorContext } from '../../App'
import ModeSelector from './Component/ModeSelector'
import './neworderMode.css'
import DeliveryAreas from './Component/DeliveryAreas'
import PickupArea from './Component/PickupArea'
import CommonBack from '../NewCheckOutPage/Components/CommonBack'
import HomepageDesign from '../InterNationalDelivery/HomepageDesign'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
const NewOrderMode = () => {
    const details = useContext(VendorContext)
    const { language } = useContext(LanguageContext)
    const { areaDetails } = useContext(AreaContext);
    const { internationalDelivery } = useContext(InterNationalContext)
    const history = useHistory()

    useEffect(() => {
        if (internationalDelivery && internationalDelivery?.delivery_country_code !== "KW") {
            history.push('/')
        }
    }, [internationalDelivery?.delivery_country_code])


    const handleCountryChange = (code) => {
        if (internationalDelivery && code !== "KW") {
            history.push('/')
        }
    }

    return (
        <div>
            <CommonBack
                variant="light"
            />
            {
                details?.vendor?.international_delivery !== "3" && details?.vendor?.international_delivery !== "" ?
                    <div style={{ padding: "0 19px" }}>
                        <HomepageDesign handleCountryChange={handleCountryChange} />
                    </div>
                    : null
            }
            {
                internationalDelivery && internationalDelivery.delivery_country_code === "KW" ?
                    <div className={`newOrderMode-mainDiv `}>
                        <ModeSelector
                            details={details}
                            language={language}
                        />
                        {
                            areaDetails?.type === "delivery" ?
                                <DeliveryAreas />
                                :
                                areaDetails?.type === "pickup" ? <PickupArea /> : null
                        }
                    </div>
                    : null
            }
        </div>
    )
}

export default NewOrderMode