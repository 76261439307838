import React, { useContext, useEffect, useState } from "react";
import $ from "jquery";
import { LanguageContext } from "../../../App";
import ProductsList from "../../Products/components/ProductsList";
import SubCategoriesList from "../../SubCategories/SubCategoriesList";

function ToggleCategoryList({ category, categorynow, idx, setcategorynow }) {
  const [loading, setLoading] = useState(false);
  const { language } = useContext(LanguageContext);
  return (
    <div className="toggle-div-tag" id={`category${idx}`}>
      <button
        onClick={() => {
          if (categorynow == category?.category_name) setcategorynow(() => "");
          else setcategorynow(() => category?.category_name);
          setTimeout(() => {
            $("html, body").animate(
              {
                scrollTop:
                  $(`#category${idx}`).offset().top -
                  (window.screen.width < 991 ? 48 : 0),
              },
              "slow"
            );
          }, 100);
        }}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingTop: "5px",
          paddingBottom: "5px",
          height: "auto",
        }}
        className={`category-accordion toggle-accordion ${
          category?.category_name == categorynow ? "active-accordion" : ""
        }`}
      >
        <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
          <img
            className="category-img-toggle"
            src={category?.category_image}
          ></img>
          <p style={{ margin: "0 15px" }}>
            {language === "ltr"
              ? category?.category_name
              : category?.category_name_ar}
          </p>
          <span className="product-number category-product-number">
            <span>{category?.products?.length}</span>
          </span>
        </div>
      </button>
      <div
        className={`area-list-collapse ${
          category?.category_name == categorynow ? "" : "d-none"
        }`}
      >
        {category?.is_subcategory ? (
          <SubCategoriesList categories={category?.products} />
        ) : (
          <ProductsList
            products={category?.products}
            loading={loading}
          ></ProductsList>
        )}
      </div>
    </div>
  );
}

export default ToggleCategoryList;
