import React, { useContext } from "react";
import { LanguageContext } from "../../../App";

function BookingHeader({ stepper }) {
  const { language } = useContext(LanguageContext);
  return (
    <header className="delivery-order-header">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
          <p
            className="delivery-order-title text-center"
            style={{ position: "relative" }}
          >
            {language == "ltr" ? "Reservation" : "الحجز"}
          </p>
        </div>
      </div>
      <div className="stepper-bar mt-2">
        <div className={`step ${stepper === 0 ? "step-active" : ""}`}></div>
        <div className={`step ${stepper === 1 ? "step-active" : ""}`}></div>
        <div className={`step ${stepper === 2 ? "step-active" : ""}`}></div>
      </div>
    </header>
  );
}

export default BookingHeader;
