import React from "react";

const Arrow = ({ height, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={height}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M7.5 15L12.5 10L7.5 5"
        stroke={color ? color : "black"}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Arrow;
