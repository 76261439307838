import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import { AreaContext, LanguageContext, VendorContext } from '../../../App';
import "moment/locale/ar-sa";
import ModeSelector from '../../NewOrderMode/Component/ModeSelector';

function MobileOrderType() {
    const { language } = useContext(LanguageContext);
    const details = useContext(VendorContext);
    const { areaDetails, } = useContext(AreaContext);

    return (
        <div>
            <div className="order-type-select delivery-address-time-div remove-padding" style={{ marginBottom: '0px', padding: '16px 0', marginTop: "0rem", background: "rgb(245, 245, 245)", paddingTop: "25px !important" }}>
                <ModeSelector
                    details={details}
                    language={language}
                />
            </div>
            <div className="order-type-select delivery-address-time-div" style={{ borderBottom: "0.5px solid #d9d9d9", marginTop: "0rem" }}>
                <div className='select-delivery-div'>
                    <div className="delivery-select" >
                        {areaDetails.type === "delivery" ? (
                            <Link to={`/area`} className="delivery-detail-selected-new">
                                <Link to={`/area`} className="area-select-delivery d-flex justify-content-between">
                                    <div className='area-select-delivery-text' >
                                        {
                                            areaDetails?.area != "" ? language === "ltr" ? "Kuwait" : "الكويت" : ""
                                        } {areaDetails?.area != "" ? ", " : ""}
                                        {language === "ltr"
                                            ? areaDetails?.area != ""
                                                ? areaDetails?.area
                                                : "Select Your Delivery Location"
                                            : areaDetails?.area != ""
                                                ? areaDetails?.ar_area
                                                : "حدد موقع التسليم الخاص بك"}
                                    </div>
                                    <div style={{ width: "20px" }}>
                                        <i
                                            style={{ color: details?.vendor?.vendor_color }}
                                            className="fa fa-angle-right right-arrow"
                                        ></i>
                                    </div>
                                </Link>
                            </Link>
                        ) : (
                            <Link to={`/area`} className="delivery-detail-selected-new">
                                <Link to={`/area`} className="area-select-delivery d-flex justify-content-between">
                                    <div className='area-select-delivery-text' >
                                        {language === "ltr"
                                            ? areaDetails?.branch != ""
                                                ? areaDetails?.branch
                                                : "Select Branch"
                                            : areaDetails?.branch != ""
                                                ? areaDetails?.ar_branch
                                                : "حدد الفرع"}
                                    </div>
                                    <div style={{ width: "20px" }}>
                                        <i
                                            style={{ color: details?.vendor?.vendor_color }}
                                            className="fa fa-angle-right right-arrow"
                                        ></i>
                                    </div>
                                </Link>
                            </Link>
                        )}
                    </div>
                </div>
            </div>
        </div >
    )
}

export default MobileOrderType