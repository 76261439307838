import React, { useContext } from "react";
import { LanguageContext, VendorContext } from "../../App";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { LazyLoadImage } from "react-lazy-load-image-component";
import BrokenImage from "../Common/BrokenImage";

const SubCategoriesList = ({ categories }) => {
  const details = useContext(VendorContext);
  const { language } = useContext(LanguageContext);

  return (
    <div className="products-main-div">
      <ul className="products-list">
        {categories.length > 0 ? (
          categories.map((item, i) => (
            <li className={`products-list-item`}>
              <Link
                to={`/products=${item?.category_slug}`}
                className="product-div"
              >
                <div>
                  <Link
                    className="product-image-link"
                    to={`/products=${item?.category_slug}`}
                  >
                    {/* <LazyLoadImage
                      style={{
                        borderRadius:
                          details?.vendor?.home_page_type == "18"
                            ? "0px"
                            : "6px",
                      }}
                      src={`${item?.category_image}`}
                      className="product-list-image"
                    ></LazyLoadImage> */}
                    <BrokenImage
                      style={{
                        borderRadius:
                          details?.vendor?.home_page_type == "18"
                            ? "0px"
                            : "6px",
                      }}
                      src={item?.category_image}
                      imgClass={"product-list-image"}
                      fallbackSrc={
                        language === "ltr"
                          ? details?.vendor?.english_new_background
                          : details?.vendor?.arabic_new_background
                      }
                    />
                  </Link>
                </div>
                <div className="product-list-description">
                  <Link
                    className="product-list-title"
                    to={`/products=${item?.category_slug}`}
                  >
                    {language === "ltr"
                      ? item?.category_name
                      : item?.category_name_ar}
                  </Link>
                </div>
              </Link>
            </li>
          ))
        ) : (
          <div>
            {language === "ltr"
              ? "Subcategories are unavailable"
              : "الفئات الفرعية غير متوفرة"}
          </div>
        )}
      </ul>
    </div>
  );
};

export default SubCategoriesList;
