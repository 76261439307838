import React from "react";
import ProductItem from "./ProductItem";

function ProductsList({ loading, products, lastBookElementRef }) {
  return (
    <div className="products-main-div">
      <ul className="products-list">
        {!loading
          ? products.map((item, i) => {
              if (i == products?.length - 1 && lastBookElementRef) {
                return (
                  <div ref={lastBookElementRef}>
                    <ProductItem
                      key={item?.product_slug}
                      item={item}
                    ></ProductItem>
                  </div>
                );
              } else
                return (
                  <ProductItem
                    key={item?.product_slug}
                    item={item}
                  ></ProductItem>
                );
            })
          : [...Array(7)].map((k, i) => (
              <>
                <div className="products-list-item shine-design">
                  <div className="product-div">
                    <div className="product-image-link">
                      <div
                        className="product-list-image"
                        style={{ backgroundColor: "#f3f3f3" }}
                      ></div>
                    </div>
                    <div className="product-list-description">
                      <div
                        style={{
                          backgroundColor: "#f3f3f3",
                          width: "40%",
                          height: "1.2rem",
                          marginBottom: "10px",
                        }}
                      ></div>
                      <div
                        style={{
                          backgroundColor: "#f3f3f3",
                          width: "95%",
                          height: "1rem",
                          marginBottom: "3px",
                        }}
                      ></div>
                      <div
                        style={{
                          backgroundColor: "#f3f3f3",
                          width: "90%",
                          height: "1rem",
                          marginBottom: "5px",
                        }}
                      ></div>
                      <div className="product-cost-div">
                        <div
                          className="price-bubble"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <div
                            style={{
                              backgroundColor: "#f3f3f3",
                              width: 100,
                              height: "1.3rem",
                              margin: "0 5px",
                              display: "inline-block",
                            }}
                          ></div>
                          <div
                            style={{
                              backgroundColor: "#f3f3f3",
                              width: 24,
                              height: "1.5rem",
                              display: "inline-block",
                              borderRadius: "50%",
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ))}
      </ul>
    </div>
  );
}

export default ProductsList;
