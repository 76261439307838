import React, { useContext } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import { CartContext, LanguageContext, VendorContext } from "../../../App";
import BrokenImage from "../../Common/BrokenImage";

function CategoryContainer({ categories }) {
  const details = useContext(VendorContext);
  const { language } = useContext(LanguageContext);
  const { cart } = useContext(CartContext);
  return (
    <div className="category-container">
      <div className="container">
        <div className={`row  ${cart?.cartCount ? "cart-bottom" : ""}`}>
          {categories?.map((category) => {
            return (
              <div className="col-lg-6 col-md-6  col-sm-6 col-6 mt-3 category-tab">
                <Link
                  className="category-link"
                  to={`/products=${category.category_slug}`}
                >
                  {/* <LazyLoadImage
                    src={
                      category.category_image
                        ? category.category_image
                        : language === "ltr"
                        ? details?.vendor?.english_new_background
                        : details?.vendor?.arabic_new_background
                    }
                    className="category-image img-fluid"
                    placeholderSrc={"pictures/ffffff.jpg"}
                  ></LazyLoadImage> */}
                  <BrokenImage
                    src={category.category_image}
                    imgClass={"category-image img-fluid"}
                    fallbackSrc={
                      language === "ltr"
                        ? details?.vendor?.english_new_background
                        : details?.vendor?.arabic_new_background
                    }
                  />
                  <p className="category-title pb-3">
                    {language === "ltr"
                      ? category.category_name
                      : category.category_name_ar}
                  </p>
                </Link>
              </div>
            );
          })}
        </div>
        <div className="no-data"> </div>
      </div>
    </div>
  );
}

export default CategoryContainer;
