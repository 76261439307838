import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import {
  InterNationalContext,
  LanguageContext,
  VendorContext,
} from "../../../App";

const NewPaymentSelector = ({ payment, setPayment, setWidth, width }) => {
  const details = useContext(VendorContext);
  const { language } = useContext(LanguageContext);
  const { internationalDelivery } = useContext(InterNationalContext);
  const resizer = () => {
    setWidth((i) => document.getElementById("slider-width")?.offsetWidth);
  };
  const [isSafari, setIsSafari] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const userAgent = navigator.userAgent.toLowerCase();
      const isSafariBrowser = /iPhone|iPad|iPod|Macintosh/i.test(
        navigator.userAgent
      );
      setIsSafari(isSafariBrowser);
    }
  }, []);

  useEffect(() => {
    if (isSafari && details?.vendor?.is_apple_pay?.length > 0) {
      setPayment(4);
    }
  }, [isSafari]);

  useEffect(() => {
    resizer();
    window.addEventListener("resize", resizer);
    return window.removeEventListener("resize", resizer, true);
  }, []);

  const getMethodsCount = () => {
    return (
      details?.vendor?.is_apple_pay?.length > 0 &&
      isSafari &&
      details?.vendor?.is_credit_card != 0 &&
      details?.vendor?.is_cod == 1 &&
      (details.vendor.international_delivery === "3" ||
        details.vendor.international_delivery === "" ||
        internationalDelivery.delivery_country_code.toLowerCase() === "kw")
    );
  };
  return (
    <div className="payment-details-headDIv">
      <div className="delivery-payment-details-heading">
        {language === "ltr" ? "Payments Details" : "طرق الدفع"}
      </div>
      <div
        className={`payby-payzah-section ${
          details?.vendor?.home_page_type === "18" && "fashion-theme-border"
        }`}
      >
        <div className="payby-text">
          {language === "ltr" ? "Payment provider" : "الدفع بواسطة"}
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src="pictures/payzahlogoicon.png" className="payby-image" />
        </div>
        <div className="payzah-text">
          {" "}
          {language === "ltr" ? "Payzah" : " بــيـزة"}
        </div>
      </div>
      <div
        className={`payment-details-mainDiv ${
          getMethodsCount() ? "fourbuttons" : ""
        }`}
      >
        {details?.vendor?.is_apple_pay?.length > 0 && isSafari ? (
          <Link
            onClick={(e) => {
              e.preventDefault();
              setPayment(4);
            }}
            className={`intro-flex payment-details-holder  ${
              payment == 4 ? "active" : ""
            }  ${
              details?.vendor?.home_page_type === "18" && "fashion-theme-border"
            }`}
          >
            <span className="del-ic" style={{ padding: 0 }}>
              <img
                style={{
                  width: "40px",
                  height: "30px",
                  objectFit: "contain",
                }}
                src={"pictures/newApplePayButton.png"}
                className="img-fluid"
              ></img>
            </span>
          </Link>
        ) : null}
        <Link
          id="slider-width"
          onClick={(e) => {
            e.preventDefault();
            setPayment(1);
          }}
          className={`intro-flex payment-details-holder  ${
            payment == 1 ? "active" : ""
          }  ${
            details?.vendor?.home_page_type === "18" && "fashion-theme-border"
          }`}
        >
          <span className="del-ic" style={{ padding: 0 }}>
            <img
              style={{ width: 38, height: 28 }}
              src={"pictures/knet icon mini.png"}
              className="img-fluid"
            ></img>
          </span>
        </Link>
        {details?.vendor?.is_credit_card != 0 ? (
          <Link
            onClick={(e) => {
              e.preventDefault();
              setPayment(2);
            }}
            className={`intro-flex payment-details-holder  ${
              payment == 2 ? "active" : ""
            }  ${
              details?.vendor?.home_page_type === "18" && "fashion-theme-border"
            }`}
          >
            <span className="del-ic" style={{ padding: 0 }}>
              <img
                style={{
                  width: "40px",
                  height: "30px",
                  objectFit: "contain",
                }}
                src={"pictures/visa.png"}
                className="img-fluid"
              ></img>{" "}
              <img
                style={{
                  width: "40px",
                  height: "30px",
                  objectFit: "contain",
                }}
                src={"pictures/master.png"}
                className="img-fluid"
              ></img>
            </span>
          </Link>
        ) : null}
        {details?.vendor?.is_cod == 1 &&
        (details.vendor.international_delivery === "3" ||
          details.vendor.international_delivery === "" ||
          internationalDelivery.delivery_country_code.toLowerCase() ===
            "kw") ? (
          <Link
            onClick={(e) => {
              e.preventDefault();
              setPayment(3);
            }}
            className={`intro-flex payment-details-holder  ${
              payment == 3 ? "active" : ""
            }  ${
              details?.vendor?.home_page_type === "18" && "fashion-theme-border"
            }`}
          >
            <span className="del-ic" style={{ padding: 0 }}>
              <img
                style={{ width: "auto", height: 30, width: 35 }}
                src={"pictures/icons8-money-64.png"}
                className="img-fluid"
              ></img>
            </span>
          </Link>
        ) : null}
      </div>
    </div>
  );
};

export default NewPaymentSelector;
