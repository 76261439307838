import axios from "axios";
import moment from "moment";
import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  BookingSetContext,
  LanguageContext,
  VendorContext,
  VendorSlugContext,
} from "../../../App";
import "react-calendar/dist/Calendar.css";
import Calendar from "react-calendar";
import { BOOK_URL } from "../../../services/constants";
import $ from "jquery";
import { add } from "../../../api";

function BookingVendor({
  setStepper,
  bookingDetails,
  setBookingDetails,
  openCal,
  setOpenCal,
  tabOpen,
  setTabOpen,
}) {
  const vendorSlug = useContext(VendorSlugContext);
  const { language } = useContext(LanguageContext);
  const details = useContext(VendorContext);
  const { bookingSets, setBookingSets } = useContext(BookingSetContext);

  useEffect(() => {
    if (bookingDetails?.date)
      setTimeout(
        () =>
          onDateSelect(
            `date${moment(bookingDetails?.date)
              ?.locale("en")
              .format("DDMMYYYY")}`
          ),
        100
      );
  }, []);

  const onDateSelect = (k) => {
    $("#date-scroller").animate(
      {
        scrollLeft:
          $(`#${k}`)?.position()?.left +
          $(`#${k}`)?.width() / 2 +
          $("#date-scroller")?.scrollLeft() -
          $("#date-scroller")?.width() / 2,
      },
      "slow"
    );
  };

  const checkNext = () => {
    if (bookingDetails?.branch?.name && bookingDetails?.time?.length != 0)
      return true;
    else return false;
  };

  const onMinus = () => {
    if (bookingDetails?.attendee != 1) {
      setBookingDetails((a) => ({ ...a, attendee: a?.attendee - 1 }));
    }
  };

  const onPlus = () => {
    if (bookingDetails?.attendee != bookingSets?.maxAttend) {
      setBookingDetails((a) => ({ ...a, attendee: a?.attendee + 1 }));
    }
  };

  const sortTime = (i, j) => {
    return i?.slot_start_time < j?.slot_start_time ? -1 : 1;
  };

  const mapTimeToPlace = (
    time,
    places = bookingDetails?.branch?.available_place
  ) => {
    let mappedPlace = places.filter((place) => {
      let logic = time.every((t) =>
        place.formated_slot.includes(`${t.slot_start_time}-${t.slot_end_time}`)
      );
      return logic;
    });
    if (mappedPlace.length != 0) {
      let index = places.findIndex(
        (place) => place.booking_place_id == mappedPlace[0].booking_place_id
      );
      return index;
    } else return -1;
  };

  const onTimeClick = (i) => {
    let temp = bookingDetails?.time;
    if (bookingSets?.is_multi_slot == 1 && bookingSets?.max_slot != 1) {
      if (
        !bookingDetails?.time?.some(
          (t) => t?.slot_start_time == i?.slot_start_time
        )
      ) {
        if (temp?.length < Number(bookingSets?.max_slot)) temp?.push(i);
      } else {
        temp = temp.filter((f) => f?.slot_start_time !== i?.slot_start_time);
      }
      temp = temp?.sort(sortTime);
    } else {
      if (
        !bookingDetails?.time?.some(
          (t) => t?.slot_start_time == i?.slot_start_time
        )
      ) {
        temp = [i];
      } else temp = [];
    }

    setBookingDetails((s) => ({
      ...s,
      time: temp,
      place: mapTimeToPlace(temp),
    }));
  };

  const createTemp = (p, date) => {
    let temp = p;
    temp = temp.map((vendor) => {
      let placeTemp = vendor.available_place;

      placeTemp = placeTemp.map((place) => {
        let { booking_place_id, formated_slot } = place;
        let available_slot = date?.locale("en").format("YYYY-MM-DD");

        let tempFormatedSlot = formated_slot.filter((time) => {
          return !bookingSets.cronBooked.some((cron) => {
            let isPlaceSame = cron.placeId == booking_place_id;
            let isDatesSame = cron.dates.includes(available_slot);
            let isTimeSame = cron.timeSlots.some(
              (a) => `${a.slot_start_time}-${a.slot_end_time}` == time
            );
            return isPlaceSame && isDatesSame && isTimeSame;
          });
        });
        return { ...place, formated_slot: tempFormatedSlot };
      });

      placeTemp = placeTemp.filter((place) => {
        return place.formated_slot.length != 0;
      });


      return {
        ...vendor,
        available_place: placeTemp,
      };
    });

    temp = temp?.filter((vendor) => {
      return vendor.available_place.length != 0;
    });

    if (temp.length != 0) {
      setBookingSets((set) => ({
        ...set,
        branchAvail: false,
        places: temp,
        baseVendor: p,
      }));
    } else {
      setBookingSets((set) => ({
        ...set,
        branchAvail: true,
        baseVendor: p,
        places: [],
      }));
    }

    if (bookingDetails.branch != "" && temp?.length != 0) {
      let branch = temp?.filter(
        (t) => t.booking_vendor_id == bookingDetails.branch.booking_vendor_id
      )[0];

      if (bookingDetails?.time?.length != 0) {
        let timeTemp = bookingDetails.time.filter((time) =>
          temp.some((vendor) =>
            vendor.unique_slot.some(
              (slot) =>
                slot.slot_start_time == time.slot_start_time &&
                slot.slot_end_time == time.slot_end_time &&
                vendor?.booking_vendor_id ==
                bookingDetails?.branch?.booking_vendor_id
            )
          )
        );
      }
      setBookingDetails((book) => ({
        ...book,
        // time: timeTemp,
        branch: { ...branch, v_name: branch.name, v_name_ar: branch.name_ar },
        place: mapTimeToPlace(book.time, branch.available_place),
      }));
    } else {
      setBookingDetails((book) => ({
        ...book,
        branch: "",
        time: [],
      }));
      setTabOpen(2);
    }
  };

  const onDateClick = (d) => {
    setBookingSets((set) => ({
      ...set,
      uniqueMorning: [],
      uniqueEvening: [],
      slotAvail: true,
      places: [],
    }));
    axios
      .post(
        `${BOOK_URL}/get-club-time`,
        JSON.stringify({
          token: process.env.REACT_APP_TOKEN,
          vendor_slug: vendorSlug,
          booking_vendor_id: details?.vendor?.booking_vendor_id,
          booking_date: d?.locale("en").format("YYYY-MM-DD"),
        })
      )
      .then((res) => {
        if (res.data.data.status) {
          let temp = Object.values(res.data.data.details);
          temp = temp.filter(
            (place) =>
              (place.booking_vendor_id !== "18" &&
                place.booking_vendor_id !== "19") ||
              moment(d).startOf("date") <
              moment("05-06-2022", "DD-MM-YYYY").startOf("date")
          );
          temp = temp.map((t) => ({
            ...t,
            unique_slot: Object.values(t.unique_slot),
          }));
          if (bookingSets.cronBooked.length !== 0) createTemp(temp, d);
          else
            setBookingSets((set) => ({
              ...set,
              branchAvail: temp.length === 0 ? true : false,
              places: temp,
              baseVendor: temp,
            }));
        } else {
          setBookingSets((set) => ({
            ...set,
            branchAvail: true,
            places: [],
            baseVendor: [],
          }));
        }
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    if (bookingSets.baseVendor.length !== 0) {
      let temp = bookingSets?.baseVendor;
      createTemp(temp, bookingDetails?.date);
    }
  }, [bookingSets.cronBooked]);

  const onBranchClick = (k) => {
    setBookingDetails((book) => ({
      ...book,
      branch: {
        ...k,
        v_name: k?.name,
        v_name_ar: k?.name_ar,
      },
      time: [],
    }));
    setTabOpen((tab) => 3);
  };

  useEffect(() => {
    if (bookingDetails.branch != "")
      setBookingSets((set) => ({
        ...set,
        uniqueMorning: Object.values(bookingDetails.branch.unique_slot)
          ?.filter((time) => time.slot_start_time < "12:00:00")
          ?.sort(sortTime),
        uniqueEvening: Object.values(bookingDetails.branch.unique_slot)
          ?.filter((time) => time.slot_start_time >= "12:00:00")
          ?.sort(sortTime),
      }));
  }, [bookingDetails.branch]);

  return (
    <>
      <div style={{ paddingBottom: 10 }}>
        <div
          className="checkout-step-one"
          style={{ paddingRight: 0, paddingLeft: 0 }}
        >
          <div className="checkout-one" style={{ backgroundColor: "#f5f5f9" }}>
            <div className="booking-single-div">
              <div className="booking-flex-div">
                <p className="booking-sub-title">
                  {language == "ltr" ? "Date" : "التاريخ"}
                </p>
                <p className="booking-info-text">
                  {language == "ltr" ? (
                    bookingDetails?.date?.format("D MMM YYYY")
                  ) : (
                    <>
                      <span style={{ fontSize: 18 }} className="number-span">
                        {bookingDetails?.date?.format("D")}
                      </span>{" "}
                      {moment(bookingDetails?.date)
                        ?.locale("ar")
                        ?.format("MMM")}{" "}
                      <span style={{ fontSize: 18 }} className="number-span">
                        {bookingDetails?.date?.format("YYYY")}
                      </span>
                    </>
                  )}
                </p>
              </div>
              <div className="pt-3">
                <p
                  className="booking-info-text open-calendar"
                  onClick={() => setOpenCal((a) => !a)}
                >
                  {!openCal
                    ? language == "ltr"
                      ? "Open Calendar"
                      : "افتح التقويم"
                    : language == "ltr"
                      ? "Close Calendar"
                      : "اغلاق الرزنامة"}
                </p>
                <div
                  style={openCal ? {} : { display: "none" }}
                  className="booking-calendar-container"
                >
                  <Calendar
                    calendarType="Arabic"
                    minDetail="year"
                    onChange={(e) => {
                      setBookingDetails((a) => ({
                        ...a,
                        date: moment(e)?.locale("en"),
                        branch: "",
                        place: 0,
                      }));
                      onDateClick(moment(e)?.locale("en"));
                      setOpenCal((a) => !a);
                      setTabOpen((a) => 2);
                      setTimeout(() => {
                        onDateSelect(
                          `date${moment(e).locale("en").format("DDMMYYYY")}`
                        );
                      }, 100);
                    }}
                    value={bookingDetails?.date?.toDate()}
                    minDate={bookingSets?.dateNow?.toDate()}
                    maxDate={moment(bookingSets?.dateNow)
                      ?.add(Number(bookingSets.max_date_selection) - 1, "days")
                      .toDate()}
                    locale={language == "ltr" ? "en" : "ar"}
                  />
                </div>
                <div
                  style={!openCal ? {} : { display: "none" }}
                  className="date-scroller"
                  id="date-scroller"
                >
                  {[...Array(2)].map((k, i) =>
                    moment(bookingSets?.dateNow)
                      ?.startOf("day")
                      .locale("en")
                      .subtract(2 - i, "days") >=
                      moment(bookingSets?.dateNow)?.startOf("day") ? (
                      <span
                        id={`date${moment(bookingSets?.dateNow)
                          .locale("en")
                          .subtract(2 - i, "days")
                          .format("DDMMYYYY")}`}
                        onClick={() => {
                          setBookingDetails((book) => ({
                            ...book,
                            time: [],
                            time_ar: [],
                            branch: "",
                            date: moment(bookingSets?.dateNow)
                              .locale("en")
                              .subtract(2 - i, "days"),
                          }));
                          onDateClick(
                            moment(bookingSets?.dateNow)
                              .locale("en")
                              .subtract(2 - i, "days")
                          );
                          setTabOpen((t) => 2);
                          onDateSelect(
                            `date${moment(bookingSets?.dateNow)
                              .locale("en")
                              .subtract(2 - i, "days")
                              .format("DDMMYYYY")}`
                          );
                        }}
                        className={`date-selector ${moment(bookingSets?.dateNow)
                          .locale("en")
                          .subtract(2 - i, "days")
                          ?.format("D MMM YYYY") ==
                          bookingDetails?.date?.format("D MMM YYYY")
                          ?
                          "booking-slot-active"
                          : ""
                          }`}
                      >
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <span>
                            {moment(bookingSets?.dateNow)
                              .locale(language == "ltr" ? "en" : "ar")
                              .subtract(2 - i, "days")
                              ?.format("ddd")}
                          </span>

                          {language == "ltr" ? (
                            <span>
                              {moment(bookingSets?.dateNow)
                                .locale("en")
                                .subtract(2 - i, "days")
                                ?.format("D MMM YYYY")}
                            </span>
                          ) : (
                            <span>
                              <span
                                style={{ fontSize: 13 }}
                                className="number-span"
                              >
                                {moment(bookingSets?.dateNow)
                                  .locale("en")
                                  .subtract(2 - i, "days")
                                  ?.format("D")}
                              </span>{" "}
                              {moment(bookingSets?.dateNow)
                                ?.locale("ar")
                                ?.subtract(2 - i, "days")
                                ?.format("MMM")}{" "}
                              <span
                                style={{ fontSize: 13 }}
                                className="number-span"
                              >
                                {moment(bookingSets?.dateNow)
                                  .locale("en")
                                  .subtract(2 - i, "days")
                                  ?.format("YYYY")}
                              </span>
                            </span>
                          )}
                        </span>
                      </span>
                    ) : null
                  )}
                  {[...Array(Number(bookingSets.max_date_selection))].map(
                    (k, i) => (
                      <span
                        id={`date${moment(bookingSets?.dateNow)
                          .locale("en")
                          .add(i, "days")
                          .format("DDMMYYYY")}`}
                        onClick={() => {
                          setBookingDetails((book) => ({
                            ...book,
                            time: [],
                            time_ar: [],
                            branch: "",
                            date: moment(bookingSets?.dateNow)
                              .locale("en")
                              .add(i, "days"),
                          }));
                          onDateClick(
                            moment(bookingSets?.dateNow)
                              .locale("en")
                              .add(i, "days")
                          );
                          setTabOpen((t) => 2);
                          onDateSelect(
                            `date${moment(bookingSets?.dateNow)
                              .locale("en")
                              .add(i, "days")
                              .format("DDMMYYYY")}`
                          );
                        }}
                        className={`date-selector ${moment(bookingSets?.dateNow)
                          .locale("en")
                          .add(i, "days")
                          ?.format("D MMM YYYY") ==
                          bookingDetails?.date?.format("D MMM YYYY")
                          ?
                          "booking-slot-active"
                          : ""
                          }`}
                      >
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <span>
                            {moment(bookingSets?.dateNow)
                              .locale(language == "ltr" ? "en" : "ar")
                              .add(i, "days")
                              ?.format("ddd")}
                          </span>

                          {language == "ltr" ? (
                            <span>
                              {moment(bookingSets?.dateNow)
                                .locale("en")
                                .add(i, "days")
                                ?.format("D MMM YYYY")}
                            </span>
                          ) : (
                            <span>
                              <span
                                style={{ fontSize: 13 }}
                                className="number-span"
                              >
                                {moment(bookingSets?.dateNow)
                                  .locale("en")
                                  .add(i, "days")
                                  ?.format("D")}
                              </span>{" "}
                              {moment(bookingSets?.dateNow)
                                ?.locale("ar")
                                ?.add(i, "days")
                                ?.format("MMM")}{" "}
                              <span
                                style={{ fontSize: 13 }}
                                className="number-span"
                              >
                                {moment(bookingSets?.dateNow)
                                  .locale("en")
                                  .add(i, "days")
                                  ?.format("YYYY")}
                              </span>
                            </span>
                          )}
                        </span>
                      </span>
                    )
                  )}
                </div>
              </div>
            </div>
            <div className="booking-single-div">
              <div className="booking-flex-div">
                <p className="booking-sub-title">
                  {language == "ltr"
                    ? details?.vendor?.place_label
                    : details?.vendor?.place_label_ar}
                </p>
                <p className="booking-info-text">
                  {bookingDetails?.branch == ""
                    ? language == "ltr"
                      ? "All"
                      : "إختر المكان"
                    : language == "ltr"
                      ? bookingDetails?.branch?.v_name
                      : bookingDetails?.branch?.v_name_ar}
                </p>
              </div>
              <div
                className="pt-3"
                style={!openCal && tabOpen >= 2 ? {} : { display: "none" }}
              >
                {bookingSets?.branchAvail ? (
                  <div
                    style={{
                      padding: "0px 10px",
                      color: details?.vendor?.vendor_color,
                      fontSize: 18,
                      textAlign: "center",
                    }}
                  >
                    {language == "ltr"
                      ? `No ${details?.vendor?.place_label} Available. Please Choose Another Date`
                      : `لا ${details?.vendor?.place_label_ar} متاحة. الرجاء اختيار تاريخ آخر`}
                  </div>
                ) : (
                  <>
                    <ul
                      style={tabOpen == 2 ? {} : { display: "none" }}
                      className="booking-branch-list"
                    >
                      {bookingSets?.places?.map((k) => (
                        <li
                          onClick={() => {
                            setBookingSets((sets) => ({
                              ...sets,
                              selectedPlace: {
                                name: k?.name,
                                name_ar: k?.name_ar,
                                vendor_name: "",
                                vendor_name_ar: "",
                                description:
                                  k?.available_place[bookingDetails?.place]
                                    ?.description,
                                description_ar:
                                  k?.available_place[bookingDetails?.place]
                                    ?.description_ar,
                                unit_price:
                                  k?.available_place[bookingDetails?.place]
                                    ?.amount,
                                price:
                                  k?.available_place[bookingDetails?.place]
                                    ?.amount,
                                google_map_link:
                                  k?.available_place[bookingDetails?.place]
                                    ?.google_map_url,
                                latitude:
                                  k?.available_place[bookingDetails?.place]
                                    ?.latitude,
                                longitude:
                                  k?.available_place[bookingDetails?.place]
                                    ?.longitude,
                                image: k?.english_new_background,
                                k,
                              },
                              placeClicked: true,
                            }));
                          }}
                          className="booking-branch"
                        >
                          <img
                            className="booking-branch-img"
                            src={k?.english_new_background}
                          ></img>
                          <div className="booking-branch-details">
                            <div className="booking-branch-title">
                              {language == "ltr" ? k?.name : k?.name_ar}
                            </div>
                            <div className="booking-branch-desc">
                              {language == "ltr"
                                ? k?.available_place?.[bookingDetails?.place]
                                  ?.description
                                : k?.available_place?.[bookingDetails?.place]
                                  ?.description_ar}
                            </div>
                            <div className={`booking-branch-button`}>
                              <button
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.nativeEvent.stopPropagation();
                                  onBranchClick(k);
                                }}
                              >
                                {language == "ltr" ? "Select" : "إختر"}
                              </button>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                    <div
                      style={
                        tabOpen > 2
                          ? { padding: "0px 0px 15px" }
                          : { display: "none" }
                      }
                      className="date-scroller"
                    >
                      {bookingSets?.places?.map((k) =>
                        (
                          k.booking_vendor_id == 5
                            ? moment("24-02-2022", "DD-MM-YYYY")
                              .startOf("date")
                              .locale("en") >=
                            moment(bookingDetails.date).startOf("date")
                            : true
                        ) ? (
                          <span
                            style={{
                              width: 165,
                              maxWidth: 165,
                              padding: "10px 0",
                              cursor: "pointer",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            onClick={() => {
                              onBranchClick(k);
                            }}
                            className={`date-selector ${k?.vendors_id ==
                              bookingDetails?.branch?.vendors_id
                              ? "booking-slot-active"
                              : ""
                              }`}
                          >
                            {language == "ltr" ? k?.name : k?.name_ar}
                          </span>
                        ) : null
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>

            <div
              className="booking-single-div"
              style={{ borderBottom: "none" }}
            >
              <div className="booking-flex-div">
                <p className="booking-sub-title">
                  {language == "ltr" ? "Time" : "وقت الحجز"}
                </p>
                <p className="booking-info-text">
                  {bookingDetails?.time?.length == 0
                    ? language == "ltr"
                      ? "Any"
                      : "إختر الوقت"
                    : bookingDetails?.time?.map((i, k) => (
                      <>
                        {k == 0 ? "" : ", "}
                        <span
                          style={{ fontSize: 18 }}
                          className="number-span"
                        >
                          {i.start_time}
                        </span>{" "}
                        {language == "ltr"
                          ? i?.start_time_meridiem
                          : i?.start_time_meridiem_ar}
                      </>
                    ))}
                </p>
              </div>
              <div
                className="pt-3"
                style={!openCal && tabOpen >= 3 ? {} : { display: "none" }}
              >
                {bookingSets?.slotAvail ? (
                  <div className="booking-time-div">
                    {bookingSets?.uniqueMorning?.length != 0 && (
                      <div className="booking-time-period">
                        <p
                          className="booking-time-text"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          {language == "ltr" ? "☀️ Morning" : "صباحاً ☀️"}
                        </p>
                        <div className="booking-time-slot-div">
                          {bookingSets?.uniqueMorning?.map((i, k) => (
                            <button
                              onClick={() => {
                                onTimeClick(i);
                              }}
                              className={`booking-time-slot ${bookingDetails?.time?.some(
                                (t) =>
                                  t?.slot_start_time == i?.slot_start_time
                              )
                                ? "booking-slot-active"
                                : ""
                                } 
                            ${bookingSets?.is_multi_slot == 1 &&
                                  bookingSets?.max_slot != 1
                                  ? bookingDetails?.time?.length ==
                                    bookingSets?.max_slot &&
                                    !bookingDetails?.time?.some(
                                      (t) =>
                                        t?.slot_start_time == i?.slot_start_time
                                    )
                                    ? "booking-time-booked"
                                    : ""
                                  : ""
                                }
                            `}
                            >
                              <span
                                style={{ fontSize: 12 }}
                                className="number-span"
                              >
                                {i.start_time}
                              </span>{" "}
                              {language == "ltr"
                                ? i?.start_time_meridiem
                                : i?.start_time_meridiem_ar}{" "}
                              -{" "}
                              <span
                                style={{ fontSize: 12 }}
                                className="number-span"
                              >
                                {i.end_time}
                              </span>{" "}
                              {language == "ltr"
                                ? i?.end_time_meridiem
                                : i?.end_time_meridiem_ar}
                            </button>
                          ))}
                        </div>
                      </div>
                    )}
                    {bookingSets?.uniqueEvening?.length != 0 && (
                      <div
                        className="booking-time-period"
                        style={{
                          paddingTop:
                            bookingSets?.uniqueMorning?.length == 0 ? 0 : 20,
                        }}
                      >
                        <p
                          className="booking-time-text"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          {language == "ltr" ? "🌙 Evening" : "مساءً 🌙"}
                        </p>
                        <div className="booking-time-slot-div">
                          {bookingSets?.uniqueEvening?.map((i, k) => (
                            <button
                              onClick={() => {
                                onTimeClick(i);
                              }}
                              className={`booking-time-slot ${bookingDetails?.time?.some(
                                (t) =>
                                  t?.slot_start_time == i?.slot_start_time
                              )
                                ? "booking-slot-active"
                                : ""
                                } ${bookingSets?.is_multi_slot == 1 &&
                                  bookingSets?.max_slot != 1
                                  ? bookingDetails?.time?.length ==
                                    bookingSets?.max_slot &&
                                    !bookingDetails?.time?.some(
                                      (t) =>
                                        t?.slot_start_time == i?.slot_start_time
                                    )
                                    ? "booking-time-booked"
                                    : ""
                                  : ""
                                }`}
                            >
                              <span
                                style={{ fontSize: 12 }}
                                className="number-span"
                              >
                                {i.start_time}
                              </span>{" "}
                              {language == "ltr"
                                ? i?.start_time_meridiem
                                : i?.start_time_meridiem_ar}{" "}
                              -{" "}
                              <span
                                style={{ fontSize: 12 }}
                                className="number-span"
                              >
                                {i.end_time}
                              </span>{" "}
                              {language == "ltr"
                                ? i?.end_time_meridiem
                                : i?.end_time_meridiem_ar}
                            </button>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div
                    style={{
                      padding: "0px 10px",
                      color: details?.vendor?.vendor_color,
                      fontSize: 18,
                      textAlign: "center",
                    }}
                  >
                    {language == "ltr"
                      ? `No Slots Available. Please Choose Another Date or ${details?.vendor?.place_label}`
                      : "لا يوجد وقت متوفر للحجز الآن يرجى البحث بخيارات مختلفة"}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {checkNext() && (
        <div
          className="bottom-button iphone-issue"
          style={
            {}
          }
        >
          <Link
            onClick={(e) => {
              e.preventDefault();
              if (bookingDetails?.place >= 0) {
                setBookingDetails((book) => ({
                  ...book,
                  branch: {
                    ...bookingDetails?.branch,
                    v_name: bookingDetails?.branch.v_name,
                    v_name_ar: bookingDetails?.branch.v_name_ar,
                    name: bookingDetails?.branch?.available_place[
                      bookingDetails?.place
                    ]?.name,
                    name_ar:
                      bookingDetails?.branch?.available_place[
                        bookingDetails?.place
                      ]?.name_ar,
                    payment_type:
                      bookingDetails?.branch?.available_place[
                        bookingDetails?.place
                      ]?.payment_type,
                    total_amount:
                      bookingDetails?.time?.length *
                      parseFloat(
                        bookingDetails?.branch?.available_place?.[
                          bookingDetails?.place
                        ]?.amount
                      ),
                    amount:
                      bookingDetails?.branch?.available_place[
                        bookingDetails?.place
                      ]?.amount,
                  },
                }));
                add({
                  vendorId: details.vendor.booking_vendor_id,
                  userId: localStorage.getItem("userID"),
                  dates: [bookingDetails?.date?.format("YYYY-MM-DD")],
                  timeSlots: bookingDetails?.time?.map((t) => ({
                    slot_start_time: t?.slot_start_time,
                    slot_end_time: t?.slot_end_time,
                  })),
                  placeId:
                    bookingDetails?.branch?.available_place?.[
                      bookingDetails?.place
                    ]?.booking_place_id,
                  mainVendor:
                    bookingDetails?.branch?.available_place?.[
                      bookingDetails?.place
                    ]?.booking_vendor_id,
                });
                setStepper((stepper) => stepper + 1);
              }
            }}
            className="text-center checkout-button "
          >
            {bookingDetails?.place >= 0 ? (
              language == "ltr" ? (
                `Book  ${(
                  bookingDetails?.time?.length *
                  parseFloat(
                    bookingDetails?.branch?.available_place?.[
                      bookingDetails?.place
                    ]?.amount
                  )
                ).toFixed(3)} KD`
              ) : (
                <>
                  متابعة الحجز
                  <pre
                    style={{
                      margin: 0,
                      overflow: "hidden",
                      display: "inline",
                      fontSize: 15,
                    }}
                    className="number-span"
                  >
                    {" "}
                    {(
                      bookingDetails?.time?.length *
                      parseFloat(
                        bookingDetails?.branch?.available_place?.[
                          bookingDetails?.place
                        ]?.amount
                      )
                    ).toFixed(3)}{" "}
                  </pre>
                  د.ك
                </>
              )
            ) : language == "ltr" ? (
              `Selected Time Slots are Unavailable`
            ) : (
              "الفترات الزمنية المحددة غير متوفرة"
            )}
          </Link>
        </div>
      )}
    </>
  );
}

export default BookingVendor;
