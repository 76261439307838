import React from "react";
import SingleCategoryProduct from "./SingleCategoryProduct";

function SingleProductList({ products, loading, lastBookElementRef }) {
  return (
    <div className="product-category-view">
      <ul className="product-category-list single-product-list">
        {loading == false
          ? products.map((item, i) => {
              if (i == products.length - 6)
                return (
                  <li
                    ref={lastBookElementRef}
                    className="product-category-pad single-product-pad"
                  >
                    <SingleCategoryProduct item={item}></SingleCategoryProduct>
                  </li>
                );
              else
                return (
                  <li className="product-category-pad single-product-pad">
                    <SingleCategoryProduct item={item}></SingleCategoryProduct>
                  </li>
                );
            })
          : [...Array(7)].map((k, i) => (
              <li className="product-category-pad single-product-pad">
                <div className="product-category-item">
                  <a>
                    <div
                      className="product-img-div shine-design"
                      style={{ backgroundColor: "#f3f3f3" }}
                    ></div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#f3f3f3",
                          height: "29px",
                          marginTop: "1rem",
                          borderRadius: "5px",
                          width: "400px",
                        }}
                      ></div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#f3f3f3",
                          height: "25px",
                          marginTop: "1rem",
                          width: "125px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "5px",
                        }}
                      ></div>
                      <div
                        style={{
                          backgroundColor: "#f3f3f3",
                          marginTop: "1rem",
                          width: "25px",
                          height: "25px",
                          borderRadius: "50px",
                        }}
                      ></div>
                    </div>
                  </a>
                </div>
              </li>
            ))}
      </ul>
    </div>
  );
}

export default SingleProductList;
