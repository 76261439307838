import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import {
  AreaContext,
  LanguageContext,
  SearchContext,
  VendorContext,
  VendorSlugContext,
} from "../../../App";
import { API_URL } from "../../../services/constants";
import ReactPixel from 'react-facebook-pixel'
import sha256 from "sha256";

let cancelToken;
function SearchContainer({
  setSearchItems,
  setSearchLoading,
  page,
  setIsPageLoading,
  setHasMore,
  hasMore,
  setPage,
}) {
  const history = useHistory();
  const { language } = useContext(LanguageContext);
  const details = useContext(VendorContext);
  const { search, setSearch } = useContext(SearchContext);
  const vendorSlug = useContext(VendorSlugContext);
  const { areaDetails } = useContext(AreaContext);
  const changeSearchText = (e) => {
    setSearch(() => e.target.value);
  };

  useEffect(() => {
    if (hasMore && page != 0) setIsPageLoading((l) => true);
    else {
      setSearchLoading((loading) => true);
      setSearchItems(() => []);
    }
    if (cancelToken != undefined) {
      cancelToken.cancel("Operation canceled due to new request.");
    }
    cancelToken = axios.CancelToken.source();
    axios
      .post(
        `${API_URL}/search`,
        JSON.stringify({
          token: process.env.REACT_APP_TOKEN,
          search_string: search,
          vendor_slug: vendorSlug,
          vendor_id: details?.vendor?.vendors_id,
          area_id: areaDetails?.area_id,
          page: page,
        }),
        {
          cancelToken: cancelToken.token,
        }
      )
      .then((res) => {
        setSearchLoading(() => false);
        setIsPageLoading((l) => false);
        setSearchItems((s) => [...s, ...res.data.data]);
        setHasMore((i) => res.data.is_more_records);
        if (details?.vendor?.fb_pixel_code != "") {
          ReactPixel.trackCustom("SearchProduct")

          const time = Date.now();
          const sec = Math.round(time / 1000);
          if (vendorSlug == "mijana-restaurant-and-café") {
            axios
              .post(
                `https://graph.facebook.com/v15.0/${details?.vendor?.fb_pixel_code}/events?access_token=EAAGZA8GMZAs1IBAC9mDImnZCTAdafRzN769x6ZCIRMExueSZBZBfnDkIzGrsP4gZBMZCCwXaSvKNggZBEKdEk3582JWiwecrnZAEHFzfCaYKSNRbltxMm2cSvUrgZBUDpVNZCQAOVWUuzO4m7nbvQn1Wqb94IBbVSexSbwWzAf6TYV80HQF1ZAZAzGcXKB`,
                {
                  data: [
                    {
                      action_source: "website",
                      event_name: "SearchProduct",
                      event_time: sec,
                      user_data: {
                        client_user_agent:
                          "Mozilla/5.0 (iPhone; CPU iPhone OS 13_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/13.0.5 Mobile/15E148 Safari/604.1",
                        em: [sha256(details?.vendor?.support_mail)],
                      },
                      custom_data: {
                        search_item: ""
                      }
                    },
                  ],
                }
              )
              .then((res) => { })
              .catch((e) => console.log(e));
          }
        };
      })
      .catch((e) => console.log(e));
  }, [search, page]);

  return (
    <div className="search-container">
      <div className="searchbar-container">
        <input
          type="search"
          onChange={(e) => {
            setPage(() => 0);
            changeSearchText(e);
          }}
          autoFocus
          value={search}
          placeholder={language === "ltr" ? "Search" : "بحث"}
          className="searchbar"
        ></input>
        <Link
          className="search-cancel"
          onClick={(e) => {
            e.preventDefault();
            history.goBack();
          }}
        >
          {language === "ltr" ? "Cancel" : "إلغاء"}
        </Link>
      </div>
    </div>
  );
}

export default SearchContainer;
