import React from 'react'

const SearchIcon = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
            <path d="M11.9487 19C16.367 19 19.9487 15.4183 19.9487 11C19.9487 6.58172 16.367 3 11.9487 3C7.53045 3 3.94873 6.58172 3.94873 11C3.94873 15.4183 7.53045 19 11.9487 19Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M21.9486 21.0004L17.5986 16.6504" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default SearchIcon