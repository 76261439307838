import React, { useContext, useEffect, useState } from "react";
import './international.css'
import { AreaContext, InterNationalContext, LanguageContext, VendorContext, VendorSlugContext } from "../../App";
import ReactFlagsSelect from "react-flags-select";
import { getVendorCountries } from "../APIS/submitOrderApi";

const HomepageDesign = ({ handleCountryChange }) => {
    const { language } = useContext(LanguageContext);
    const details = useContext(VendorContext)
    const { internationalDelivery, setInternationalDelivery } = useContext(InterNationalContext);
    const { areaDetails, setAreaDetails } = useContext(AreaContext)
    const vendorSlug = useContext(VendorSlugContext)
    const [countryDropDown, setCountryDropDown] = useState()
    const [countryArray, setCountryArray] = useState()

    useEffect(() => {
        if (internationalDelivery.delivery_country_code && details?.vendor?.international_delivery !== "3" && details?.vendor?.international_delivery !== "") {
            (async () => {
                const response = await getVendorCountries({ vendor_id: details.vendor.vendors_id, ecom_vendor_id: details.vendor.ecommerce_vendor_id, vendor_slug: vendorSlug })
                if (response.status) {
                    let countryArray = [];
                    response.data.map((ele) => {
                        countryArray.push(ele.abbr)
                    })
                    const currentCountry = response.data.filter((ele) => ele.abbr === internationalDelivery.delivery_country_code)
                    if (currentCountry && currentCountry.length > 0) {

                        setInternationalDelivery({
                            ...internationalDelivery,
                            delivery_country_code: currentCountry[0].abbr,
                            country_id: currentCountry[0].country_id,
                            delivery_expected_time: currentCountry[0]?.delivery_expected_time,
                            delivery_expected_type: currentCountry[0]?.delivery_expected_type
                        })
                    }
                    setCountryArray(countryArray)
                    setCountryDropDown(response.data)
                }
            })();
        }
    }, [])

    return <div className="international-delivery-home-container">
        <div>
            {language === "ltr" ? "Delivery" : "توصيل"}
        </div>
        <div className="international-map-div">
            <div>
                <ReactFlagsSelect
                    selected={internationalDelivery.delivery_country_code}
                    showSelectedLabel={true}
                    searchable
                    countries={countryArray}
                    onSelect={(code) => {
                        const filterCountry = countryDropDown.filter((country) => country.abbr === code)
                        setInternationalDelivery({
                            ...internationalDelivery,
                            delivery_country_code: code,
                            country_id: filterCountry[0].country_id,
                            delivery_country: filterCountry[0].country_name,
                            delivery_expected_time: filterCountry[0]?.delivery_expected_time,
                            delivery_expected_type: filterCountry[0]?.delivery_expected_type
                        })
                        if (code !== "KW") {
                            setAreaDetails({
                                ...areaDetails,
                                area: "",
                                branch: "",
                                branch_id: "",
                                area_id: "",
                                branchForArea: {},
                                deliveryTiming: "",
                                pickupTiming: "",
                                ar_area: "",
                                ar_branch: "",
                                ar_deliveryTiming: "",
                                ar_pickupTiming: "",
                            })

                        }
                        handleCountryChange(code)
                    }}
                />
            </div>
        </div>
    </div>;
};

export default HomepageDesign;
