import React, { useContext, useState } from "react";
import { LanguageContext, VendorSlugContext } from "../../../App";
import PhoneIcons from "../../../SVGs/socialMediaIcons/PhoneIcons";
import InstagramIcon from "../../../SVGs/socialMediaIcons/InstagramIcon";
import FacebookIcon from "../../../SVGs/socialMediaIcons/FacebookIcon";
import GoogleIcon from "../../../SVGs/GoogleIcon";
import TwitterIcons from "../../../SVGs/socialMediaIcons/TwitterIcons";
import YouTubeIcons from "../../../SVGs/socialMediaIcons/YouTubeIcons";
import { getTNC } from "../../APIS/getTNC";
import { API_URL } from "../../../services/constants";
import TermsModal from "../../TermsModal/TermsModal";

const SideMenuBottom = ({ vendorData, handleTermsClick }) => {
  const { language } = useContext(LanguageContext);

  return (
    <div className="sideMenu-bottom-mainDiv">
      <div className="sidemenu-social-icon">
        <div className="reach-Us-Via-text">
          {language === "ltr" ? "Contact Us Via" : "تواصل معنا من خلال"}
        </div>
        <div className="sideMenu-BottomIcons">
          {vendorData?.social_media?.whatsapp != "" &&
          vendorData?.social_media?.whatsapp ? (
            <a
              rel="noreferrer"
              href={`https://api.whatsapp.com/send?phone=${vendorData?.social_media?.whatsapp}`}
              target="_blank"
              className="social-link"
            >
              <PhoneIcons />
            </a>
          ) : null}
          {vendorData?.social_media?.instagram != "" &&
          vendorData?.social_media?.instagram ? (
            <a
              rel="noreferrer"
              href={vendorData?.social_media?.instagram}
              target="_blank"
              className="social-link"
            >
              <InstagramIcon />
            </a>
          ) : null}
          {vendorData?.social_media?.facebook != "" &&
          vendorData?.social_media?.facebook ? (
            <a
              rel="noreferrer"
              href={vendorData?.social_media?.facebook}
              target="_blank"
              className="social-link"
            >
              <FacebookIcon />
            </a>
          ) : null}
          {vendorData?.social_media?.google != "" &&
          vendorData?.social_media?.google ? (
            <a
              rel="noreferrer"
              href={vendorData?.social_media?.google}
              target="_blank"
              className="social-link"
            >
              <GoogleIcon />
            </a>
          ) : null}
          {vendorData?.social_media?.twitter != "" &&
          vendorData?.social_media?.twitter ? (
            <a
              rel="noreferrer"
              href={vendorData?.social_media?.twitter}
              target="_blank"
              className="social-link"
            >
              <TwitterIcons />
            </a>
          ) : null}
          {vendorData?.social_media?.youtube != "" &&
          vendorData?.social_media?.youtube ? (
            <a
              rel="noreferrer"
              href={vendorData?.social_media?.youtube}
              target="_blank"
              className="social-link"
            >
              <YouTubeIcons />
            </a>
          ) : null}
        </div>
      </div>
      <div className="privacy-policy-text">
        <div>{language === "ltr" ? "Privacy Policy" : "سياسة الخصوصية"}</div>
        <div style={{ cursor: "pointer" }} onClick={handleTermsClick}>
          {language === "ltr" ? "Terms & Conditions" : "الشروط و الأحكام"}
        </div>
      </div>
      <div className="poweredBy-text">
        {language === "ltr" ? "Powered By" : "مشغل بواسطة"}
      </div>
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <img src="pictures/Artboard.png" className="sideMenu-bottomImage" />
      </div>
    </div>
  );
};

export default SideMenuBottom;
