import { io } from "socket.io-client";
const socket = io("https://estore.payzah.support", {
  path: "/cronjob/",
});

// const socket = io("https://estore.payzah.support:5001");

function subscribe({ cb, vendorId, childIds, userId }) {
  console.log("inside socket subscribe");
  socket.on("data", (timestamp) => {
    console.log("checking");

    console.log("checking", socket.connected);
    cb(
      null,
      timestamp.filter(
        (booking) => booking.userId !== localStorage.getItem("userID")
      )
    );
  });
  socket.emit("join", { vendorId, childIds, userId });
}

function add({ vendorId, userId, dates, timeSlots, placeId, mainVendor }) {
  console.log("inside socket add");
  console.log("checking", socket.connected);

  socket.emit("addBooking", {
    vendorId,
    userId,
    dates,
    timeSlots,
    placeId,
    mainVendor,
  });
}

function deleteB({ vendorId, userId, mainVendor }) {
  console.log("inside socket deleteB");
  socket.emit("deleteBooking", { vendorId, userId, mainVendor });
}

export { subscribe, add, deleteB };
