import React, { useContext, useEffect, useState } from 'react'
import { ContactContext } from '../../../App'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { tele, telecount } from '../../../services/constants'
import ReactFlagsSelect from 'react-flags-select'
const BuyerDetails = () => {
    const { contactDetails } = useContext(ContactContext)
    const [countryCode, setCountryCode] = useState("")
    useEffect(() => {
        setCountryCode(
            Object.keys(telecount).map((ele) => {
                if (ele == contactDetails.phoneCode) {
                    return telecount[ele].split(" ")[telecount[ele].split(" ").length - 1]
                }
            })
        )
    }, [])

    return (
        <Link to='/contact-details' className='buyer-details-mainDIv'>
            <div className='buyer-details-firstDiv'>
                <div className='buyer-details-name'>
                    {contactDetails?.name}
                </div>

                <div className='buyer-phone-number'>
                    <div className='dontchane-direction'>
                        <ReactFlagsSelect
                            className='showFlag-only'
                            selected={contactDetails?.phoneCode}
                            showSelectedLabel={false}
                            disabled
                            customLabels={telecount}
                        />
                        +{countryCode} {contactDetails?.phone}
                    </div>
                </div>
            </div>
            <div className='buyer-details-secondDiv'>
                <i className="fa fa-angle-right right-arrow"></i>
            </div>
        </Link>
    )
}

export default BuyerDetails