import axios from "axios";
import moment from "moment";
import React, { useContext } from "react";
import { useHistory } from "react-router";
import {
  AreaContext,
  CartContext,
  LanguageContext,
  VendorContext,
  VendorSlugContext,
} from "../App";
import { API_URL } from "../services/constants";

function ModalPickup({ popupDetails, setPopupDetails }) {
  const details = useContext(VendorContext);
  const history = useHistory();
  const { language } = useContext(LanguageContext);
  const { areaDetails, setAreaDetails } = useContext(AreaContext);
  const vendorSlug = useContext(VendorSlugContext);
  const { setCart } = useContext(CartContext);

  const onYesClick = () => {
    axios
      .post(
        `${API_URL}/reset-cart-data`,
        JSON.stringify({
          token: process.env.REACT_APP_TOKEN,
          vendor_id: details?.vendor?.vendors_id,
          area_id: popupDetails?.area,
          vendor_slug: vendorSlug,
          user_string: localStorage.getItem("userID"),
        })
      )
      .then((res) => {
        if (res.data.data?.cart?.cartCount == 0) {
          setCart((g) => { });
        } else setCart((g) => res.data.data.cart);
      })
      .catch((e) => console.log(e))
      .finally(() => {
        let temp = areaDetails?.data?.branch?.filter(
          (k, i) => k?.id == popupDetails?.key
        );
        temp = temp[0];
        let start, end;
        if (temp?.office_end_time == "00:00:00") {
          start = new Date(
            new Date().setHours(...temp?.office_start_time?.split(":"))
          );
          end = new Date(new Date().setHours(23, 59, 59));
        } else {
          start = new Date(
            new Date().setHours(...temp?.office_start_time?.split(":"))
          );
          end = new Date(
            new Date().setHours(...temp?.office_end_time?.split(":"))
          );
        }
        let current = new Date();
        current.setHours(current.getHours() + 2);
        if (!(start > current || current > end)) {
          setAreaDetails({
            ...areaDetails,
            branch: popupDetails?.eng,
            minimum: "",
            area_id: popupDetails?.area,
            shopOpen: 1,
            now: 1,
            area: "",
            ar_area: "",
            ar_branch: popupDetails?.arab,
            branch_id: popupDetails?.key,
            deliveryTiming: "2 Hours",
            ar_deliveryTiming: `2 ساعات`,
            getDeliveryTiming: moment().add(120, "minutes").toDate(),
            laterDeliveryTiming: moment().add(120, "minutes").toDate(),
            branchForArea: {
              id: popupDetails?.key,
              english: popupDetails?.eng,
              arabic: popupDetails?.arab,
              end:
                temp?.office_end_time == "00:00:00"
                  ? moment("23:59:59", "HH:mm:ss")
                  : moment(temp?.office_end_time, "HH:mm:ss"),
              start: moment(temp?.office_start_time, "HH:mm:ss"),
            },
          });
        } else {
          setAreaDetails({
            ...areaDetails,
            branch: popupDetails?.eng,
            area_id: popupDetails?.area,
            minimum: "",
            shopOpen: 2,
            now: 2,
            ar_branch: popupDetails?.arab,
            branch_id: popupDetails?.key,
            area: "",
            ar_area: "",
            deliveryTiming: "24 Hours",
            ar_deliveryTiming: "24 ساعات",
            getDeliveryTiming:
              start > current
                ? new Date(start.setHours(start.getHours() + 2))
                : new Date(start.setDate(start.getDate() + 1)),
            laterDeliveryTiming:
              start > current
                ? new Date(start.setHours(start.getHours() + 2))
                : new Date(start.setDate(start.getDate() + 1)),
            branchForArea: {
              id: popupDetails?.key,
              english: popupDetails?.eng,
              arabic: popupDetails?.arab,
              end:
                temp?.office_end_time == "00:00:00"
                  ? moment("23:59:59", "HH:mm:ss")
                  : moment(temp?.office_end_time, "HH:mm:ss"),
              start: moment(temp?.office_start_time, "HH:mm:ss"),
            },
          });
        }
        setPopupDetails((r) => ({ show_popup: 0 }));
        history.goBack();
      });
  };

  const onNoClick = () => {
    setPopupDetails((r) => ({ show_popup: 0 }));
  };

  return (
    <div id="myModal" className="modal-made">
      <div className="modal-content-made">
        <p className="header-modal">
          {language == "ltr"
            ? "Confirm change in area"
            : "تأكيد التغيير في المنطقة"}{" "}
        </p>
        <p className="text-modal">
          {" "}
          {language == "ltr"
            ? "We may need to remove/update the items added in your cart due to change in area. Please choose if you want to continue?"
            : "قد نحتاج إلى إزالة / تحديث العناصر المضافة في سلة التسوق الخاصة بك بسبب التغيير في المنطقة. الرجاء اختيار ما إذا كنت تريد الاستمرار؟"}{" "}
        </p>
        <div className="button-container">
          <button onClick={() => onNoClick()} className="red">
            {language == "ltr" ? "No" : "لا"}
          </button>
          <button onClick={() => onYesClick()} className="green">
            {language == "ltr" ? "Yes" : "نعم"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ModalPickup;
