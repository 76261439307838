import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  AreaContext,
  LanguageContext,
  UserContext,
  VendorContext,
} from "../../../App";

function DesktopNavigationBar({ setSearchText }) {
  const { language, setLanguage } = useContext(LanguageContext);
  const history = useHistory();
  const details = useContext(VendorContext);
  const { areaDetails } = useContext(AreaContext);
  const { userDetails, setUserDetails } = useContext(UserContext);

  const onLanguageChange = () => {
    document
      .getElementsByTagName("html")[0]
      .setAttribute("dir", language.split("").reverse().join(""));
    sessionStorage.setItem("language", language.split("").reverse().join(""));
    setLanguage(language.split("").reverse().join(""));
  };
  return (
    <div>
      <div className="desktop-navbar">
        <nav className="navbar navbar-expand-lg desktop-navbar-links">
          <div className="collapse navbar-collapse">
            <ul className="navbar-nav  mt-2 mt-lg-0">
              {window.location.host.replace(/^www\./, "") ===
                "playon.today" && (
                <li
                  className={`nav-item ${
                    window.location.pathname == "/search" ? "active" : ""
                  }`}
                >
                  <a
                    className="nav-link"
                    target={"_blank"}
                    href={`https://shop.playon.today`}
                  >
                    {language === "ltr" ? "Store" : "متجر"}
                  </a>
                </li>
              )}
              <li
                className={`nav-item ${
                  window.location.pathname == "/" ? "active" : ""
                }`}
              >
                <Link
                  className="nav-link"
                  onClick={(e) => {
                    e.preventDefault();
                    history.push("/");
                  }}
                >
                  {language === "ltr"
                    ? details?.vendor?.slug_type == 2
                      ? "Reserve"
                      : details?.vendor?.business_type == 6
                      ? "Menu"
                      : "Shop"
                    : details?.vendor?.slug_type == 2
                    ? "الحجوزات"
                    : details?.vendor?.business_type == 6
                    ? "القائمة"
                    : "المتجر"}
                </Link>
              </li>
              {window.location.host.replace(/^www\./, "") ===
                "shop.playon.today" && (
                <li
                  className={`nav-item ${
                    window.location.pathname == "/book" ? "active" : ""
                  }`}
                >
                  <a
                    className="nav-link"
                    target={"_blank"}
                    href={`https://playon.today`}
                  >
                    {language === "ltr" ? "Book" : "الحجوزات"}
                  </a>
                </li>
              )}
              {details?.vendor?.slug_type != 2 && (
                <li
                  className={`nav-item ${
                    window.location.pathname == "/search" ? "active" : ""
                  }`}
                >
                  <Link className="nav-link" to={`/search`}>
                    {language === "ltr" ? "Search" : "بحث"}
                  </Link>
                </li>
              )}
              <li
                className={`nav-item ${
                  window.location.pathname == "/branches" ? "active" : ""
                }`}
              >
                <Link className="nav-link" to={`/branches`}>
                  {areaDetails?.data?.branch?.length <= 1
                    ? language === "ltr"
                      ? "Branch"
                      : "أفرعنا"
                    : language === "ltr"
                    ? "Branches"
                    : "افرعنا"}
                </Link>
              </li>
              <li
                className={`nav-item ${
                  window.location.pathname == "/login" ? "active" : ""
                }`}
              >
                {userDetails?.is_guest == true ? (
                  <Link className="nav-link" to={`/login`}>
                    {language === "ltr" ? "Login" : "تسجيل الدخول"}
                  </Link>
                ) : (
                  <Link className="nav-link" to={`/user=${userDetails?.id}`}>
                    {language === "ltr" ? "Account" : "حساب"}
                    {/* {language === "ltr" ? userDetails?.firstname : userDetails?.firstname_ar} */}
                  </Link>
                )}
              </li>
            </ul>
          </div>
        </nav>
        {window.location.host != "alrubaiaanlibrary.payzah.store" && (
          <Link
            className="language-selection-option"
            onClick={(e) => {
              e.preventDefault();
              onLanguageChange();
            }}
          >
            {language === "rtl" ? "English" : "عربي"}
          </Link>
        )}
      </div>
    </div>
  );
}

export default DesktopNavigationBar;
