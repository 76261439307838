import React from 'react'
import ReviewBar from './ReviewBar'

const Drawer = () => {
    return (
        <div className='drawer-main'>
            <ReviewBar></ReviewBar>
        </div>
    )
}

export default Drawer