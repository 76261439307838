import React, { useContext } from "react";
import { LanguageContext, VendorContext } from "../../App";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { LazyLoadImage } from "react-lazy-load-image-component";
import BrokenImage from "../Common/BrokenImage";

const SubCategoriesSideScroll = ({ item }) => {
  const details = useContext(VendorContext);
  const { language } = useContext(LanguageContext);

  return (
    <div className="product-category-item">
      <Link to={`/products=${item?.category_slug}`}>
        <div className="product-img-div">
          <div
            style={{
              maxWidth: "90%",
              position: "relative",
              display: "inline-block",
            }}
          >
            {/* <LazyLoadImage src={item?.category_image}></LazyLoadImage> */}
            <BrokenImage
              src={item?.category_image}
              fallbackSrc={
                language === "ltr"
                  ? details?.vendor?.english_new_background
                  : details?.vendor?.arabic_new_background
              }
            />
          </div>
        </div>
        <div className="product-title">
          <p
            style={{
              fontSize: language == "ltr" ? 15 : 18,
              fontWeight: 600,
              textAlign: "center",
              width: "90%",
            }}
            className="product-name"
          >
            {language === "ltr" ? item?.category_name : item?.category_name_ar}
          </p>
        </div>
      </Link>
    </div>
  );
};

export default SubCategoriesSideScroll;
