import React, { useContext } from 'react'
import { LanguageContext, VendorContext } from '../../../App'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const RestSideDrawerContent = ({ setBurger }) => {
    const { language } = useContext(LanguageContext)
    const details = useContext(VendorContext)
    const history = useHistory()
    const sectionsArray = [
        {
            englishType:
                details?.vendor?.slug_type == 2 ?
                    "Reserve" :
                    details?.vendor?.business_type == 6
                        ? "Menu" : "Home",
            arabicType: details?.vendor?.slug_type == 2
                ? "الحجوزات"
                : details?.vendor?.business_type == 6
                    ? "الصفحة الرئيسية"
                    : "الصفحة الرئيسية",
            link: "/"
        },
        {
            englishType: "Branch",
            arabicType: "افرعنا",
            link: "/branches"
        },
        // {
        //     englishType: "FAQs",
        //     arabicType: "الأسئلة الشائعة",
        //     link: ""
        // },
        {
            englishType: "Track Order",
            arabicType: "تعقب الطلب",
            link: "/track-order"
        }

    ]
    const navigateFunction = (section) => {
        switch (section.englishType) {
            case 'FAQs':

                break;
            case 'Track Order':
                setBurger(false)
                history.push('/track-order')
                break;
            case 'Home':
            case 'Reserve':
            case 'Menu':
                setBurger(false)
                history.push('/')
                break;
            case 'Branch':
                setBurger(false)
                history.push('/branches')
                break;
            default:
                break;
        }

    }
    return (
        <div style={{ marginBottom: "360px" }}>
            {
                sectionsArray && sectionsArray.map((section, i) =>
                    <div key={i} className='restSide-div' onClick={() => navigateFunction(section)}>
                        {language === "ltr" ? section.englishType : section.arabicType}
                    </div>
                )
            }
        </div>
    )
}

export default RestSideDrawerContent