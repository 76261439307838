import React, { useContext } from "react";
import { AreaContext, LanguageContext, VendorContext } from "../../../App";
import MobileVendorLogoNew from "./MobileVendorLogoNew";

function MobileVendorLogo() {
  const details = useContext(VendorContext);
  const { areaDetails } = useContext(AreaContext);
  const { language } = useContext(LanguageContext);
  return (
    <>
      {
        details?.vendor?.home_page_type == "17" || details?.vendor?.home_page_type == "18" ?
          <MobileVendorLogoNew></MobileVendorLogoNew>
          :
          <div className="vendor-mobile-logo" style={{ paddingTop: 20 }}>
            <img
              className="fluid-image vendor-logo"
              src={
                language == "ltr"
                  ? details?.vendor?.english_new_background
                  : details?.vendor?.arabic_new_background
              }
            ></img>
            <span className="vendor-name">
              <div className="row">
                <div className="col-lg-12" style={{ padding: "auto 0px" }}>
                  <div className="logo-content">
                    <b>
                      {language == "ltr"
                        ? details?.vendor?.name
                        : details?.vendor?.name_ar}
                    </b>
                  </div>
                  <div className="para-text">
                    {language == "ltr"
                      ? details?.vendor?.slogan
                      : details?.vendor?.slogan_ar}
                  </div>
                </div>
              </div>
              {((details?.vendor?.minimum_charge != "" ||
                (areaDetails?.minimum != "" && areaDetails?.minimum)) &&
                details?.vendor?.slug_type != 2) ? (
                <div className="mini" style={{ padding: "0 15px" }}>
                  {language == "ltr"
                    ? `Minimum Order is ${details?.vendor?.minimum_charge != ""
                      ? details?.vendor?.minimum_charge ? parseFloat(details?.vendor?.minimum_charge).toFixed(3) : 0
                      : areaDetails?.minimum ? parseFloat(areaDetails?.minimum).toFixed(3) : 0
                    } KD`
                    : `الحد الأدنى للطلب هو ${details?.vendor?.minimum_charge != ""
                      ? details?.vendor?.minimum_charge ? parseFloat(details?.vendor?.minimum_charge).toFixed(3) : 0
                      : areaDetails?.minimum ? parseFloat(areaDetails?.minimum).toFixed(3) : 0
                    } د.ك`}
                </div>
              ) : null}
            </span>
          </div>
      }
    </>
  );
}

export default MobileVendorLogo;
