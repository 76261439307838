import axios from "axios";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import ReactPixel from "react-facebook-pixel";
import { useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import {
  AreaContext,
  CartContext,
  VendorContext,
  VendorSlugContext,
  LanguageContext,
} from "../App";
import { API_URL } from "../services/constants";
import CategoryProductList from "./CategoryProductList";
import ReviewBar from "./HomePage/components/ReviewBar";
import SnapPixel from "react-snapchat-pixel";
import sha256 from "sha256";
import CommonBack from "./NewCheckOutPage/Components/CommonBack";

function Categoryview({
  products,
  lastBookElementRef,
  loading,
  isPageLoading,
  handleBackClick
}) {
  const { language } = useContext(LanguageContext);
  // const [products, setProducts] = useState([]);
  const { category } = useParams();
  const { cart } = useContext(CartContext);
  // const [loading, setLoading] = useState(false);
  const details = useContext(VendorContext);
  const { areaDetails } = useContext(AreaContext);
  const vendorSlug = useContext(VendorSlugContext);
  const [page, setPage] = useState(0);

  useEffect(() => {
    if (
      details?.vendor?.snap_pixel_code &&
      details?.vendor?.snap_pixel_code != ""
    )
      SnapPixel.pageView();

    if (details?.vendor?.fb_pixel_code && details?.vendor?.fb_pixel_code != "")
      ReactPixel.pageView();
  }, []);

  return (
    <>
      <div className="delivery-order-header">
        <CommonBack
          english_title={products?.[0]?.category_name}
          arabic_title={products?.[0]?.category_name_ar}
          variant="light"
          clickButton={handleBackClick}
        />
      </div>
      <div style={{ marginBottom: cart?.cartCount ? 45 : 0 }}>
        {products &&
          !loading &&
          (products.length ? (
            <CategoryProductList
              products={products}
              lastBookElementRef={lastBookElementRef}
              loading={loading}
            ></CategoryProductList>
          ) : null)}

        {(!products.length && !loading && !isPageLoading) ?
          <div style={{ padding: "0 30px", marginTop: "10px" }}>
            {language === "ltr"
              ? "Products are unavailable"
              : "المنتجات غير متوفرة"}
          </div> : null
        }

        {isPageLoading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <ClipLoader
              size={40}
              color={details?.vendor?.vendor_color}
            ></ClipLoader>
          </div>
        )}
      </div>
      {cart?.cartCount && <ReviewBar></ReviewBar>}
    </>
  );
}

export default Categoryview;
