import React, { useContext, useEffect, useState } from 'react'
import CommonSectionHeader from './CommonSectionHeader'
import { LanguageContext, VendorContext } from '../../../App'
import ReactFlagsSelect from 'react-flags-select'
import { telecount } from '../../../services/constants'

const NewContactDetails = ({
    setContactDetails,
    contactDetails,
    errorContactDetails,
    internationalDelivery
}) => {
    const { language } = useContext(LanguageContext)

    useEffect(() => {
        if (internationalDelivery && internationalDelivery.country_code) {
            setContactDetails({
                ...contactDetails,
                phoneCode: internationalDelivery.country_code,
            })

        }
    }, []);


    const modifyValue = (phoneNumber) => {
        if (phoneNumber.includes('+')) {
            const data = phoneNumber.split(" ")
            let finalNumber = '';
            if (data.length === 1) {
                const newData = data[0].split("").slice(4, 12)
                finalNumber = newData.join("")
            }
            else {
                data.map((num, i) => {
                    if (i !== 0) {
                        finalNumber += num
                    }
                })
            }
            return finalNumber
        }
        else {
            return phoneNumber
        }
    }
    return (
        <div style={{ position: "relative" }}>
            <CommonSectionHeader englishHeader="Contact Details" arabicHeader="ارقام التواصل" />
            <form action='javascript/void' autoComplete='on'>
                <div className='contact-details-form-maindiv'>
                    <form>
                        <div className="contact-details-form-div">
                            <label className="contact-details-label-name">
                                {language == "ltr" ? "Phone Number" : "رقم الهاتف"}
                            </label>
                            <div className='contact-form-container'>
                                <ReactFlagsSelect
                                    selected={contactDetails?.phoneCode}
                                    showSelectedLabel={false}
                                    customLabels={telecount}
                                    className='contact-details-flag'
                                    onSelect={(code) => {
                                        setContactDetails({
                                            ...contactDetails,
                                            phoneCode: code,
                                            phone: contactDetails?.phone?.substring(
                                                0,
                                                code == "KW" ? 8 : 12
                                            ),
                                        });
                                    }}
                                />
                                <input
                                    type="tel"
                                    className="contact-details-form-control contact-details-phone-number"
                                    placeholder=""
                                    id="phone"
                                    name="phone"
                                    required="true"
                                    value={contactDetails.phone}
                                    autoComplete="tel"
                                    onChange={(e) => {
                                        const newValue = modifyValue(e.target.value)
                                        if ((contactDetails.phoneCode === "KW" && newValue.length <= 8) || (contactDetails.phoneCode !== "KW" && newValue.length <= 10)) {
                                            setContactDetails({
                                                ...contactDetails,
                                                phone: newValue,
                                            });
                                        }
                                    }}
                                ></input>
                            </div>
                            {errorContactDetails.phoneError && (
                                <label className="error-text">
                                    {language == "ltr"
                                        ? errorContactDetails.phoneErrorMessage
                                        : errorContactDetails.phoneErrorMessagear}
                                </label>
                            )}
                        </div>
                        <div className="contact-details-form-div" style={{ display: "flex", alignItems: "flex-end" }} >
                            <div style={{ width: "100%" }}>
                                <div className='contact-form-container'>
                                    <input
                                        type="text"
                                        className="contact-details-form-control"
                                        placeholder=""
                                        id="name"
                                        name="name"
                                        required="true"
                                        autoComplete
                                        value={contactDetails.name}
                                        onChange={(e) => {
                                            // nameValidation(e.target.value);
                                            setContactDetails({
                                                ...contactDetails,
                                                name: e.target.value,
                                            });
                                        }}
                                    ></input>
                                    <label for="name" className="contact-details-label-name contact-details-label-name1">
                                        {language == "ltr" ? "Full Name" : "الاسم الكامل"}
                                    </label>
                                </div>
                                {errorContactDetails.nameError && (
                                    <label className="error-text">
                                        {language == "ltr"
                                            ? errorContactDetails.nameErrorMessage
                                            : errorContactDetails.nameErrorMessagear}
                                    </label>
                                )}
                            </div>

                        </div>
                    </form>
                    <div className="contact-details-form-div" style={{ marginBottom: "0", display: "flex", alignItems: "flex-end" }}>
                        <div style={{ width: "100%" }}>
                            <div className='contact-form-container'>
                                <input
                                    type="email"
                                    className="contact-details-form-control"
                                    placeholder=""
                                    id="email"
                                    name="email"
                                    required="true"
                                    autoComplete
                                    value={contactDetails.email}
                                    onChange={(e) => {
                                        setContactDetails({
                                            ...contactDetails,
                                            email: e.target.value,
                                        });
                                    }}
                                ></input>
                                <label for="email" className="contact-details-label-name contact-details-label-name1">
                                    {language == "ltr" ? "Email" : "البريد الإلكتروني"}
                                </label>
                            </div>
                            {errorContactDetails.emailError && (
                                <label className="error-text">
                                    {language == "ltr"
                                        ? errorContactDetails.emailErrorMessage
                                        : errorContactDetails.emailErrorMessagear}
                                </label>
                            )}
                        </div>
                    </div>
                </div>
            </form>
        </div >
    )
}

export default NewContactDetails