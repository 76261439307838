export const mapArea = [
  {
    area_id: 1,
    area_name: "Abdullah Al Salem",
    area_name_ar: "عبد الله السالم",
    area_map: "Abdullah al-Salem",
  },
  {
    area_id: 2,
    area_name: "Adailiyah",
    area_name_ar: "العديلية",
    area_map: "Adailiya",
  },
  {
    area_id: 3,
    area_name: "Bneid Al Gar",
    area_name_ar: "بنيد القار",
    area_map: "Bnied Al-Gar",
  },
  {
    area_id: 4,
    area_name: "Da'iya",
    area_name_ar: "الدعية",
    area_map: "Daiya",
  },
  {
    area_id: 5,
    area_name: "Dasma",
    area_name_ar: "الدسمة",
    area_map: "Dasma",
  },
  {
    area_id: 6,
    area_name: "Dasman",
    area_name_ar: "دسمان",
    area_map: "Dasman",
  },
  {
    area_id: 7,
    area_name: "Doha",
    area_name_ar: "الدوحة",
    area_map: "Doha"
  },
  {
    area_id: 8,
    area_name: "Doha Port",
    area_name_ar: "ميناء الدوحة",
    area_map: "Doha Port Kuwait",
  },
  {
    area_id: 9,
    area_name: "Faiha'",
    area_name_ar: "الفيحاء",
    area_map: "Faiha",
  },
  {
    area_id: 10,
    area_name: "Failakah",
    area_name_ar: "فيلكا",
    area_map: "Failaka Island",
  },
  {
    area_id: 11,
    area_name: "Ghurnata",
    area_name_ar: "غرناطة",
    area_map: "Granada",
  },
  {
    area_id: 13,
    area_name: "Qibla",
    area_name_ar: "جبلة",
    area_map: "Jibla"
  },
  {
    area_id: 14,
    area_name: "Kaifan",
    area_name_ar: "كيفان",
    area_map: "Kaifan",
  },
  {
    area_id: 15,
    area_name: "Khaldiya",
    area_name_ar: "الخالدية",
    area_map: "Khaldiya",
  },
  {
    area_id: 16,
    area_name: "Mansuriya",
    area_name_ar: "المنصورية",
    area_map: "Mansouriya",
  },
  {
    area_id: 17,
    area_name: "Mirgab",
    area_name_ar: "المرقاب",
    area_map: "Mirqab",
  },
  {
    area_id: 18,
    area_name: "Nahdha",
    area_name_ar: "النهضة",
    area_map: "Nahdha",
  },
  {
    area_id: 19,
    area_name: "North West Sulaibikhat",
    area_name_ar: "شمال غرب الصليبيخات",
    area_map: "North West Al-Sulaibikhat",
  },
  {
    area_id: 20,
    area_name: "Nuzha",
    area_name_ar: "النزهة",
    area_map: "Nuzha",
  },
  {
    area_id: 21,
    area_name: "Qadsiya",
    area_name_ar: "القادسية",
    area_map: "Qadsiya",
  },
  {
    area_id: 22,
    area_name: "Qurtuba",
    area_name_ar: "قرطبة",
    area_map: "Qortuba",
  },
  {
    area_id: 23,
    area_name: "Rawda",
    area_name_ar: "الروضة",
    area_map: "Rawda",
  },
  {
    area_id: 24,
    area_name: "Salhiya",
    area_name_ar: "الصالحية",
    area_map: "salhiya",
  },
  {
    area_id: 25,
    area_name: "Sawabir",
    area_name_ar: "الصوابر",
    area_map: "Sawabir",
  },
  {
    area_id: 26,
    area_name: "Shamiya",
    area_name_ar: "الشامية",
    area_map: "Shamiya",
  },
  {
    area_id: 27,
    area_name: "Sharq",
    area_name_ar: "شرق",
    area_map: "Sharq"
  },
  {
    area_id: 28,
    area_name: "Shuwaikh",
    area_name_ar: "الشويخ",
    area_map: "Shuwaikh Educational",
  },
  {
    area_id: 29,
    area_name: "Shuwaikh Industrial Area",
    area_name_ar: "الشويخ الصناعية",
    area_map: "Shuwaikh Industrial",
  },
  {
    area_id: 30,
    area_name: "Shuwaikh Port",
    area_name_ar: "ميناء الشويخ",
    area_map: "Shuwaikh Port",
  },
  {
    area_id: 31,
    area_name: "Sulaibikhat",
    area_name_ar: "الصليبخات",
    area_map: "Sulaibikhat",
  },
  {
    area_id: 32,
    area_name: "Surra",
    area_name_ar: "السرة",
    area_map: "Surra",
  },
  {
    area_id: 33,
    area_name: "Yarmouk",
    area_name_ar: "اليرموك",
    area_map: "Yarmouk",
  },
  {
    area_id: 34,
    area_name: "Kuwait City",
    area_name_ar: "مدينة الكويت",
    area_map: "Kuwait City",
  },
  {
    area_id: 35,
    area_name: "Mubarakiya",
    area_name_ar: "المباركية",
    area_map: "Mubarakiya",
  },
  {
    area_id: 142,
    area_name: "Shuwaikh Residential",
    area_name_ar: "الشويخ السكنية",
    area_map: "Shuwaikh Residential",
  },
  {
    area_id: 146,
    area_name: "Shuwaikh Administrative",
    area_name_ar: "الشويخ المنطقة الإدارية",
    area_map: "Shuwaikh Administrative",
  },
  {
    area_id: 147,
    area_name: "Shuwaikh Educational",
    area_name_ar: "الشويخ المنطقة التعليمية",
    area_map: "Shuwaikh Educational",
  },
  {
    area_id: 148,
    area_name: "Shuwaikh Industrial 1",
    area_name_ar: "الشويخ الصناعية",
    area_map: "Shuwaikh Educational",
  },
  {
    area_id: 151,
    area_name: "Shuwaikh Medical",
    area_name_ar: "الشويخ المنطقة الصحية",
    area_map: "Shuwaikh Educational",
  },
  {
    area_id: 36,
    area_name: "Salmiya",
    area_name_ar: "السالمية",
    area_map: "Salmiya",
  },
  {
    area_id: 37,
    area_name: "Hawalli",
    area_name_ar: "حولي",
    area_map: "Hawally",
  },
  {
    area_id: 38,
    area_name: "Salwa",
    area_name_ar: "سلوى",
    area_map: "Salwa",
  },
  {
    area_id: 39,
    area_name: "Jabriya",
    area_name_ar: "الجابرية",
    area_map: "Jabriya"
  },
  {
    area_id: 40,
    area_name: "Rumaithiya",
    area_name_ar: "الرميثية",
    area_map: "Rumaithiya"
  },
  {
    area_id: 41,
    area_name: "Bayan",
    area_name_ar: "بيان",
    area_map: "Bayan"
  },
  {
    area_id: 42,
    area_name: "Mishref",
    area_name_ar: "مشرف",
    area_map: "Mishref"
  },
  {
    area_id: 43,
    area_name: "Al Shaab",
    area_name_ar: "الشعب",
    area_map: "Shaab"
  },
  {
    area_id: 44,
    area_name: "Salam",
    area_name_ar: "السلام",
    area_map: "Salam"
  },
  {
    area_id: 45,
    area_name: "Heteen",
    area_name_ar: "حطين",
    area_map: "Hateen"
  },
  {
    area_id: 46,
    area_name: "Al Zahra",
    area_name_ar: "الزهراء",
    area_map: "Zahra"
  },
  {
    area_id: 47,
    area_name: "Mubarak Al Abdullah",
    area_name_ar: "مبارك العبدالله",
    area_map: "Mubarak Al-Abdullah"
  },
  {
    area_id: 48,
    area_name: "Al Shuhada",
    area_name_ar: "الشهداء",
    area_map: "Shuhada Area"
  },
  {
    area_id: 49,
    area_name: "Al Bedaa",
    area_name_ar: "البدع",
    area_map: "Al-Bidea"
  },
  {
    area_id: 50,
    area_name: "Al Seddiq",
    area_name_ar: "الصديق",
    area_map: "Al-Siddeeq"
  },
  {
    area_id: 51,
    area_name: "Maidan Hawalli",
    area_name_ar: "ميدان حولي",
    area_map: "Maidan hawally"
  },
  {
    area_id: 154,
    area_name: "Ministries Zone",
    area_name_ar: "منطقة الوزارات",
    area_map: "Ministries Zone"
  },
  {
    area_id: 52,
    area_name: "Abraq Khaitan",
    area_name_ar: "أبرق خيطان",
    area_map: "Abraq Khaitan"
  },
  {
    area_id: 53,
    area_name: "Farwaniya",
    area_name_ar: "الفروانية",
    area_map: "Al Farwaniyah"
  },
  {
    area_id: 54,
    area_name: "Al Andalus",
    area_name_ar: "الأندلس",
    area_map: "Andalous"
  },
  {
    area_id: 55,
    area_name: "Al Shadadeya",
    area_name_ar: "الشدادية",
    area_map: "Al-Shadadiya"
  },
  {
    area_id: 56,
    area_name: "Eshbilya",
    area_name_ar: "إشبيلة",
    area_map: "Ishbiliya"
  },
  {
    area_id: 57,
    area_name: "Al Rabya",
    area_name_ar: "الرابية",
    area_map: "Rabia"
  },
  {
    area_id: 58,
    area_name: "Al Rehab",
    area_name_ar: "الرحاب",
    area_map: "Al Rehab"
  },
  {
    area_id: 59,
    area_name: "Jeleeb Al Shuyokh",
    area_name_ar: "جليب الشيوخ",
    area_map: "Jleeb Al-Shuyoukh"
  },
  {
    area_id: 60,
    area_name: "Khaitan",
    area_name_ar: "خيطان",
    area_map: "Khaitan"
  },
  {
    area_id: 61,
    area_name: "Al Reqqei",
    area_name_ar: "الرقعي",
    area_map: "Riqqah District"
  },
  {
    area_id: 62,
    area_name: "Al Omayria",
    area_name_ar: "العمرية",
    area_map: "Al Omariya St"
  },
  {
    area_id: 63,
    area_name: "Industrial Al Rai",
    area_name_ar: "الري الصناعية",
    area_map: "al rai industrial area"
  },
  {
    area_id: 64,
    area_name: "Al Ardiya",
    area_name_ar: "العارضية",
    area_map: "Ardiya"
  },
  {
    area_id: 65,
    area_name: "Sabah Al Nasser",
    area_name_ar: "صباح الناصر",
    area_map: "Sabah Al Nasser"
  },
  {
    area_id: 66,
    area_name: "Industrial Ardiya",
    area_name_ar: "العارضية الصناعية",
    area_map: "Herafi Ardiya"
  },
  {
    area_id: 67,
    area_name: "Abdullah Al Mubarak",
    area_name_ar: "عبدالله المبارك",
    area_map: "Abdullah Al Mubarak Al Sabah"
  },
  {
    area_id: 68,
    area_name: "Firdous",
    area_name_ar: "الفردوس",
    area_map: "Firdous"
  },
  {
    area_id: 69,
    area_name: "Dajeej",
    area_name_ar: "الضجيج",
    area_map: "Al-Dajeej"
  },
  {
    area_id: 70,
    area_name: "West Abdullah Al Mubarak",
    area_name_ar: "غرب مبارك العبدالله",
    area_map: "Abdullah Al Mubarak Al Sabah"
  },
  {
    area_id: 71,
    area_name: "Abbasiya",
    area_name_ar: "العباسية",
    area_map: "Jleeb Al-Shuyoukh"
  },
  {
    area_id: 72,
    area_name: "Airport",
    area_name_ar: "مطار الكويت الدولي",
    area_map: "Kuwait International Airport"
  },
  {
    area_id: 73,
    area_name: "Exhibits (South Khaitan)",
    area_name_ar: "المعارض (جنوب خيطان)",
    area_map: "South Khaitan"
  },
  {
    area_id: 143,
    area_name: "Ardiya Storage Zone",
    area_name_ar: "العارضية المخازن",
    area_map: "Ardiya 6"
  },
  {
    area_id: 144,
    area_name: "South Abdullah Al Mubarak",
    area_name_ar: "جنوب عبد الله المبارك",
    area_map: "South Abdullah Al-Mubarak Co-op"
  },
  {
    area_id: 75,
    area_name: "Abu Halifa",
    branch_id: "134",
    area_map: "Abu Halifa"
  },
  {
    area_id: 76,
    area_name: "Al-Ahmadi",
    branch_id: "134",
    area_map: "Ahmadi"
  },
  {
    area_id: 77,
    area_name: "Al-Eqaila",
    branch_id: "134",
    area_map: "Eqaila"
  },
  {
    area_id: 78,
    area_name: "Thahar",
    branch_id: "134",
    area_map: "Az Zahr"
  },
  {
    area_id: 79,
    area_name: "Fahaheel",
    branch_id: "134",
    area_map: "Fahaheel"
  },
  {
    area_id: 80,
    area_name: "Fintas",
    branch_id: "134",
    area_map: "Fintas"
  },
  {
    area_id: 81,
    area_name: "Hadyia",
    branch_id: "134",
    area_map: "Hadiya"
  },
  {
    area_id: 82,
    area_name: "Jaber Al Ali",
    branch_id: "134",
    area_map: "Jaber Al Ali"
  },
  {
    area_id: 83,
    area_name: "Mahboula",
    branch_id: "134",
    area_map: "Mahboula"
  },
  {
    area_id: 84,
    area_name: "Mangaf",
    branch_id: "134",
    area_map: "Mangaf"
  },
  {
    area_id: 85,
    area_name: "Riqqa",
    branch_id: "134",
    area_map: "Riqqah District"
  },
  {
    area_id: 86,
    area_name: "Sabahiya",
    branch_id: "134",
    area_map: "Subahiya"
  },
  {
    area_id: 87,
    area_name: "Wafra",
    branch_id: "134",
    area_map: "Al Wafrah"
  },
  {
    area_id: 88,
    area_name: "Al Zour",
    branch_id: "134",
    area_map: "Zour"
  },
  {
    area_id: 89,
    area_name: "East Ahmadi",
    branch_id: "134",
    area_map: "East Ahmadi"
  },
  {
    area_id: 90,
    area_name: "Ali Sabah Al-Salem (Umm Al-Hayman)",
    branch_id: "134",
    area_map: "Fingerprint Services Umma Al-Hayman"
  },
  {
    area_id: 91,
    area_name: "Mina Abdullah",
    branch_id: "134",
    area_map: "Mina Abd Allah"
  },
  {
    area_id: 92,
    area_name: "Bnaider",
    branch_id: "134",
    area_map: "Bnaider"
  },
  {
    area_id: 93,
    area_name: "Julaya",
    branch_id: "134",
    area_map: "Julaya"
  },
  {
    area_id: 94,
    area_name: "Nuwayseeb",
    branch_id: "134",
    area_map: "Al-Nuwaiseeb"
  },
  {
    area_id: 95,
    area_name: "Al Khairan",
    branch_id: "134",
    area_map: "Al Khiran"
  },
  {
    area_id: 96,
    area_name: "Fahad Al-Ahmad",
    branch_id: "134",
    area_map: "Fahad Al Ahmad"
  },
  {
    area_id: 97,
    area_name: "Sabah Al-Ahmad Residential City",
    branch_id: "134",
    area_map: "sabah al ahmad"
  },
  {
    area_id: 98,
    area_name: "Sabah Al-Ahmad Marine City",
    branch_id: "134",
    area_map: "Sabah Al Ahmad Sea City Marina"
  },
  {
    area_id: 99,
    area_name: "Maqoa",
    branch_id: "134",
    area_map: "Mawaqaa"
  },
  {
    area_id: 100,
    area_name: "Ahmadi Port",
    branch_id: "134",
    area_map: "Mina Al-Ahmadi Port Tower"
  },
  {
    area_id: 101,
    area_name: "Shuaiba Port",
    branch_id: "134",
    area_map: "Port of Shuaiba"
  },
  {
    area_id: 102,
    area_name: "Wafra Farms",
    branch_id: "134",
    area_map: "Wafra Farm Road"
  },
  {
    area_id: 103,
    area_name: "Wafra Residental",
    branch_id: "134",
    area_map: "Wafra Living"
  },
  {
    area_id: 156,
    area_name: "South Sabahiya",
    branch_id: "134",
    area_map: "South Sabahiya"
  },
  {
    area_id: 159,
    area_name: "Khairan Resort",
    branch_id: "134",
    area_map: "Khairan Resort"
  },
  {
    area_id: 104,
    area_name: "Al Abdally",
    branch_id: "134",
    area_map: "Abdali"
  },
  {
    area_id: 106,
    area_name: "Al Jahra",
    branch_id: "134",
    area_map: "Al Jahra"
  },
  {
    area_id: 109,
    area_name: "Al Naeem",
    branch_id: "134",
    area_map: "Naeem"
  },
  {
    area_id: 110,
    area_name: "Al Naseem",
    branch_id: "134",
    area_map: "Naseem"
  },
  {
    area_id: 111,
    area_name: "Al Oyoun",
    branch_id: "134",
    area_map: "Oyoun"
  },
  {
    area_id: 112,
    area_name: "Al Qasr",
    branch_id: "134",
    area_map: "Qasr"
  },
  {
    area_id: 114,
    area_name: "Al Roudhatain",
    branch_id: "134",
    area_map: "Al Roudhatain"
  },
  {
    area_id: 115,
    area_name: "Al Salmy",
    branch_id: "134",
    area_map: "Al Salmy"
  },
  {
    area_id: 116,
    area_name: "Al Sabahiyah",
    branch_id: "134",
    area_map: "Subahiya"
  },
  {
    area_id: 117,
    area_name: "Al Sulaibya",
    branch_id: "134",
    area_map: "Al Sulaibiya Industrial 1"
  },
  {
    area_id: 118,
    area_name: "Al Waha",
    branch_id: "134",
    area_map: "Waha"
  },
  {
    area_id: 119,
    area_name: "Amghara",
    branch_id: "134",
    area_map: "Amghara"
  },
  {
    area_id: 120,
    area_name: "Jaber Al Ahmad City",
    branch_id: "134",
    area_map: "Jaber Al Ahmad"
  },
  {
    area_id: 121,
    area_name: "Kabd",
    branch_id: "134",
    area_map: "Kabed"
  },
  {
    area_id: 122,
    area_name: "Umm Al Aish",
    branch_id: "134",
    area_map: "Umm al Aish"
  },
  {
    area_id: 123,
    area_name: "Saad Al Abdallah City",
    branch_id: "134",
    area_map: "Saad Al Abdullah"
  },
  {
    area_id: 124,
    area_name: "Taima",
    branch_id: "134",
    area_map: "Taima'"
  },
  {
    area_id: 153,
    area_name: "Qairawan",
    branch_id: "134",
    area_map: "Qairawan"
  },
  {
    area_id: 160,
    area_name: "Sulaibya Industrial 1",
    branch_id: "134",
    area_map: "Al Sulaibiya Industrial 1"
  },
  {
    area_id: 125,
    area_name: "Abu Al Hasaniya",
    branch_id: "134",
    area_map: "Abu Al Hasaniya"
  },
  {
    area_id: 126,
    area_name: "Abu Futaira",
    branch_id: "134",
    area_map: "Abu 'Fteira"
  },
  {
    area_id: 127,
    area_name: "Adan",
    branch_id: "134",
    area_map: "Al-Adan District"
  },
  {
    area_id: 128,
    area_name: "Al Qurain",
    branch_id: "134",
    area_map: "Al-Qurain District"
  },
  {
    area_id: 129,
    area_name: "Al-Qusour",
    branch_id: "134",
    area_map: "Al-Qusour District"
  },
  {
    area_id: 130,
    area_name: "Fintas",
    branch_id: "134",
    area_map: "Fintas"
  },
  {
    area_id: 131,
    area_name: "Funaitis",
    branch_id: "134",
    area_map: "Fnaitees"
  },
  {
    area_id: 132,
    area_name: "Mesila",
    branch_id: "134",
    area_map: "Messila"
  },
  {
    area_id: 133,
    area_name: "Mubarak Al Kabeer",
    branch_id: "134",
    area_map: "Mubarak Al-Kabeer"
  },
  {
    area_id: 134,
    area_name: "Sabah Al Salem",
    branch_id: "134",
    area_map: "Sabah Al Salem"
  },
  {
    area_id: 135,
    area_name: "Sabhan",
    branch_id: "134",
    area_map: "Sabhan Industrial Area"
  },
  {
    area_id: 136,
    area_name: "Al Masayel",
    branch_id: "134",
    area_map: "Al-Masayel"
  },
  {
    area_id: 140,
    area_name: "West Abu Fateerah Small Industry",
    branch_id: "134",
    area_map: "LuLu Hypermarket - Al Qurain"
  }
];
