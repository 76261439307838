import axios from "axios";
import moment from "moment";
import React, { useContext } from "react";
import { useHistory } from "react-router";
import {
  AreaContext,
  CartContext,
  LanguageContext,
  VendorContext,
  VendorSlugContext,
} from "../App";
import { API_URL } from "../services/constants";

function ModalComponent({ popupDetails, setPopupDetails, setLoading }) {
  const details = useContext(VendorContext);
  const history = useHistory();
  const { language } = useContext(LanguageContext);
  const { areaDetails, setAreaDetails } = useContext(AreaContext);
  const vendorSlug = useContext(VendorSlugContext);
  const { setCart } = useContext(CartContext);

  const onYesClick = () => {
    axios
      .post(
        `${API_URL}/reset-cart-data`,
        JSON.stringify({
          token: process.env.REACT_APP_TOKEN,
          vendor_id: details?.vendor?.vendors_id,
          area_id: popupDetails?.key,
          vendor_slug: vendorSlug,
          user_string: localStorage.getItem("userID"),
        })
      )
      .then((res) => {
        if (res.data.data?.cart?.cartCount == 0) {
          setCart((g) => { });
        } else setCart((g) => res.data.data.cart);
      })
      .catch((e) => console.log(e))
      .finally(() =>
        axios
          .post(
            `${API_URL}/get-schedule-time`,
            JSON.stringify({
              token: process.env.REACT_APP_TOKEN,
              vendor_id: details?.vendor?.vendors_id,
              area_id: popupDetails?.key,
              vendor_slug: vendorSlug,
              lang: "ar",
            })
          )
          .then((res) => {
            let esti =
              res?.data?.data?.delivery_details?.delivery_expected_type != 6
                ? res?.data?.data?.delivery_details?.delivery_expected_time
                : 0;
            let kg = res.data.data.branch;
            let ff = areaDetails?.data?.branch?.filter(
              (k) => k?.id == kg?.id
            )[0];
            if (res.data.data.time == 1) {
              setAreaDetails((k) => ({
                ...areaDetails,
                area: popupDetails?.eng,
                minimum: popupDetails?.min,
                shopOpen: popupDetails?.avail == 1 ? res.data.data.time : 2,
                now: popupDetails?.avail == 1 ? res.data.data.time : 2,
                ar_area: popupDetails?.arab,
                branch: "",
                ar_branch: "",
                area_id: popupDetails?.key,
                deliveryTiming: res.data.data.schedule_time,
                ar_deliveryTiming: res.data.data.schedule_time_ar,
                customDelivery:
                  res.data.data?.delivery_details?.delivery_expected_type == 6,
                getDeliveryTiming:
                  popupDetails?.avail == 1 || res.data.data.time == 2
                    ? moment(
                      res.data.data.preorder_on,
                      "YYYY-MM-DD HH:mm:ss"
                    ).toDate()
                    : moment().add(esti, "minutes").toDate(),
                laterDeliveryTiming:
                  popupDetails?.avail == 1 || res.data.data.time == 2
                    ? moment(
                      res.data.data.preorder_on,
                      "YYYY-MM-DD HH:mm:ss"
                    ).toDate()
                    : moment().add(esti, "minutes").toDate(),
                branchForArea: {
                  ...res.data.data.branch,
                  end:
                    ff?.office_end_time > ff?.office_start_time
                      ? moment(ff?.office_end_time, "HH:mm:ss")
                      : moment(ff?.office_end_time, "HH:mm:ss").add(1, "days"),
                  start: moment(ff?.office_start_time, "HH:mm:ss"),
                },
              }));
            } else {
              setAreaDetails((l) => ({
                ...areaDetails,
                area: popupDetails?.eng,
                branch: "",
                minimum: popupDetails?.min,
                ar_branch: "",
                shopOpen: popupDetails?.avail == 1 ? res.data.data.time : 2,
                now: popupDetails?.avail == 1 ? res.data.data.time : 2,
                ar_area: popupDetails?.arab,
                area_id: popupDetails?.key,
                deliveryTiming: res?.data?.data?.schedule_time,
                ar_deliveryTiming: res?.data?.data?.schedule_time_ar,
                customDelivery:
                  res.data.data?.delivery_details?.delivery_expected_type == 6,
                getDeliveryTiming:
                  popupDetails?.avail == 1 || res.data.data.time == 2
                    ? moment(
                      res.data.data.preorder_on,
                      "YYYY-MM-DD HH:mm:ss"
                    ).toDate()
                    : moment().add(esti, "minutes").toDate(),
                laterDeliveryTiming:
                  popupDetails?.avail == 1 || res.data.data.time == 2
                    ? moment(
                      res.data.data.preorder_on,
                      "YYYY-MM-DD HH:mm:ss"
                    ).toDate()
                    : moment().add(esti, "minutes").toDate(),
                branchForArea: {
                  ...res.data.data.branch,
                  end:
                    ff?.office_end_time > ff?.office_start_time
                      ? moment(ff?.office_end_time, "HH:mm:ss")
                      : moment(ff?.office_end_time, "HH:mm:ss").add(1, "days"),
                  start: moment(ff?.office_start_time, "HH:mm:ss"),
                },
              }));
            }
          })
          .catch((e) => console.log(e))
          .finally(() => {
            setPopupDetails((r) => ({ show_popup: 0 }));
            history.goBack();
          })
      );
  };

  const onNoClick = () => {
    setPopupDetails((r) => ({ show_popup: 0 }));
    setLoading(false)
  };

  return (
    <div id="myModal" className="modal-made">
      <div className="modal-content-made">
        <p className="header-modal">
          {language == "ltr"
            ? "Confirm change in area"
            : "تأكيد التغيير في المنطقة"}{" "}
        </p>
        <p className="text-modal">
          {" "}
          {language == "ltr"
            ? "We may need to remove/update the items added in your cart due to change in area. Please choose if you want to continue?"
            : "قد نحتاج إلى إزالة / تحديث العناصر المضافة في سلة التسوق الخاصة بك بسبب التغيير في المنطقة. الرجاء اختيار ما إذا كنت تريد الاستمرار؟"}{" "}
        </p>
        <div className="button-container">
          <button onClick={() => onNoClick()} className="red">
            {language == "ltr" ? "No" : "لا"}
          </button>
          <button onClick={() => onYesClick()} className="green">
            {language == "ltr" ? "Yes" : "نعم"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ModalComponent;
