import React, { useContext, useEffect, useState } from 'react'
import { AreaContext, CartContext, LanguageContext, VendorContext } from '../../App'
import CommonHeader from '../NewCheckOutPage/Components/CommonHeader'
import NewOrderProductList from '../NewOrderDetailsPage/Components/NewOrderProductList'
import './reviewOrder.css'
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import moment from 'moment'
import SnapPixel from "react-snapchat-pixel";
import ReactPixel from 'react-facebook-pixel'
import CommonSectionHeader from '../NewCheckOutPage/Components/CommonSectionHeader'
import ModalClosed from '../ModalClosed'

const NewReviewOrderPage = () => {
    const details = useContext(VendorContext)
    const { areaDetails } = useContext(AreaContext)
    const { language } = useContext(LanguageContext)
    const [successPromocode, setSuccessPromocode] = useState()
    const { cart } = useContext(CartContext)
    const [popup, setPopup] = useState(false);
    const history = useHistory()

    useEffect(() => {
        if (
            details?.vendor?.snap_pixel_code &&
            details?.vendor?.snap_pixel_code != ""
        )
            SnapPixel.pageView();
        if (details?.vendor?.fb_pixel_code && details?.vendor?.fb_pixel_code != "") {
            ReactPixel.init(
                details?.vendor?.fb_pixel_code,
                {},
                { autoConfig: true, debug: false }
            );
            ReactPixel.pageView();
        }
    }, []);

    useEffect(() => {
        if (!cart?.cartCount) {
            history.push(`/`);
        }
    }, []);


    const onGoCheckoutClick = () => {
        if (
            !(
                areaDetails?.branchForArea?.start > moment() ||
                moment() > areaDetails?.branchForArea?.end
            )
        )
            history.push(`/contact-details`);
        else {
            if (
                !(
                    areaDetails?.data?.branch?.filter(
                        (k) => k?.id == areaDetails?.branchForArea?.id
                    )[0]?.on_shop_close_purchase == 1
                )
            )
                history.push(`/contact-details`);
            else {
                setPopup((loading) => true);
            }
        }
    };
    return (
        <>
            <div className='holder-container review-order-mainDiv'>
                <CommonHeader englishHeader="Review Order" arabicHeader="مراجعة الطلب" />
                {areaDetails.type == "delivery" && (details.vendor.international_delivery === "3" || details.vendor.international_delivery === "") &&
                    <>
                        <CommonSectionHeader englishHeader="Delivery Info" arabicHeader="معلومات التوصيل" />
                        <Link to='/timing' className='delivery-info-mainDIv '>
                            <div className='buyer-details-firstDiv'>
                                <div className='review-order-text'>
                                    {areaDetails?.now == 1
                                        ? language === "ltr"
                                            ? `${!areaDetails?.customDelivery ? "Delivery Within" : ""} ${areaDetails?.deliveryTiming
                                            }`
                                            : `${!areaDetails?.customDelivery ? "التوصيل سيكون خلال" : ""
                                            } ${areaDetails?.ar_deliveryTiming}`
                                        : moment(areaDetails?.laterDeliveryTiming)
                                            .locale("en")
                                            .format("DD") +
                                        " " +
                                        moment(areaDetails?.laterDeliveryTiming)
                                            .locale(language == "ltr" ? "en" : "ar-sa")
                                            .format("MMMM") +
                                        moment(areaDetails?.laterDeliveryTiming)
                                            .locale("en")
                                            .format(", yyyy hh:mm ") +
                                        moment(areaDetails?.laterDeliveryTiming)
                                            .locale(language == "ltr" ? "en" : "ar-sa")
                                            .format("A")}
                                </div>
                            </div>
                            <div className='buyer-details-secondDiv'>
                                <i className="fa fa-angle-right right-arrow"></i>
                            </div>
                        </Link>
                    </>
                }
                <CommonSectionHeader englishHeader="Items Details" arabicHeader="تفاصيل عربة التسوق" />
                <NewOrderProductList setSuccessPromocode={setSuccessPromocode} successPromocode={successPromocode} />
                <div className="newreview-details-div">
                    <p className="newreview-text">
                        {language === "ltr" ? "Sub Total" : "الإجمالي"}
                    </p>
                    <p className="newreview-text">
                        <span>{cart?.subTotal ? parseFloat(cart?.subTotal).toFixed(3) : 0}</span>{" "}
                        {language === "rtl" ? "د.ك" : "KD"}
                    </p>
                </div>
            </div>
            <div className='newreview-button-div'>
                <div className={`contact-details-bottom-button review-order-mobile-button contact-details-mobile-button  ${details?.vendor?.home_page_type === "18" && "fashion-theme"}`} style={{ width: "100%" }}>
                    <Link className='contact-details-back-button background-issue'
                        onClick={(e) => {
                            e.preventDefault();
                            history.push('/');
                        }}
                    >
                        {language === "ltr" ? "+ Add More" : "+ أضف المزيد "}
                    </Link>
                    <Link className='contact-details-next-button'
                        onClick={(e) => {
                            e.preventDefault();
                            if (
                                parseFloat(cart?.subTotal) >=
                                (details?.vendor?.minimum_charge != "" ||
                                    (areaDetails?.minimum != "" && areaDetails?.minimum)
                                    ? details?.vendor?.minimum_charge != ""
                                        ? parseFloat(details?.vendor?.minimum_charge)
                                        : parseFloat(areaDetails?.minimum)
                                    : 0)
                            )
                                onGoCheckoutClick();
                        }}
                    >
                        {parseFloat(cart?.subTotal) >=
                            (details?.vendor?.minimum_charge != "" ||
                                (areaDetails?.minimum != "" && areaDetails?.minimum)
                                ? details?.vendor?.minimum_charge != ""
                                    ? parseFloat(details?.vendor?.minimum_charge)
                                    : parseFloat(areaDetails?.minimum)
                                : 0)
                            ? language == "ltr"
                                ? "Checkout"
                                : "متابعة الطلب"
                            : language == "ltr"
                                ? `Minimum Order is ${areaDetails?.minimum != ""
                                    ? areaDetails?.minimum ? parseFloat(areaDetails?.minimum).toFixed(3) : 0
                                    : details?.vendor?.minimum_charge ? parseFloat(details?.vendor?.minimum_charge).toFixed(3) : 0
                                } KD`
                                : `الحد الأدنى للطلب هو ${areaDetails?.minimum != ""
                                    ? areaDetails?.minimum ? parseFloat(areaDetails?.minimum).toFixed(3) : 0
                                    : details?.vendor?.minimum_charge ? parseFloat(details?.vendor?.minimum_charge).toFixed(3) : 0
                                } د.ك`
                        }
                    </Link>
                </div>
            </div>
            {popup ? (
                <ModalClosed note={popup} setNote={setPopup}></ModalClosed>
            ) : null}
        </>
    )
}

export default NewReviewOrderPage