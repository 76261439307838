import React from "react";
import CategoryProduct from "./CategoryProduct";

function CategoryProductList({ products, loading, lastBookElementRef }) {
  return (
    <div className="product-category-view">
      <ul className="product-category-list">
        {loading == false
          ? products.map((item, k) => {
              if (k == products.length - 1 && lastBookElementRef)
                return (
                  <li ref={lastBookElementRef} className="product-category-pad">
                    <CategoryProduct item={item}></CategoryProduct>
                  </li>
                );
              else
                return (
                  <li className="product-category-pad">
                    <CategoryProduct item={item}></CategoryProduct>
                  </li>
                );
            })
          : [...Array(7)].map((k, i) => (
              <li className="product-category-pad shine-design">
                <div className="product-category-item">
                  <a style={{ alignItems: "center" }}>
                    <div
                      className="product-img-div"
                      style={{
                        position: "static",
                        backgroundColor: "#f3f3f3",
                        width: "90%",
                        zIndex: 0,
                      }}
                    ></div>
                    <div
                      className="mt-3"
                      style={{
                        minHeight: "44px",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <span
                        style={{
                          backgroundColor: "#f3f3f3",
                          display: "inline-block",
                          width: "50%",
                          height: "21px",
                          marginBottom: 10,
                        }}
                      ></span>
                      <span
                        style={{
                          backgroundColor: "#f3f3f3",
                          display: "inline-block",
                          width: "90%",
                          height: "15px",
                          marginBottom: 5,
                        }}
                      ></span>
                      <span
                        style={{
                          backgroundColor: "#f3f3f3",
                          display: "inline-block",
                          width: "80%",
                          height: "15px",
                          marginBottom: 15,
                        }}
                      ></span>
                    </div>
                    <div
                      className="product-price-div"
                      style={{ alignItems: "center" }}
                    >
                      <div className="product-price">
                        <span
                          style={{
                            backgroundColor: "#f3f3f3",
                            height: "20px",
                            width: "70px",
                          }}
                        ></span>
                      </div>
                      <div
                        style={{
                          borderRadius: "50%",
                          width: "25px",
                          backgroundColor: "#f3f3f3",
                          minHeight: "25px",
                          margin: "0 6px",
                        }}
                      ></div>
                    </div>
                  </a>
                </div>
              </li>
            ))}
      </ul>
    </div>
  );
}

export default CategoryProductList;
