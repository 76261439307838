import React from "react";
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";

const MyMapComponent = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?libraries=geometry,drawing,places&key=AIzaSyDK_1lc7uLQSGYHVpr0mGl-c1Zys2OPOdg",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `350px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) => (
  <GoogleMap
    defaultZoom={13}
    defaultCenter={{
      lat: parseFloat(props?.branch?.lat),
      lng: parseFloat(props?.branch?.lng),
    }}
  >
    <Marker
      position={{
        lat: parseFloat(props?.branch?.lat),
        lng: parseFloat(props?.branch?.lng),
      }}
    />
  </GoogleMap>
));

function MapContainer({ branch }) {
  return (
    <div>
      {branch?.lat && <MyMapComponent branch={branch}></MyMapComponent>}
    </div>
  );
}

export default MapContainer;
