import React from 'react'

const GoogleIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.93521 7.30007V10.7511H13.7272C13.2812 12.9441 11.4142 14.2041 8.93521 14.2041C6.04776 14.1643 3.72787 11.8123 3.72787 8.92457C3.72787 6.03684 6.04776 3.68481 8.93521 3.64507C10.1354 3.64363 11.2987 4.06014 12.2252 4.82307L14.8252 2.22307C11.8428 -0.398861 7.48721 -0.736093 4.13677 1.39552C0.786319 3.52712 -0.753767 7.61528 0.357458 11.4277C1.46868 15.2401 4.96416 17.8605 8.93521 17.8581C13.4022 17.8581 17.4642 14.6091 17.4642 8.92407C17.4573 8.37671 17.3902 7.83177 17.2642 7.29907L8.93521 7.30007Z" fill="#1D232E" />
        </svg>

    )
}

export default GoogleIcon