import axios from "axios";
import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { LanguageContext } from "../App";
import CategoryProduct from "./CategoryProduct";
import SubCategoriesSideScroll from "./SubCategories/SubCategoriesSideScroll";

function ScrollCategoryTab({ category }) {
  const history = useHistory();
  const { language } = useContext(LanguageContext);

  return (
    <div className="products-show-area">
      <div className="products-case-wrapper">
        <div className="product-show-more">
          <h3 className="sub-heading">
            {language === "ltr"
              ? category?.category_name
              : category?.category_name_ar}
          </h3>
          <Link
            to={`/products=${category?.category_slug}`}
            className="show-more"
          >
            {language === "ltr" ? "Show More" : "أظهر المزيد"}
          </Link>
        </div>
      </div>
      <div className="product-case-three">
        <div className="row flex-nowrap">
          {category?.is_subcategory
            ? category?.products?.slice(0, 10)?.map((item) => (
                <div
                  className="scroll-product-div mt-3"
                  style={{ minWidth: 275 }}
                >
                  <SubCategoriesSideScroll
                    item={item}
                  ></SubCategoriesSideScroll>
                </div>
              ))
            : category?.products?.slice(0, 10)?.map((item) => (
                <div
                  className="scroll-product-div mt-3"
                  style={{ minWidth: 275 }}
                >
                  <CategoryProduct item={item}></CategoryProduct>
                </div>
              ))}
          {category?.products?.length == 10 ? (
            <div
              className="scroll-product-div mt-3 hover-view"
              style={{
                position: "relative",
                width: 140,
                flexShrink: 0,
                cursor: "pointer",
              }}
              onClick={() =>
                history.push(`/products=${category?.category_slug}`)
              }
            >
              <div className="view-all">
                {language == "ltr" ? (
                  <>
                    View All&nbsp;<i className="fas fa-chevron-right"></i>
                  </>
                ) : (
                  <>
                    مشاهدة الكل&nbsp;<i className="fas fa-chevron-left"></i>
                  </>
                )}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default ScrollCategoryTab;
