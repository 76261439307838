import React from 'react'

const CrossIcon = ({ size }) => {
    return (<svg width={size} height={Number(size) + 1} viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.99996 19.125L19.125 3.00003" stroke="black" strokeWidth="4" strokeLinecap="round" />
        <path d="M2.54286 2.54276L18.6679 18.6678" stroke="black" strokeWidth="4" strokeLinecap="round" />
    </svg>

    )
}

export default CrossIcon