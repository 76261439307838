import moment from "moment";
import React, { useContext } from "react";
import { useState } from "react";
import { BookingSetContext, VendorContext } from "../../App";
import PackagePlace from "./components/PackagePlace";
import PackagePlaceSearch from "./components/PackagePlaceSearch";
import PackageReserver from "./components/PackageReserver";
import PackageSearch from "./components/PackageSearch";
import SubmitPackage from "./components/SubmitPackage";

function BookingPackage({ stepper, setStepper }) {
  const details = useContext(VendorContext);
  const [openCal, setOpenCal] = useState(true);
  const [tabOpen, setTabOpen] = useState(1);
  const { bookingSets } = useContext(BookingSetContext);

  // const [stepper, setStepper] = useState(0);
  const [bookingDetails, setBookingDetails] = useState({
    time: [],
    attendee: 2,
    week: [],
    branch: "",
    date: [moment()?.locale("en")],
  });
  const [contactDetails, setContactDetails] = useState({
    name: "",
    phoneCode: "KW",
    phone: "",
    email: "",
  });


  return (
    <>
      {stepper === 0 && (
        <>
          {!bookingSets?.placeClicked ? (
            <>
              {details?.vendor?.home_page_type == 2 ||
                details?.vendor?.home_page_type == 4 ? (
                <PackageSearch
                  stepper={stepper}
                  openCal={openCal}
                  setOpenCal={setOpenCal}
                  tabOpen={tabOpen}
                  setTabOpen={setTabOpen}
                  setStepper={setStepper}
                  bookingDetails={bookingDetails}
                  setBookingDetails={setBookingDetails}
                ></PackageSearch>
              ) : null}
              {details?.vendor?.home_page_type == 3 ? (
                <PackagePlaceSearch
                  stepper={stepper}
                  openCal={openCal}
                  setOpenCal={setOpenCal}
                  tabOpen={tabOpen}
                  setTabOpen={setTabOpen}
                  setStepper={setStepper}
                  bookingDetails={bookingDetails}
                  setBookingDetails={setBookingDetails}
                ></PackagePlaceSearch>
              ) : null}
            </>
          ) : (
            <PackagePlace
              setStepper={setStepper}
              bookingDetails={bookingDetails}
              setBookingDetails={setBookingDetails}
              booking={bookingSets?.selectedPlace}
            ></PackagePlace>
          )}
        </>
      )}
      {stepper === 1 && (
        <PackageReserver
          stepper={stepper}
          setStepper={setStepper}
          contactDetails={contactDetails}
          bookingDetails={bookingDetails}
          setContactDetails={setContactDetails}
        ></PackageReserver>
      )}
      {stepper === 2 && (
        <SubmitPackage
          stepper={stepper}
          setStepper={setStepper}
          bookingDetails={bookingDetails}
          setBookingDetails={setBookingDetails}
          contactDetails={contactDetails}
          setTabOpen={setTabOpen}
          setOpenCal={setOpenCal}
        ></SubmitPackage>
      )}
    </>
  );
}

export default BookingPackage;
