import React, { useContext, useState } from 'react'
import { LanguageContext } from '../../../App'
import $ from "jquery";

const CustomAccrodian = ({ accordianArray }) => {
    const { language } = useContext(LanguageContext)
    const [activeItem, setActiveItem] = useState(null);
    const isItemActive = (id) => {
        return activeItem === id
    }
    const handleAccordian = (i) => {
        setActiveItem(activeItem == i ? null : i)
        if (activeItem !== i) {
            $("html, body").animate(
                {
                    scrollTop:
                        $(`#heading-${i}`).offset().top -
                        (window.screen.width < 991 ? 107 : 59),
                },
                "slow"
            );
        }
    }
    return (
        <div id="accordion" >
            {accordianArray && accordianArray.map((ele, i) =>
                <div key={i} className='accordion-all-div'>
                    <div className="accrodian-header-div" id={`heading-${i}`} data-toggle="collapse" data-target={`#collapse-${i}`} aria-expanded="false" aria-controls={`collapse-${i}`} onClick={() => handleAccordian(i)}>
                        <button className="accrodian-header-text" >
                            {
                                language === "ltr" ? ele.english : ele.arabic
                            }
                        </button>
                        <div>
                            <i className={`fa fa-angle-right right-arrow  ${isItemActive(i) ? 'rotate' : 'unrotate'}`}></i>
                        </div>
                    </div>

                    <div id={`collapse-${i}`} className="collapse" aria-labelledby={`heading-${i}`} data-parent="#accordion">
                        <div className="accrodian-body">
                            {ele.component}
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default CustomAccrodian