import React, { useContext, useState } from 'react'
import { LanguageContext, VendorContext } from '../../../App'
import NewCommonNavbar from '../../NewCommonNavbar'
import NewSideBar from '../../NewSideBar'

const CenteredLayout = ({ component, isnavbar }) => {
    const [burger, setBurger] = useState(false)
    const details = useContext(VendorContext)
    const { language, setLanguage } = useContext(LanguageContext)
    return (
        <div className='centered-theme-maindiv'>
            <div className={`centered-theme-div ${burger ? "hide-overflow" : ""}`} style={{ position: "relative", minHeight: "100vh" }}>
                {
                    isnavbar && <NewCommonNavbar
                        burger={burger}
                        setBurger={setBurger}
                        language={language}
                        setLanguage={setLanguage}
                        details={details}
                    />
                }
                {
                    burger && <NewSideBar setBurger={setBurger} />
                }
                {component}
            </div>
        </div>
    )
}

export default CenteredLayout