import React, { useContext, useEffect, useState } from "react";
import { LanguageContext } from "../../App";
import ProductBoxView from "./Component/ProductBoxView";
import $ from "jquery";
import ProductSideView from "./Component/ProductSideView";
import ToggleProductHeader from "./Component/ToggleProductHeader";

function ToggleProduct({
  details,
  setToggleView,
  toggleView,
  categories,
  setcategoryopen,
  categorynow,
  setcategorynow,
}) {
  const [ActiveCategory, setActiveCategory] = useState(null);
  const { language } = useContext(LanguageContext);
  const [selectcategorynow, setselectcategorynow] = useState(null);
  const [indexId, setindexId] = useState(0);
  const [userClick, setUserClick] = useState(false);
  useEffect(() => {
    if (categorynow) {
      setselectcategorynow(categorynow);
    }
  }, [categorynow]);
  return (
    <div>
      <ToggleProductHeader
        indexId={indexId}
        setindexId={setindexId}
        selectcategorynow={selectcategorynow}
        setcategorynow={setcategorynow}
        setselectcategorynow={setselectcategorynow}
        toggleView={toggleView}
        setToggleView={setToggleView}
        categories={details?.categories}
        ActiveCategory={ActiveCategory}
        setActiveCategory={setActiveCategory}
        setUserClick={setUserClick}
      />
      {toggleView == 1 ? (
        <div
          style={
            language == "ltr"
              ? {
                  position: "relative",
                  transition: "1s",
                  left: toggleView == 1 ? 0 : "-100%",
                }
              : {
                  position: "relative",
                  transition: "1s",
                  right: toggleView == 1 ? 0 : "-100%",
                }
          }
        >
          <div>
            <ProductBoxView
              toggleView={toggleView}
              selectcategorynow={selectcategorynow}
              indexId={indexId}
              setindexId={setindexId}
              categories={categories}
              setselectcategorynow={setselectcategorynow}
              setActiveCategory={setActiveCategory}
              setcategoryopen={setcategoryopen}
              userClick={userClick}
            ></ProductBoxView>
          </div>
        </div>
      ) : (
        <div
          style={
            language == "ltr"
              ? {
                  position: "relative",
                  transition: "1s",
                  left: toggleView == 0 ? 0 : "-100%",
                }
              : {
                  position: "relative",
                  transition: "1s",
                  right: toggleView == 0 ? 0 : "-100%",
                }
          }
        >
          <div>
            <ProductSideView
              toggleView={toggleView}
              selectcategorynow={selectcategorynow}
              indexId={indexId}
              setindexId={setindexId}
              setselectcategorynow={setselectcategorynow}
              categories={categories}
              setcategoryopen={setcategoryopen}
              setActiveCategory={setActiveCategory}
              userClick={userClick}
            ></ProductSideView>
          </div>
        </div>
      )}
    </div>
  );
}

export default ToggleProduct;
