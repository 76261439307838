import React, { useContext } from 'react'
import { VendorContext } from '../../App'
import CenteredLayout from './Component/CenteredLayout'
import SideviewLayout from './Component/SideviewLayout'

const LayoutHolder = ({ component, isnavbar }) => {
    const details = useContext(VendorContext)
    return (
        <div style={{ position: "relative" }}>
            {details?.vendor?.home_page_type === "18" ?
                <CenteredLayout component={component} isnavbar={isnavbar} />
                :
                <SideviewLayout component={component} isnavbar={isnavbar} />
            }
        </div>
    )
}

export default LayoutHolder