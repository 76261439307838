import React from "react";
import "./newcommonnavbar.css";
import SearchIcon from "../../SVGs/SearchIcon";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const NewCommonNavbar = ({ setLanguage, language, setBurger }) => {
  const history = useHistory();
  const onLanguageChange = () => {
    document
      .getElementsByTagName("html")[0]
      .setAttribute("dir", language.split("").reverse().join(""));
    sessionStorage.setItem("language", language.split("").reverse().join(""));
    setLanguage(language.split("").reverse().join(""));
  };
  return (
    <div className="newCommon-navbar-mainDiv">
      <div
        className="newCommon-navbar-hambuger"
        onClick={() => setBurger(true)}
      >
        <div></div>
        <div></div>
      </div>
      <div className="newCommon-navbar-langugaeGroup">
        {window.location.host != "alrubaiaanlibrary.payzah.store" && (
          <div
            className="newCommon-navbar-language-name"
            onClick={onLanguageChange}
          >
            {language === "ltr" ? "عربي" : "English"}
          </div>
        )}
        <div
          onClick={() => {
            history.push("/search");
          }}
          style={{ cursor: "pointer" }}
        >
          <SearchIcon color="#fff" />
        </div>
      </div>
    </div>
  );
};

export default NewCommonNavbar;
