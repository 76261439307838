import React from "react";

const OrderCancelIcon = () => {
  return <svg width="92" height="93" viewBox="0 0 92 93" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M78 46C78 50.2023 77.1723 54.3635 75.5641 58.2459C73.956 62.1283 71.5989 65.6559 68.6274 68.6274C65.6559 71.5989 62.1283 73.956 58.2459 75.5641C54.3635 77.1723 50.2023 78 46 78C41.7977 78 37.6365 77.1723 33.7541 75.5641C29.8717 73.956 26.3441 71.5989 23.3726 68.6274C20.4011 65.6559 18.044 62.1283 16.4359 58.2459C14.8277 54.3635 14 50.2023 14 46C14 37.5131 17.3714 29.3737 23.3726 23.3726C29.3737 17.3714 37.5131 14 46 14C54.4869 14 62.6263 17.3714 68.6274 23.3726C74.6286 29.3737 78 37.5131 78 46Z" stroke="#FF0000" stroke-width="5" />
    <path d="M35.3333 56.6667L56.6667 35.3333M56.6667 56.6667L35.3333 35.3333" stroke="#FF0000" stroke-width="5" stroke-linecap="round" />
  </svg>

};

export default OrderCancelIcon;
