import React, { useContext } from "react";
import { LanguageContext } from "../../../App";

const CategoryHead = ({ category }) => {
    const { language } = useContext(LanguageContext);

    return <div className="listGrid-Head">
        <div>
            {
                language === "ltr" ? category?.category_name : category?.category_name_ar
            }
        </div>
        <div className="listGrid-arrow">
            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.36572 7.76855L10.463 12.8662L15.5605 7.76874" stroke="black" stroke-width="1.50002" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </div>
    </div>;
};

export default CategoryHead;
