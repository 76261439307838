import axios from "axios";
import sha256 from "sha256";



export const setPageView = async ({ fb_pixel_code, fb_access_token, support_mail }) => {
    try {
        const time = Date.now();
        const sec = Math.round(time / 1000);
        const response = await axios.post(`https://graph.facebook.com/v15.0/${fb_pixel_code}/events?access_token=${fb_access_token}`,
            {
                data: [
                    {
                        action_source: "website",
                        event_name: "PageView",
                        event_time: sec,
                        user_data: {
                            client_user_agent:
                                "Mozilla/5.0 (iPhone; CPU iPhone OS 13_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/13.0.5 Mobile/15E148 Safari/604.1",
                            em: [sha256(support_mail)],
                        },
                    },
                ],
            }
        )
        return response.data
    } catch (error) {
        console.log(error)
    }
}

export const triggerAddToCart = async ({ fb_pixel_code, fb_access_token, support_mail, item }) => {
    try {
        const time = Date.now();
        const sec = Math.round(time / 1000);
        const response = await axios.post(`https://graph.facebook.com/v15.0/${fb_pixel_code}/events?access_token=${fb_access_token}`,
            {
                data: [
                    {
                        action_source: "website",
                        event_name: "AddToCart",
                        event_time: sec,
                        user_data: {
                            client_user_agent:
                                "Mozilla/5.0 (iPhone; CPU iPhone OS 13_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/13.0.5 Mobile/15E148 Safari/604.1",
                            em: [sha256(support_mail)],
                        },
                        custom_data: {
                            content_name: item?.product_name,
                            content_category: item?.category_name,
                            content_ids: [item?.id],
                            content_type: "product",
                            value: item?.product_price,
                            currency: "KWD",
                        }
                    },
                ],
            }
        )
        return response.data
    } catch (error) {
        console.log(error)
    }
}


export const viewProduct = async ({ fb_pixel_code, fb_access_token, support_mail, product }) => {
    try {
        const time = Date.now();
        const sec = Math.round(time / 1000);
        const response = await axios.post(`https://graph.facebook.com/v15.0/${fb_pixel_code}/events?access_token=${fb_access_token}`,
            {
                data: [
                    {
                        action_source: "website",
                        event_name: "ViewProduct",
                        event_time: sec,
                        user_data: {
                            client_user_agent:
                                "Mozilla/5.0 (iPhone; CPU iPhone OS 13_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/13.0.5 Mobile/15E148 Safari/604.1",
                            em: [sha256(support_mail)],
                        },
                        custom_data: {
                            content_name: product.name,
                            content_category: product.category_name,
                            content_type: "product",
                        }
                    },
                ],
            }
        )
        return response.data
    } catch (error) {
        console.log(error)
    }

}