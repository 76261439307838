import React, { useContext, useState } from 'react'
import { LanguageContext } from '../../App'
import './trackorder.css'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const TrackOrder = () => {
    const [trackingNumber, setTrackingNumber] = useState()
    const { language } = useContext(LanguageContext)
    const history = useHistory()
    const handleSubmit = () => {
        if (trackingNumber) {
            history.push(`/order=${trackingNumber}`)
        }
    }
    return (
        <div className='trackOrder-whitebox'>

            <div style={{ padding: "30px 20px" }}>
                <div className='tracker-order-center order-tracker-text'>
                    {language === "ltr" ? "Orders Tracker" : "تعقب الطلبات"}
                </div>
                <div className='tracker-order-center order-tracker-image'>
                    <img src='pictures/delivery-packed-image.jpg' className='trackorder-image' />
                </div>

                <div className='tracker-order-center shipment-tracker-text'>
                    {language === "ltr" ? "Track shipments and orders by entering the tracking number." : "تتبع الشحنات والطلبات عن طريق إدخال رقم التتبع."}
                </div>
                <div className='tracker-order-center'>
                    <div className='track-divider-line'></div>
                </div>
                <div className='tracker-order-center'>
                    <input type='text'
                        onChange={(e) => {
                            setTrackingNumber(e.target.value)
                        }}
                        className='order-tracker-input-field'
                        placeholder={language === "ltr" ? "Tracking Number Goes Here" : "ضع رقم الطلب هنا لتتبعه"}
                    />
                </div>
                <div className='tracker-order-center order-tracker-input-div'>


                    <button className='order-tracker-input-button' onClick={handleSubmit}>
                        {
                            language === "ltr" ? "Track" : "تتبع الطلب"
                        }
                    </button>
                </div>
            </div>
        </div >
    )
}

export default TrackOrder