import axios from "axios";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  AreaContext,
  LanguageContext,
  VendorContext,
} from "../App";
import { BOOK_URL } from "../services/constants";

const weekMap = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 7,
};

function BookingConfirm() {
  const [orderDetails, setOrderDetails] = useState({});
  const { language } = useContext(LanguageContext);
  const { id } = useParams();
  const details = useContext(VendorContext);
  const { areaDetails } = useContext(AreaContext);

  useEffect(() => {
    if (details?.vendor?.slug && areaDetails?.data?.branch)
      axios
        .post(
          `${BOOK_URL}/get-order-details`,
          JSON.stringify({
            token: process.env.REACT_APP_TOKEN,
            vendor_slug: details?.vendor?.slug,
            booking_vendor_id: details?.vendor?.booking_vendor_id,
            order_number: id,
          })
        )
        .then((res) => {
          let temp = res.data.data;
          let term =
            temp?.order?.booking_order_details?.[0]?.booking_from ==
              temp?.order?.booking_order_details?.[0]?.booking_till
              ? 1
              : 2;
          let branch = areaDetails?.data?.branch?.filter((b) => {
            return b?.id == temp?.order?.location_id;
          });
          let months = [];
          temp?.order?.booking_dates?.forEach((k) => {
            let s = moment(k, "YYYY-MM-DD")?.locale("en");
            if (!months?.some((l) => l.en == s.format("MMMM")))
              months.push({
                en: s?.locale("en")?.format("MMMM"),
                ar: s?.locale("ar")?.format("MMMM"),
              });
          });
          let sessions =
            temp?.order?.booking_dates?.length *
            temp?.order?.booking_order_details?.length;
          setOrderDetails((products) => ({
            ...res.data.data,
            term,
            branch: branch?.length != 0 ? branch[0] : {},
            months,
            sessions,
          }));
        })
        .catch((e) => console.log(e));
  }, [details?.vendor, areaDetails?.data?.branch]);

  return (
    <>
      {orderDetails?.branch && (
        <>
          <div className="order-placing">
            <div className="place-order-success text-center">
              {orderDetails?.order?.payment_status ? (
                <>
                  <i
                    className="fa fa-check-circle-o "
                    aria-hidden="true"
                  ></i>
                  <h3 className="sub-heading pt-4">
                    {language == "ltr" ? "Success" : ""}
                  </h3>
                </>
              ) : (
                <>
                  <i className="fa fa-times " aria-hidden="true"></i>
                  <h3 className="sub-heading pt-4">
                    {language == "ltr" ? "Sorry" : "الطلب لم يتم"}
                  </h3>
                </>
              )}
              <div className="order-id mt-4" style={{ paddingBottom: 0 }}>
                <h3 className="order-numb" style={{ marginBottom: 0 }}>
                  {language == "ltr" ? "Order Code:" : "مرجع الطلب:"}
                  <span className="code-of-order">{id}</span>
                </h3>
              </div>
              <img
                style={{
                  display: "block",
                  margin: "0px auto",
                  width: 200,
                  height: 200,
                  objectFit: "contain",
                }}
                src={`https://chart.googleapis.com/chart?cht=qr&chl=${window.location.href}&chs=150x150`}
              ></img>
              <a
                rel="noreferrer"
                download
                target={"_blank"}
                href={`https://chart.googleapis.com/chart?cht=qr&chl=${window.location.href}&chs=150x150`}
                className="card-submit mt-2"
                style={{
                  maxWidth: 200,
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                {language == "ltr" ? "Download QR Code" : "تنزيل QR Code"}
              </a>
            </div>
            <div className="place-order-wrapper">
              {true && (
                <div className="open-detail pt-2">
                  <div className="left-wrapper-delivery-outer">
                    <div className="order-delivery-info">
                      <h3 className="sub-heading">
                        {orderDetails?.term == 1
                          ? language === "ltr"
                            ? "Short Term Booking Details"
                            : "معلومات الحجز على المدى القصير"
                          : language === "ltr"
                            ? "Long Term Booking Details"
                            : "معلومات الحجز طويل المدى"}
                      </h3>
                      <ul
                        style={{ padding: 0 }}
                        className="cart-pricing-details"
                      >

                        <>
                          <li className="cart-sub-total mt-2">
                            <p className="product-price booking-price-p">
                              {language == "ltr"
                                ? "Full Name"
                                : "الاسم الكامل"}
                            </p>
                            <p className="product-price booking-price-p">
                              {orderDetails?.order?.first_name}
                            </p>
                          </li>

                          <li className="cart-sub-total mt-2">
                            <p className="product-price booking-price-p">
                              {language == "ltr" ? "Phone" : "هاتف"}
                            </p>
                            <p className="product-price booking-price-p">
                              <span
                                style={{ fontSize: 14 }}
                                className="number-span"
                                dir="ltr"
                              >
                                {orderDetails?.order?.country_code}{" "}
                                {orderDetails?.order?.phone_number}
                              </span>
                            </p>
                          </li>

                          <li className="cart-sub-total mt-2">
                            <p className="product-price booking-price-p">
                              {language == "ltr"
                                ? details?.vendor?.place_label
                                : details?.vendor?.place_label_ar}
                            </p>
                            <p className="product-price booking-price-p">
                              {language == "ltr"
                                ? orderDetails?.places?.name
                                : orderDetails?.places?.name_ar}
                            </p>
                          </li>

                          <li className="cart-sub-total mt-2">
                            <p
                              style={{ marginTop: 0 }}
                              className="product-price booking-price-p"
                            >
                              {language == "ltr" ? "Address" : "تبوك"}
                            </p>
                            <p
                              style={{
                                maxWidth: "80%",
                                textAlign:
                                  language == "ltr" ? "right" : "left",
                              }}
                              className="product-price booking-price-p"
                            >
                              {language == "ltr"
                                ? orderDetails?.places?.description
                                : orderDetails?.places?.description_ar}
                            </p>
                          </li>

                          {orderDetails?.term === 2 && (
                            <li className="cart-sub-total mt-2">
                              <p className="product-price booking-price-p ">
                                {language == "ltr" ? "Duration" : "المدة"}
                              </p>
                              <p className="product-price booking-price-p">
                                {orderDetails?.months?.map((k, i) =>
                                  i == 0
                                    ? language == "ltr"
                                      ? k?.en
                                      : k?.ar
                                    : `, ${language == "ltr" ? k?.en : k?.ar
                                    }`
                                )}
                              </p>
                            </li>
                          )}

                          {orderDetails?.term === 2 && (
                            <li className="cart-sub-total mt-2">
                              <p className="product-price booking-price-p ">
                                {language == "ltr"
                                  ? "Days of the Week"
                                  : "أيام الأسبوع"}
                              </p>
                              <p className="product-price booking-price-p">
                                {orderDetails?.order?.booking_days?.map(
                                  (k, i) =>
                                    i == 0
                                      ? moment(weekMap[k], "E")
                                        ?.locale(
                                          language == "ltr" ? "en" : "ar"
                                        )
                                        ?.format("dddd")
                                      : `, ${moment(weekMap[k], "E")
                                        ?.locale(
                                          language == "ltr" ? "en" : "ar"
                                        )
                                        ?.format("dddd")}`
                                )}
                              </p>
                            </li>
                          )}

                          <li className="cart-sub-total mt-2">
                            <p className="product-price booking-price-p">
                              {details.vendor.booking_vendor_id !== "24"
                                ? language == "ltr"
                                  ? "No. of Sessions"
                                  : "عدد الحصص"
                                : language == "ltr"
                                  ? "Party Of"
                                  : "عدد الحضور"}
                            </p>
                            <p className="product-price booking-price-p">
                              <span
                                style={{ fontSize: 15 }}
                                className="number-span"
                              >
                                {orderDetails?.sessions}
                              </span>
                            </p>
                          </li>

                          {orderDetails?.term === 1 && (
                            <li className="cart-sub-total mt-2">
                              <p className="product-price booking-price-p ">
                                {language == "ltr" ? "Date" : "التاريخ"}
                              </p>
                              <p className="product-price booking-price-p">
                                {language == "ltr" ? (
                                  moment(
                                    orderDetails?.order
                                      ?.booking_order_details?.[0]
                                      ?.booking_from
                                  )
                                    ?.locale("en")
                                    ?.format("D MMM YYYY")
                                ) : (
                                  <>
                                    <span
                                      style={{ fontSize: 15 }}
                                      className="number-span"
                                    >
                                      {moment(
                                        orderDetails?.order
                                          ?.booking_order_details?.[0]
                                          ?.booking_from
                                      )
                                        ?.locale("en")
                                        ?.format("D")}
                                    </span>{" "}
                                    {moment(
                                      orderDetails?.order
                                        ?.booking_order_details?.[0]
                                        ?.booking_from
                                    )
                                      ?.locale("ar")
                                      ?.format("MMM")}{" "}
                                    <span
                                      style={{ fontSize: 15 }}
                                      className="number-span"
                                    >
                                      {moment(
                                        orderDetails?.order
                                          ?.booking_order_details?.[0]
                                          ?.booking_from
                                      )
                                        ?.locale("en")
                                        ?.format("YYYY")}
                                    </span>
                                  </>
                                )}
                              </p>
                            </li>
                          )}

                          {orderDetails?.term === 2 && (
                            <li className="cart-sub-total mt-2">
                              <p
                                className="product-price booking-price-p"
                                style={{ marginTop: 0 }}
                              >
                                {language == "ltr" ? "Date" : "التاريخ"}
                              </p>
                              <p
                                style={{
                                  maxWidth: "80%",
                                  textAlign:
                                    language == "ltr" ? "right" : "left",
                                }}
                                className="product-price booking-price-p"
                              >
                                {orderDetails?.order?.booking_dates?.map(
                                  (t, k) => (
                                    <>
                                      {k % 4 == 0 && k != 0 ? (
                                        <>
                                          <br></br>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      <span
                                        style={{ fontSize: 15 }}
                                        className="number-span"
                                      >
                                        {moment(t, "YYYY-MM-DD")
                                          ?.locale("en")
                                          ?.format("D ")}
                                      </span>
                                      {moment(t, "YYYY-MM-DD")
                                        ?.locale(
                                          language == "ltr" ? "en" : "ar"
                                        )
                                        ?.format("MMM")}
                                      {k !=
                                        orderDetails?.order?.booking_dates
                                          ?.length -
                                        1 ? (
                                        <>
                                          {","}
                                          {(k + 1) % 4 == 0 ? (
                                            ""
                                          ) : (
                                            <> &nbsp;</>
                                          )}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  )
                                )}
                              </p>
                            </li>
                          )}

                          {details.vendor.booking_vendor_id !== "24" ? (
                            <li className="cart-sub-total mt-2">
                              <p
                                className="product-price booking-price-p"
                                style={{ marginTop: 0 }}
                              >
                                {language == "ltr" ? "Time" : "الوقت"}
                              </p>
                              <p
                                style={{ maxWidth: "80%" }}
                                className="product-price booking-price-p"
                              >
                                {orderDetails?.order?.booking_order_details?.map(
                                  (t, k) =>
                                    language == "ltr" ? (
                                      <>
                                        {k != 0 ? (
                                          <>
                                            ,<br></br>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                        {moment(
                                          t?.slot_start_time,
                                          "HH:mm:ss"
                                        )
                                          ?.locale("en")
                                          ?.format("hh:mm a")}
                                        {" - "}
                                        {moment(
                                          t?.slot_end_time,
                                          "HH:mm:ss"
                                        )
                                          ?.locale("en")
                                          ?.format("hh:mm a")}
                                      </>
                                    ) : (
                                      <>
                                        {k != 0 ? (
                                          <>
                                            ,<br></br>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                        <span
                                          style={{ fontSize: 15 }}
                                          className="number-span"
                                        >
                                          {moment(
                                            t?.slot_start_time,
                                            "HH:mm:ss"
                                          )
                                            ?.locale("en")
                                            ?.format("hh")}
                                        </span>
                                        :
                                        <span
                                          style={{ fontSize: 15 }}
                                          className="number-span"
                                        >
                                          {moment(
                                            t?.slot_start_time,
                                            "HH:mm:ss"
                                          )
                                            ?.locale("en")
                                            ?.format("mm")}
                                        </span>{" "}
                                        {moment(
                                          t?.slot_start_time,
                                          "HH:mm:ss"
                                        )
                                          ?.locale("ar")
                                          ?.format("a")}
                                        {" - "}
                                        <span
                                          style={{ fontSize: 15 }}
                                          className="number-span"
                                        >
                                          {moment(
                                            t?.slot_end_time,
                                            "HH:mm:ss"
                                          )
                                            ?.locale("en")
                                            ?.format("hh")}
                                        </span>
                                        :
                                        <span
                                          style={{ fontSize: 15 }}
                                          className="number-span"
                                        >
                                          {moment(
                                            t?.slot_end_time,
                                            "HH:mm:ss"
                                          )
                                            ?.locale("en")
                                            ?.format("mm")}
                                        </span>{" "}
                                        {moment(
                                          t?.slot_end_time,
                                          "HH:mm:ss"
                                        )
                                          ?.locale("ar")
                                          ?.format("a")}
                                      </>
                                    )
                                )}
                              </p>
                            </li>
                          ) : null}

                          {orderDetails.order.is_trainer_required == 1 && (
                            <li className="cart-sub-total mt-2">
                              <p className="product-price booking-price-p">
                                {language == "ltr" ? "Note" : "ملحوظة"}
                              </p>
                              <p className="product-price booking-price-p">
                                {language == "ltr"
                                  ? "Trainer is required"
                                  : "مطلوب مدرب"}
                              </p>
                            </li>
                          )}
                        </>
                      </ul>
                    </div>
                  </div>
                </div>
              )}
              {orderDetails?.order?.payment_status && (
                <div className="open-detail pt-2">
                  <div className="left-wrapper-delivery-outer">
                    <div className="order-delivery-info">
                      <h3 className="sub-heading">
                        {language == "ltr"
                          ? "Payment Details"
                          : "بيانات الدفع"}
                      </h3>
                      <ul
                        className="cart-pricing-details"
                        style={{ padding: 0 }}
                      >
                        <li
                          className="cart-sub-total mt-2"
                          style={{ alignItems: "center" }}
                        >
                          <p
                            className="product-price booking-price-p"
                            style={{ marginTop: 0 }}
                          >
                            {language == "ltr"
                              ? "Payment Method"
                              : "طريقة الدفع"}
                          </p>
                          <p className="product-price booking-price-p">
                            {orderDetails?.order?.payment_method == 1 && (
                              <>
                                {language == "ltr" ? "K-Net" : "كي نت"}
                                &nbsp;
                                <img
                                  style={{ width: 28, height: 22 }}
                                  src={"pictures/knet icon mini.png"}
                                  className="img-fluid"
                                ></img>
                              </>
                            )}
                            {orderDetails?.order?.payment_method == "2" &&
                              (language == "ltr"
                                ? "Credit Card"
                                : "بطاقة الائتمان")}
                            {orderDetails?.order?.payment_method == "3" &&
                              (language == "ltr" ? "Cash" : "نقداً")}
                          </p>
                        </li>
                        <li className="cart-sub-total mt-2">
                          <p className="product-price booking-price-p">
                            {language == "ltr"
                              ? "Payment Date"
                              : "تاريخ الدفع"}
                          </p>
                          <p className="product-price booking-price-p">
                            {language == "ltr" ? (
                              moment(orderDetails?.order?.payment_date)
                                ?.locale("en")
                                ?.format("D MMM YYYY")
                            ) : (
                              <>
                                <span
                                  style={{ fontSize: 15 }}
                                  className="number-span"
                                >
                                  {moment(orderDetails?.order?.payment_date)
                                    ?.locale("en")
                                    ?.format("D")}
                                </span>{" "}
                                {moment(orderDetails?.order?.payment_date)
                                  ?.locale("ar")
                                  ?.format("MMM")}{" "}
                                <span
                                  style={{ fontSize: 15 }}
                                  className="number-span"
                                >
                                  {moment(orderDetails?.order?.payment_date)
                                    ?.locale("en")
                                    ?.format("YYYY")}
                                </span>
                              </>
                            )}
                          </p>
                        </li>

                        <li className="cart-sub-total mt-2">
                          <p className="product-price booking-price-p">
                            {language == "ltr"
                              ? "Transaction No."
                              : "رقم العملية"}
                          </p>
                          <p className="product-price booking-price-p">
                            <span
                              style={{ fontSize: 15 }}
                              className="number-span"
                            >
                              {orderDetails?.order?.transaction_number}
                            </span>
                          </p>
                        </li>
                        <li className="cart-sub-total mt-2">
                          <p className="product-price booking-price-p">
                            {language == "ltr"
                              ? "Auth Code"
                              : "رمز التفويض"}
                          </p>
                          <p className="product-price booking-price-p">
                            <span
                              style={{ fontSize: 15 }}
                              className="number-span"
                            >
                              {orderDetails?.order?.tracking_number}
                            </span>
                          </p>
                        </li>

                        {
                          <li className="cart-sub-total mt-2">
                            <p className="product-price booking-price-p">
                              {language == "ltr"
                                ? "Ref Code"
                                : "الكود المرجعي"}
                            </p>
                            <p className="product-price booking-price-p">
                              <span
                                style={{ fontSize: 15 }}
                                className="number-span"
                              >
                                {orderDetails?.order?.reference_number}
                              </span>
                            </p>
                          </li>
                        }
                        {orderDetails.order.total_trainer_cost != 0 &&
                          orderDetails.order.is_trainer_required == 1 && (
                            <li className="cart-sub-total mt-2">
                              <p className="product-price booking-price-p">
                                {language == "ltr"
                                  ? "Trainer Cost"
                                  : "تكلفة المدرب"}
                              </p>
                              <p className="product-price booking-price-p">
                                <span
                                  style={{ fontSize: 15 }}
                                  className="number-span"
                                >
                                  {orderDetails.order.total_trainer_cost ? parseFloat(
                                    orderDetails.order.total_trainer_cost
                                  ).toFixed(3) : 0}
                                  &nbsp;
                                </span>
                                {language === "rtl" ? "د.ك" : "KD"}
                              </p>
                            </li>
                          )}
                        <li className="cart-sub-total mt-3">
                          <p className="sub-heading">
                            {language == "ltr" ? "Total" : "المجموع"}
                          </p>
                          <p className="sub-heading">
                            <span>
                              {orderDetails?.order?.total_amount ? parseFloat(
                                orderDetails?.order?.total_amount
                              ).toFixed(3) : 0}
                              &nbsp;
                            </span>{" "}
                            {language === "rtl" ? "د.ك" : "KD"}
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              )}
              <div className="download">
                <div
                  className="get-help"
                  style={{
                    justifyContent:
                      // orderDetails?.order?.payment_status
                      //   ? "space-between"
                      //   :
                      "center",
                  }}
                >
                </div>
              </div>
            </div>
          </div>
          <div className={`bottom-button ${details?.vendor?.home_page_type == "18" ? "bottom-button-full" : "bottom-button-half"}`}>
            {orderDetails?.order?.payment_status == 1 ? (
              <>
                {orderDetails?.branch?.lat && orderDetails?.branch?.lng && (
                  <a
                    href={`https://www.google.com/maps/search/?api=1&query=${orderDetails?.branch?.lat}%2C${orderDetails?.branch?.lng}`}
                    target="_blank"
                    className="text-center checkout-button"
                    style={{
                      width: "49%",
                      backgroundColor: "white",
                      border: `3px solid ${details?.vendor?.vendor_color}`,
                      color: `${details?.vendor?.vendor_color}`,
                    }}
                  >
                    {language == "ltr"
                      ? `${details?.vendor?.place_label} Location`
                      : `عرض على الخريط`}
                  </a>
                )}
                <Link
                  to={`/`}
                  className={`text-center checkout-button `}
                  style={{
                    width:
                      orderDetails?.branch?.lat && orderDetails?.branch?.lng
                        ? "49%"
                        : "100%",
                  }}
                >
                  {language == "ltr" ? "Book Again" : "احجز مرة أخرى"}
                </Link>
              </>
            ) : (
              <Link
                to={"/"}
                className="text-center checkout-button"
                style={{ width: "100%" }}
              >
                {language == "ltr" ? "Try Again" : "إعادة المحاولة"}
              </Link>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default BookingConfirm;
