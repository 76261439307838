import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { LanguageContext, VendorContext } from "../../App";
import ReactFlagsSelect from "react-flags-select";
import ClockIcon from "../../SVGs/ClockIcon";
import { displayInternationalTime } from "../../commonFunction/commonFunction";

const DeliveryAddressSection = ({ internationalDelivery }) => {
    const { language } = useContext(LanguageContext);
    const details = useContext(VendorContext)
    const [deliveryTime, setDeliveryTime] = useState()

    useEffect(() => {
        if (internationalDelivery.delivery_expected_time && internationalDelivery.delivery_expected_type) {
            const respones = displayInternationalTime(internationalDelivery.delivery_expected_time, internationalDelivery.delivery_expected_type)
            setDeliveryTime(respones)
        }
    }, [internationalDelivery.delivery_expected_time, internationalDelivery.delivery_expected_type])


    return <div >
        <div className='delivery-addres-heading-checkout'>
            {
                language === "ltr" ? "Delivery Address" : "عنوان التسليم"
            }
        </div>
        <Link to={'/delivery-address'} className='delivery-address-details-mainDIv'>
            <div className='delivery-address-details-firstDiv'>
                <div className='dontchane-direction'>
                    <ReactFlagsSelect
                        className='showFlag-only'
                        selected={internationalDelivery?.delivery_country_code}
                        showSelectedLabel={false}
                        disabled
                    />
                    {internationalDelivery.delivery_country}
                </div>
                <div className='delivery-alignment'>
                    {internationalDelivery.delivery_address1}
                </div>
                <div className='delivery-alignment'>
                    {internationalDelivery.delivery_address2}
                </div>
                {internationalDelivery.delivery_zipCode ?
                    <div className='delivery-alignment'>
                        {internationalDelivery.delivery_zipCode ? <span>
                            {language == "ltr" ? "Zip Code" : "الرمز البريدي"}
                            {" "}
                            {internationalDelivery.delivery_zipCode}
                        </span>
                            : null
                        }
                    </div>
                    : null
                }
                {internationalDelivery.delivery_specialInstruction &&
                    <div className='delivery-alignment'>
                        {internationalDelivery.delivery_specialInstruction}
                    </div>
                }
                {deliveryTime ?
                    <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                        <div style={{ width: "20px", height: "14px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <ClockIcon color={details.vendor.vendor_color} />
                        </div>
                        <div>
                            {internationalDelivery.delivery_expected_time}{" "}
                            {
                                language === "ltr" ? deliveryTime.eng : deliveryTime.arb
                            }
                        </div>
                    </div>
                    : null
                }
            </div>
            <div className='delivery-address-details-secondDiv'>
                <i className="fa fa-angle-right right-arrow"></i>
            </div>
        </Link >
    </div >;
};

export default DeliveryAddressSection;
