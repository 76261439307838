import React from "react";
import CommonBack from "../NewCheckOutPage/Components/CommonBack";

const CategoryLoading = ({ type, isSubCategory }) => {
  const renderLoader = () => {
    if (isSubCategory) {
      return (
        <div className="product-category-view">
          <ul className="product-category-list">
            {[...Array(7)].map((k, i) => (
              <li className="product-category-pad shine-design">
                <div className="product-category-item">
                  <a style={{ alignItems: "center" }}>
                    <div
                      className="product-img-div"
                      style={{
                        position: "static",
                        backgroundColor: "#f3f3f3",
                        width: "90%",
                        zIndex: 0,
                      }}
                    ></div>
                    <div
                      className="mt-3"
                      style={{
                        minHeight: "44px",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <span
                        style={{
                          backgroundColor: "#f3f3f3",
                          display: "inline-block",
                          width: "50%",
                          height: "21px",
                          marginBottom: 10,
                        }}
                      ></span>
                    </div>
                  </a>
                </div>
              </li>
            ))}
          </ul>
        </div>
      );
    }

    switch (type) {
      case "10":
      case "14":
      case "17":
      case "18":
      case "11":
        return (
          <div className="products-main-div">
            <ul className="products-list">
              {[...Array(7)].map((k, i) => (
                <div className="products-list-item shine-design">
                  <div className="product-div">
                    <div className="product-image-link">
                      <div
                        className="product-list-image"
                        style={{ backgroundColor: "#f3f3f3" }}
                      ></div>
                    </div>
                    <div className="product-list-description">
                      <div
                        style={{
                          backgroundColor: "#f3f3f3",
                          width: "40%",
                          height: "1.2rem",
                          marginBottom: "10px",
                        }}
                      ></div>
                      <div
                        style={{
                          backgroundColor: "#f3f3f3",
                          width: "95%",
                          height: "1rem",
                          marginBottom: "3px",
                        }}
                      ></div>
                      <div
                        style={{
                          backgroundColor: "#f3f3f3",
                          width: "90%",
                          height: "1rem",
                          marginBottom: "5px",
                        }}
                      ></div>
                      <div className="product-cost-div">
                        <div
                          className="price-bubble"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <div
                            style={{
                              backgroundColor: "#f3f3f3",
                              width: 100,
                              height: "1.3rem",
                              margin: "0 5px",
                              display: "inline-block",
                            }}
                          ></div>
                          <div
                            style={{
                              backgroundColor: "#f3f3f3",
                              width: 24,
                              height: "1.5rem",
                              display: "inline-block",
                              borderRadius: "50%",
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </ul>
          </div>
        );

      case "13":
      case "12":
      case "19":
      case "15":
        return (
          <div className="product-category-view">
            <ul className="product-category-list">
              {[...Array(7)].map((k, i) => (
                <li className="product-category-pad shine-design">
                  <div className="product-category-item">
                    <a style={{ alignItems: "center" }}>
                      <div
                        className="product-img-div"
                        style={{
                          position: "static",
                          backgroundColor: "#f3f3f3",
                          width: "90%",
                          zIndex: 0,
                        }}
                      ></div>
                      <div
                        className="mt-3"
                        style={{
                          minHeight: "44px",
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <span
                          style={{
                            backgroundColor: "#f3f3f3",
                            display: "inline-block",
                            width: "50%",
                            height: "21px",
                            marginBottom: 10,
                          }}
                        ></span>
                        <span
                          style={{
                            backgroundColor: "#f3f3f3",
                            display: "inline-block",
                            width: "90%",
                            height: "15px",
                            marginBottom: 5,
                          }}
                        ></span>
                        <span
                          style={{
                            backgroundColor: "#f3f3f3",
                            display: "inline-block",
                            width: "80%",
                            height: "15px",
                            marginBottom: 15,
                          }}
                        ></span>
                      </div>
                      <div
                        className="product-price-div"
                        style={{ alignItems: "center" }}
                      >
                        <div className="product-price">
                          <span
                            style={{
                              backgroundColor: "#f3f3f3",
                              height: "20px",
                              width: "70px",
                            }}
                          ></span>
                        </div>
                        <div
                          style={{
                            borderRadius: "50%",
                            width: "25px",
                            backgroundColor: "#f3f3f3",
                            minHeight: "25px",
                            margin: "0 6px",
                          }}
                        ></div>
                      </div>
                    </a>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        );

      case "16":
        return (
          <div className="product-category-view">
            <ul className="product-category-list single-product-list">
              {[...Array(7)].map((k, i) => (
                <li className="product-category-pad single-product-pad">
                  <div className="product-category-item">
                    <a>
                      <div
                        className="product-img-div shine-design"
                        style={{ backgroundColor: "#f3f3f3" }}
                      ></div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#f3f3f3",
                            height: "29px",
                            marginTop: "1rem",
                            borderRadius: "5px",
                            width: "400px",
                          }}
                        ></div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#f3f3f3",
                            height: "25px",
                            marginTop: "1rem",
                            width: "125px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "5px",
                          }}
                        ></div>
                        <div
                          style={{
                            backgroundColor: "#f3f3f3",
                            marginTop: "1rem",
                            width: "25px",
                            height: "25px",
                            borderRadius: "50px",
                          }}
                        ></div>
                      </div>
                    </a>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        );

      default:
        return (
          <div className="product-category-view">
            <ul className="product-category-list">
              {[...Array(7)].map((k, i) => (
                <li className="product-category-pad single-product-pad">
                  <div className="product-category-item">
                    <a>
                      <div
                        className="product-img-div"
                        style={{ backgroundColor: "#f3f3f3" }}
                      ></div>
                      <div className="mt-3" style={{ minHeight: "44px" }}>
                        <span
                          style={{
                            backgroundColor: "#f3f3f3",
                            display: "inline-block",
                            width: "100%",
                            height: "21px",
                          }}
                        ></span>
                        <br></br>
                        <span
                          style={{
                            backgroundColor: "#f3f3f3",
                            display: "inline-block",
                            width: "50%",
                            height: "21px",
                          }}
                        ></span>
                      </div>
                      <div style={{ minHeight: "30px" }}>
                        <span
                          style={{
                            backgroundColor: "#f3f3f3",
                            display: "inline-block",
                            width: "100%",
                            height: "16px",
                          }}
                        ></span>
                        <br></br>
                        <span
                          style={{
                            backgroundColor: "#f3f3f3",
                            display: "inline-block",
                            width: "100%",
                            height: "16px",
                          }}
                        ></span>
                      </div>
                      <div className="product-price-div">
                        <div className="product-price">
                          <span
                            style={{
                              backgroundColor: "#f3f3f3",
                              height: "15px",
                              width: "50px",
                            }}
                          ></span>
                        </div>
                        <div
                          className="buy-get-img product-price-margin"
                          backgroundColor="#f3f3f3"
                          style={{
                            borderRadius: "30px",
                            width: "70px",
                            minHeight: "25px",
                          }}
                        ></div>
                      </div>
                    </a>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        );
    }
  };
  return (
    <div>
      <div style={{ padding: "8px 16px" }}>
        <CommonBack
          english_title={""}
          arabic_title={""}
          variant="light"
          clickButton={() => {}}
        />
      </div>
      {renderLoader()}
    </div>
  );
};

export default CategoryLoading;
