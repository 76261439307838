import React from 'react'

const PhoneIcons = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="basic / phone_outline">
                <path id="coolicon" d="M8.74214 8.42568C7.66901 9.30126 7.27576 10.8981 8.20237 12.1792C9.06948 13.378 10.1618 14.4266 11.4099 15.2588C12.7441 16.1484 14.407 15.7707 15.3187 14.7402L15.3289 14.7447C16.4934 15.2627 17.7304 15.6178 19 15.7987V18.3335L18.9989 18.3335L18.9961 18.3335C11.0215 18.3443 4.99148 12.4867 5.00001 4.89364V4.8902H7.63937L7.63954 4.89132C7.828 6.11032 8.19761 7.29706 8.73714 8.41529L8.74214 8.42568ZM18.9989 20.2539H20C20.5523 20.2539 21 19.824 21 19.2937V14.9705C21 14.4946 20.637 14.0905 20.1468 14.0206L19.2926 13.8989C18.2123 13.745 17.1605 13.4429 16.1695 13.0021L15.4166 12.6672C14.9804 12.4732 14.462 12.6047 14.1826 12.9804L13.8418 13.4386C13.5431 13.8402 12.9728 13.9628 12.5506 13.6814C11.4984 12.9797 10.5761 12.0943 9.84502 11.0836C9.55181 10.6782 9.67955 10.1306 10.0978 9.84373L10.5748 9.51667C10.9661 9.24833 11.1031 8.75054 10.901 8.33158L10.5518 7.60798C10.0927 6.65645 9.77823 5.64662 9.61786 4.60932L9.49104 3.78896C9.41827 3.31825 8.99745 2.96973 8.50188 2.96973H4.00001C3.44773 2.96973 3.00001 3.39964 3.00001 3.92996V4.89157C2.99029 13.5399 9.91023 20.2663 18.9989 20.2539Z" fill="#1D232E" />
            </g>
        </svg>

    )
}

export default PhoneIcons