import React, { useContext } from 'react'
import { LanguageContext, VendorContext } from '../../../App'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const CommonBack = ({ arabic_title, english_title, variant, clickButton }) => {
    const details = useContext(VendorContext)
    const { language } = useContext(LanguageContext)
    const history = useHistory()
    return (
        <div className='commonback-maindiv'>
            <div className={`commonback-holder ${variant === "dark" ? "commonback-holder-dark" : "commonback-holder-light"} ${details?.vendor?.home_page_type == 18 && "fashion-theme"}`} onClick={() => clickButton ? clickButton() : history.goBack()}>
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="16" viewBox="0 0 9 16" fill="none">
                        <path d="M8 15L1 8L8 1" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
            </div>
            {
                arabic_title != "" && english_title != "" ?
                    <div className='commonback-heading'>
                        {language === "ltr" ? english_title : arabic_title}
                    </div>
                    : null
            }
        </div >
    )
}

export default CommonBack