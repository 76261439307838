/**
 * @desc This component is the main homepage for the booking vendor. It has both the long term and short term booking pages.
 */
import React, { useContext, useState } from "react";
import ReactOwlCarousel from "react-owl-carousel";
import { BookingSetContext, LanguageContext, VendorContext } from "../../App";
import DesktopNavigationBar from "../HomePage/components/DesktopNavigationBar";
import WhatsappSticky from "../WhatsappSticky";
import BookingHeader from "../BookingPage/components/BookingHeader";
import BookingType from "./components/BookingType";
import BookingPage from "../BookingPage";
import MobileNavigationBar from "../HomePage/components/MobileNavigationBar";
import MobileNavigationMenu from "../HomePage/components/MobileNavigationMenu";
import MobileVendorLogo from "../HomePage/components/MobileVendorLogo";
import Footer from "../HomePage/components/Footer";
import BookingPackage from "../BookingPackage";
import AppInstall from "../Common/AppInstall";
import BookingPlaceHeader from "./components/BookingPlaceHeader";
import NewSideBar from "../NewSideBar";

function Booking({ installable, width, setInstallable, handleInstallClick }) {
  const details = useContext(VendorContext);
  const [burger, setBurger] = useState(false);
  const { language } = useContext(LanguageContext);
  const { bookingSets } = useContext(BookingSetContext);
  const [stepper, setStepper] = useState(0);
  const [bookingType, setBookingType] = useState(
    details?.vendor?.booking_term == 3 ||
      details?.vendor?.booking_term == 1 ||
      details?.vendor?.home_page_type == 1
      ? 1
      : 2
  );
  return (
    <>
      {stepper === 0 && !bookingSets?.booking_search && (
        <AppInstall
          installable={installable}
          width={width}
          setInstallable={setInstallable}
          handleInstallClick={handleInstallClick}
        ></AppInstall>
      )}
      <div className="container-fluid">
        <div className="row">
          {!burger ? (
            <div className="col-lg-4 col-md-12 order-md-2 order-sm-2 order-lg-1 order-2 small-col p-0">
              <div
                style={
                  width < 992 &&
                    stepper === 0 &&
                    !bookingSets?.booking_search &&
                    !bookingSets?.placeClicked
                    ? {
                      marginTop: 15,
                      paddingTop: 15,
                      borderTop: "1px #dbdbdb solid",
                    }
                    : {
                      paddingBottom:
                        stepper === 0 &&
                          !bookingSets?.booking_search &&
                          !bookingSets?.placeClicked
                          ? 230
                          : 0,
                    }
                }
              >
                {!bookingSets?.placeClicked ? (
                  <BookingHeader stepper={stepper}></BookingHeader>
                ) : (
                  <BookingPlaceHeader></BookingPlaceHeader>
                )}
                {stepper == 0 &&
                  details?.vendor?.booking_term == 3 &&
                  details?.vendor?.home_page_type != 1 &&
                  !bookingSets?.booking_search &&
                  !bookingSets?.placeClicked ? (
                  <BookingType
                    bookingType={bookingType}
                    setBookingType={setBookingType}
                  ></BookingType>
                ) : null}
                {bookingType === 1 ? (
                  <BookingPage
                    stepper={stepper}
                    setStepper={setStepper}
                  ></BookingPage>
                ) : null}
                {bookingType === 2 ? (
                  <BookingPackage
                    stepper={stepper}
                    setStepper={setStepper}
                  ></BookingPackage>
                ) : null}
                {stepper === 0 &&
                  !bookingSets?.booking_search &&
                  !bookingSets?.placeClicked && (
                    <Footer text={"page-footer"}></Footer>
                  )}
              </div>
            </div>
          ) : null}
          <div
            className={`col-lg-8 col-md-12 order-md-1 order-lg-2 order-sm-1 order-1 large-col p-0 ${stepper !== 0 ||
              bookingSets?.booking_search ||
              bookingSets?.placeClicked
              ? "none-res"
              : ""
              }`}
            style={{
              width: "100%",
            }}
          >
            <MobileNavigationBar
              installable={installable}
              width={width}
              burger={burger}
              setBurger={setBurger}
            ></MobileNavigationBar>
            {!burger ? (
              <>
                <div className={`owl-bra `}>
                  <ReactOwlCarousel
                    dir="ltr"
                    items={1}
                    className="owl-theme"
                    loop={!(details?.vendor?.banner_images?.length == 1)}
                    mouseDrag={!(details?.vendor?.banner_images?.length == 1)}
                    autoplay={!(details?.vendor?.banner_images?.length == 1)}
                    dots={false}
                    dotsEach={false}
                    animateIn="fadeIn"
                    animateOut="fadeOut"
                  >
                    {details?.vendor?.banner_images?.map((pic, i) => (
                      <div
                        className={`${details?.vendor?.set_gradient_background == 1
                          ? "gradient"
                          : ""
                          }`}
                        key={i}
                      >
                        <img
                          className={`owl-banner-pic`}
                          src={`${pic?.image}`}
                        />
                      </div>
                    ))}
                  </ReactOwlCarousel>
                </div>
                {
                  window.innerWidth < 991 ?
                    <MobileVendorLogo></MobileVendorLogo>
                    : null
                }
                <DesktopNavigationBar></DesktopNavigationBar>
                <div className="logo-container">
                  {details?.vendor &&
                    details?.vendor?.english_new_background && (
                      <img
                        src={
                          language == "ltr"
                            ? details?.vendor?.english_new_background
                            : details?.vendor?.arabic_new_background
                        }
                        className="img-fluid right-big-logo"
                        alt="payzah-logo"
                      ></img>
                    )}
                  <p className="logo-name mt-4">
                    {language == "ltr"
                      ? details?.vendor?.name
                      : details?.vendor?.name_ar}
                  </p>
                  <p className="logo-detail mt-2">
                    {language == "ltr"
                      ? details?.vendor?.slogan
                      : details?.vendor?.slogan_ar}
                  </p>
                </div>
              </>
            ) : null}
            {
              burger && <NewSideBar setBurger={setBurger} />
            }
            {/* {burger && (
              <MobileNavigationMenu
                burger={burger}
                setBurger={setBurger}
              ></MobileNavigationMenu>
            )} */}
          </div>
        </div>
        <WhatsappSticky></WhatsappSticky>
      </div>
    </>
  );
}

export default Booking;
